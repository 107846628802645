import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Upload, Space } from "antd";
// ============
import styles from "./styles.module.less";
import { addCurrency } from "../../store/currency";
import { UploadOutlined } from "@ant-design/icons";

const CurrencyAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  useEffect(() => {
    const currencyLocale = new Intl.NumberFormat().resolvedOptions().locale;
    formRef.current.setFieldsValue({
      locale: currencyLocale,
    });
  }, []);
  const handleSubmit = async (values) => {
    console.log("values");
    console.log(values);

    try {
      if (values) {
        await dispatch(addCurrency(values)).unwrap();
        message.success("You have successfully added the currency");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const uploadFlag = async (values) => {
    console.log("values");

    console.log(values);
  };

  const handleOnChangeFlag = () => {
    console.log("handleOnChange");
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>CREATE CURRENCY</div>
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <div className={styles.input}>
            <Form.Item
              name="currency_name"
              label={<div className={styles.label}>Currency Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the currency name",
                },
              ]}
            >
              <Input placeholder="Malaysian Ringgit" />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="iso_code"
              label={
                <div className={styles.label}>Currency Code / Iso Code</div>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the currency code",
                },
              ]}
            >
              <Input placeholder="MYR" />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="minimum_amount"
              label={<div className={styles.label}>Minimum Amount</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the minimum amount",
                },
              ]}
            >
              <Input placeholder="0.00" />
            </Form.Item>
          </div>

          {/* =========== TO DO : UPLOAD THE LOGO INTO THE AWS SERVER =========== */}
          {/* Temporary omit this part first*/}
          {/* <div>
            <Form.Item
              name="flag"
              label={<div className={styles.label}>Currency Flag</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the minimum amount",
                },
              ]}
            >
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                }}
                size="large"
              >
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  maxCount={1}
                  style={{
                    width: "100%",
                    backgroundColor: "pink",
                  }}
                  onChange={handleOnChangeFlag}
                >
                  <Button icon={<UploadOutlined />}>Upload Flag</Button>
                </Upload>
              </Space>
            </Form.Item>
          </div> */}

          {/* <div className={styles.input}>
            <Form.Item
              name="locale"
              label={<div className={styles.label}>Locale</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the locale exp: en-US",
                },
              ]}
            >
              <Input value="hello" placeholder="Currency code" />
            </Form.Item>
          </div> */}
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Create
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CurrencyAdd;
