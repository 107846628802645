import React, { useState } from "react";
import { Row, Col, Card, Form, Input, Button, message, Modal } from "antd";
import { FiAtSign } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useDispatch } from "react-redux";

//====
import styles from "./styles.module.less";
import { forgetPassword } from "@store/app";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const onFinish = async (values) => {
    try {
      setModalVisible(false);
      setSpinnerVisible(true);
      const { data } = await dispatch(forgetPassword(values.email)).unwrap();
      const { message: responseMessage } = data;

      message.success(responseMessage);
      navigate("/signin");
    } catch (error) {
      message.error(error.message);
    } finally {
      setSpinnerVisible(false);
    }
  };

  const handleModalContent = () => {
    if (spinnerVisible) {
      return (
        <>
          <Spin indicator={antIcon} />
          <div style={{ fontWeight: 700, fontSize: 16, marginTop: 12 }}>
            Sending email...
          </div>
        </>
      );
    }

    // if (isEmailSent) {
    //   return (
    //     <Row
    //       style={{ fontWeight: 700, fontSize: 16, marginBottom: 12 }}
    //       align="center"
    //     >
    //       Email sent, please check your email inbox
    //     </Row>
    //   );
    // } else {
    //   return (
    //     <Row
    //       style={{ fontWeight: 500, fontSize: 15, marginBottom: 12 }}
    //       align="center"
    //     >
    //       Invalid email!
    //     </Row>
    //   );
    // }
  };
  return (
    <>
      <Row className={styles.forgot_password_container}>
        <Card className={styles.forgot_password_card}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className={styles.forgot_password_title}>
                Forgot Password?
              </div>
            </Col>
            <Col span={24}>
              <div className={styles.forgot_password_desc}>
                Enter your email and instructions will sent to you!
              </div>
            </Col>
          </Row>

          <Form
            name="control-hooks"
            layout="vertical"
            requiredMark="optional"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label="EMAIL"
              className={styles.forgot_password_form_item}
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input
                prefix={<FiAtSign className="grey_fill_icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <Button type="default" htmlType="submit" className="save-auth-form">
              Reset
            </Button>

            <Button
              htmlType="cancel"
              className="cancel-auth-form"
              onClick={() => navigate("/signin")}
            >
              Cancel
            </Button>
          </Form>
        </Card>
      </Row>
      <Modal
        className="modal_container"
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={modalVisible}
        closable={false}
        onOk={() => {
          setModalVisible(false);
          navigate("/signin");
        }}
        okText="Close"
        cancelButtonProps={{
          style: { display: "none" },
        }}
        okButtonProps={{
          style: { display: spinnerVisible ? "none" : "inline" },
        }}
      >
        {handleModalContent()}
        {/* {spinnerVisible ? (
          <>
            <Spin indicator={antIcon} />
            <div style={{ fontWeight: 700, fontSize: 16, marginTop: 12 }}>
              Sending email...
            </div>
          </>
        ) : (
          <Row
            style={{ fontWeight: 700, fontSize: 16, marginBottom: 12 }}
            align="center"
          >
            Email sent, please check your email inbox
          </Row>
        )} */}
      </Modal>
    </>
  );
};

export default ForgotPassword;
