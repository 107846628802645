import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";

const initialState = {
  selectedCountry: {},
  allCountries: [],
};

export const getAllCountries = createAsyncThunk(
  "country/getAllCountries",
  async (_) => {
    try {
      const { data } = await axios.get("countries?type=all");
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const countryWithCurrencies = createAsyncThunk(
  "banks/countries",
  async ({ currencyId }) => {
    try {
      const { data } = await axios.get(`banks/countries/${currencyId}`);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveCountries = createAsyncThunk(
  "country/retrieveCountries",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("countries", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCountryById = createAsyncThunk(
  "country/updateCountryById",
  async ({ id, currency_ids }) => {
    let payload = {
      currency_ids,
    };

    try {
      const { data } = await axios.patch(`countries/${id}`, payload);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCountryStatus = createAsyncThunk(
  "country/updateCountryStatus",
  async ({ id }) => {
    try {
      const { data } = await axios.patch(`countries/update-status/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCountryForTransacts = createAsyncThunk(
  "country/updateCountryForTransacts",
  async ({ id }) => {
    try {
      const { data } = await axios.patch(
        `countries/update-for-transacts/${id}`
      );
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllActiveCountries = createAsyncThunk(
  "country/findAllActiveCountries",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder("countries/active", getState().ui.table, filter);

      const { data } = await axios.get(url);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCountryFilterOptionsByType = createAsyncThunk(
  "country/findCountryFilterOptionsByType",
  async ({ type }) => {
    try {
      const { data } = await axios.get(`countries/filter/${type}`);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCountryById = createAsyncThunk(
  "country/findCountryById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`countries/${id}`);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setSelectedCountry: (state, { payload }) => {
      state.selectedCountry = payload.selectedCountry;
    },
    resetCountry: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCountries.fulfilled, (state, { payload }) => {
      state.allCountries = payload.data;
    });
  },
});

export const { setSelectedCountry, resetCountry } = countrySlice.actions;

export default countrySlice.reducer;
