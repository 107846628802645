import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";
import fileDownload from "js-file-download";
import moment from "moment";
import { join } from "lodash";

export const generateTopUpBankDepositReport = createAsyncThunk(
  "report/generateTopUpBankDepositReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "reports/topup/bank",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateTopUpCryptoReport = createAsyncThunk(
  "report/generateTopUpCryptoReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "reports/topup/crypto",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateTopUpGiftCardReport = createAsyncThunk(
  "report/generateTopUpGiftCardReport",
  async ({ mode, filter }, { getState }) => {
    try {

      const url = queryBuilder(
        "reports/topup/gift-card",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateConversionReport = createAsyncThunk(
  "report/generateConversionReport",
  async ({ mode, filter }, { getState }) => {
    try {

      const url = queryBuilder(
        "reports/conversion",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateSendReport = createAsyncThunk(
  "report/generateSendReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder("reports/send", getState().ui.table, filter);

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateMemberReport = createAsyncThunk(
  "report/generateMemberReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "reports/send/members",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateMemberSummaryReport = createAsyncThunk(
  "report/generateMemberSummaryReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "reports/send/members/summary",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateAllTransactionsReport = createAsyncThunk(
  "report/generateAllTransactionsReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "reports/transactions",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateAdjustmentReport = createAsyncThunk(
  "report/generateAdjustmentReport",
  async ({ mode, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "reports/adjustment",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(`${url}&mode=${mode}`);

      return data

    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const reportSlice = createSlice({
  name: "report",
  initialState: {}
});

export const { } = reportSlice.actions;

export default reportSlice.reducer;
