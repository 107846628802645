import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler, queryBuilder } from "../configs";

const initialState = {
  selectedProvider: {},
  providers: [],
};

/**
 * Crypto Provider
 */

export const retrieveCryptoProviders = createAsyncThunk(
  "crypto/retrieveCryptoProviders",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("crypto/provider", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createCryptoProvider = createAsyncThunk(
  "crypto/createCryptoProvider",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("name", values.provider_name);
      // formData.append("currency_id", values.currency_id);

      const { data } = await axios.post("crypto/provider/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getCryptoProviderById = createAsyncThunk(
  "crypto/getCryptoProviderById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`crypto/provider/${id}`);
      console.log(data);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCryptoProvider = createAsyncThunk(
  "crypto/updateCryptoProvider",
  async (values) => {
    try {
      const { id, name } = values;

      let dataTemp = JSON.stringify({
        name: name.trim(),
      });

      const { data } = await axios.patch(`crypto/provider/${id}`, dataTemp, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

/**
 * Crypto Account
 */

export const retrieveCryptoAccounts = createAsyncThunk(
  "crypto/retrieveCryptoAccounts",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder("crypto/account", getState().ui.table, filter);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createCryptoAccount = createAsyncThunk(
  "crypto/createCryptoAccount",
  async (values) => {
    try {
      let formData = new FormData();
      formData.append("status", values.status);
      formData.append("crypto_provider_id", values.crypto_provider_id);
      formData.append("crypto_network_id", values.crypto_network_id);
      formData.append("address", values.address);
      formData.append("minimum_amount", values.minimum_amount);
      formData.append("maximum_amount", values.maximum_amount);

      const { data } = await axios.post("crypto/account/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCryptoAccount = createAsyncThunk(
  "crypto/updateCryptoAccount",
  async (values) => {
    try {
      let payload = {
        status: values.status,
        crypto_provider_id: values.crypto_provider_id,
        crypto_network_id: values.crypto_network_id,
        address: values.address,
        minimum_amount: values.minimum_amount,
        maximum_amount: values.maximum_amount,
      };

      const { data } = await axios.patch(
        `crypto/account/${values.id}`,
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCryptoAccountById = createAsyncThunk(
  "crypto/getCryptoProviderById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`crypto/account/${id}`);
      console.log(data);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

/**
 * Crypto Network
 */

export const retrieveCryptoNetworks = createAsyncThunk(
  "crypto/retrieveCryptoNetworks",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("crypto/network", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCryptoNetworkById = createAsyncThunk(
  "crypto/findCryptoNetworkById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`crypto/network/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

/**
 * Crypto Transactions
 */

export const findAllCryptoReloads = createAsyncThunk(
  "crypto/findAllCryptoReloads",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder("cases/reload/crypto/all", getState().ui.table, filter);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllPendingCryptoReloads = createAsyncThunk(
  "crypto/findAllPendingCryptoReloads",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "cases/reload/crypto/pending",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCryptoReloadById = createAsyncThunk(
  "crypto/findCryptoReloadById",
  async ({ id, mode }) => {
    try {
      const { data } = await axios.get(`cases/reload/crypto/${id}?mode=${mode}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    setSelectedProvider: (state, { payload }) => {
      state.selectedProvider = payload.selectedProvider;
    },
    resetCrypto: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveCryptoProviders.fulfilled, (state, { payload }) => {
      state.currencies = payload.data;
    });
  },
});
export const { setSelectedProvider, resetCrypto } = cryptoSlice.actions;

export default cryptoSlice.reducer;
