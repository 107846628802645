import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler, queryBuilder } from "../configs";

export const findAllCommunicationChannels = createAsyncThunk(
  "communication-channel/findAllCommunicationChannels",
  async (_, { getState }) => {
    try {
      const url = queryBuilder(
        "communication-channel/all",
        getState().ui.table
      );
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCommunicationChannelById = createAsyncThunk(
  "bank/findCommunicationChannelById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`communication-channel/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createCommunicationChannel = createAsyncThunk(
  "communication-channel/createCommunicationChannel",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("type", values.type);

      // Any value passed into data.append will be converted to a string.
      // No need to pass if url is null
      if (values.url) {
        formData.append("url", values.url);
      }

      // No need to pass if url is null
      if (values.email) {
        formData.append("email", values.email);
      }

      formData.append("is_enabled", values.is_enabled);

      const { data } = await axios.post("communication-channel/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCommunicationChannelById = createAsyncThunk(
  "communication-channel/updateCommunicationChannelById",
  async ({ id, params }) => {
    try {
      let formData = {
        name: params.name,
        description: params.description,
        type: params.type,
        url: params.url,
        email: params.email,
        is_enabled: params.is_enabled,
      };

      const { data } = await axios.post(
        `communication-channel/${id}`,
        formData
        // {
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "multipart/form-data",
        //   },
        // }
      );

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
