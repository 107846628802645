import React, { useRef, useState } from "react";
import { Button, Row, Input, Form, Space, Col, message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { map, filter } from "lodash";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { addNewUser } from "@store/app";
import { isValidPhoneNumber } from "libphonenumber-js";

const { Option } = Select;

const DashboardUserAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const countries = useSelector((state) =>
    filter(state.country.allCountries, (item) => item.is_enabled == 1)
  );

  const onFinishAddUser = async (values) => {
    try {
      const { data } = await dispatch(addNewUser(values)).unwrap();
      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  const onCountryChange = (value) => {
    formRef?.current?.setFieldsValue({
      country: value,
    });
  };

  const countriesCallingCode = useSelector((state) =>
    filter(state.country.allCountries, (item) => item.is_enabled == 1)
  );

  const renderCountryOptions = (item) => {
    return (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    );
  };

  return (
    <div className={styles.card_container}>
      <Row>
        <div className={styles.section_title}>ADD NEW DASHBOARD USER</div>
      </Row>
      <Form
        layout="vertical"
        className={styles.input_container}
        onFinish={onFinishAddUser}
        requiredMark={false}
        ref={formRef}
      >
        <Form.Item
          className={styles.input}
          name="new_username"
          label={<div className={styles.label}>Username</div>}
          rules={[
            {
              required: true,
              message: "Username cannot be blank",
            },
            () => ({
              validator(rule, value) {
                if (value) {
                  if (value.includes(" ")) {
                    return Promise.reject("No space allowed");
                  }
                  return Promise.resolve();
                } else {
                  return Promise.reject();
                }
              },
            }),
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          className={styles.input}
          name="new_fullname"
          label={<div className={styles.label}>Full Name</div>}
          rules={[
            {
              required: true,
              message: "Full name cannot be blank",
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          className={styles.input}
          name="new_email"
          label={<div className={styles.label}>Email</div>}
          rules={[
            {
              required: true,
              message: "Email cannot be blank",
            },
            {
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Row>
          <Col span={21}>
            <label className={styles.label}>Phone No.</label>
            <div className={styles.phone_container}>
              <div className={styles.select_container_phone}>
                <Form.Item
                  name="calling_code"
                  // label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Require call code",
                    },
                  ]}
                >
                  <Select
                    name="calling_code"
                    defaultValue="+"
                    style={{ maxWidth: 87, minWidth: 8 }}
                    showSearch
                  >
                    {map(countriesCallingCode, (item) => {
                      return (
                        <Option
                          key={item.calling_code}
                          value={item.calling_code}
                        >
                          +{item.calling_code}
                        </Option>
                      );
                    })}

                    {/* {map(countriesCallingCode, (item) => renderCountryOptions(item))} */}
                  </Select>
                </Form.Item>
              </div>

              <div className={styles.input}>
                <Form.Item
                  className={styles.input}
                  name="new_phone_no"
                  rules={[
                    {
                      required: true,
                      message: "Phone number cannot be empty",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let callingCode = parseInt(
                          getFieldValue("calling_code")
                        );
                        let phoneNo = `+${callingCode}${value}`;

                        if (value) {
                          if (isValidPhoneNumber(phoneNo)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error("Please enter correct phone no.")
                            );
                          }
                        } else {
                          return Promise.reject();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Phone No." />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>

        <div className={styles.select_container}>
          <Form.Item
            name="nationality_id"
            label={<div className={styles.label}>Nationality</div>}
            rules={[
              {
                required: true,
                message: "Country must be selected",
              },
            ]}
          >
            <Select
              placeholder="Please select a country"
              onChange={onCountryChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(countries, (item) => renderCountryOptions(item))}
            </Select>
          </Form.Item>
        </div>

        <Row justify="end">
          <Space>
            <div className={styles.button}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.button}>
              <Button htmlType="submit" className="secondary_btn">
                Create
              </Button>
            </div>
          </Space>
        </Row>
      </Form>
    </div>
  );
};

export default DashboardUserAdd;
