import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
import { find, map } from "lodash";

// ============
import styles from "./styles.module.less";
import { getAllCountries } from "../../store/country";
import { retrieveBanks, updateBankBranch } from "../../store/bank";

const { Option } = Select;

const BanksByCountryEditBranch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const location = useLocation();
  const { state: bankCountryData } = location;
  const { params } = useMatch("banks-by-country-edit-branch/:id");

  const [countries, setCountries] = useState([]);
  const [banks, setBanks] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState({
    id: null,
    name: "",
    iso_code: "",
  });

  const [selectedBank, setSelectedBank] = useState({
    id: null,
    name: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve countries

        const countriesPayload = await dispatch(getAllCountries()).unwrap();
        const { data: countryData } = countriesPayload;

        setCountries(countryData);

        // Retrieve banks

        const bankPayload = await dispatch(retrieveBanks()).unwrap();
        const { data: bankData } = bankPayload.data;

        setBanks(bankData);

        formRef.current.setFieldsValue({
          bank: bankCountryData.bank_name,
          branch_name: bankCountryData.branch_name,
          country: bankCountryData.country_id,
        });
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const countriesOnChange = (value) => {
    const selectCountry = find(countries, (item) => item.id === value);

    if (selectCountry) {
      setSelectedCountry({
        id: selectCountry.id,
        name: selectCountry.name,
        iso_code: selectCountry.iso_code_2,
      });
    }
  };

  const banksOnChange = (value) => {
    const selectBank = find(banks, (item) => item.id === value);

    if (selectBank) {
      setSelectedBank({
        id: selectBank.id,
        name: selectBank.name,
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const { data } = await dispatch(
          updateBankBranch({
            bank_country_id: params.id,
            branch_name: values.branch_name
              ? values.branch_name
              : bankCountryData.branch_name,
            country_id: values.country
              ? values.country
              : bankCountryData.country_id,
            bank_id: selectedBank.id
              ? selectedBank.id
              : bankCountryData.bank_id,
          })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>EDIT BRANCH</div>
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <div className={styles.select_container}>
            <Form.Item
              name="bank"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Bank name cannot be empty",
                },
              ]}
            >
              <Select name="bank" value={selectedBank} onChange={banksOnChange}>
                {map(banks, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="branch_name"
              label={<div className={styles.label}>Branch Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the branch name",
                },
              ]}
            >
              <Input placeholder="Branch Name" />
            </Form.Item>
          </div>
          <div className={styles.select_container}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty",
                },
              ]}
            >
              <Select
                name="country"
                value={selectedCountry}
                onChange={countriesOnChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(countries, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BanksByCountryEditBranch;
