import React from "react";
import ReactEcharts from "echarts-for-react";
import { Card } from "antd";

// ============
import styles from "./styles.module.less";
import { useSelector } from "react-redux";

const DownlineTree = ({}) => {
  const contentWidth = useSelector((state) => state.ui.contentWidth);
  const chartWidth = contentWidth - 38 - 20;
  const contentHeight = useSelector((state) => state.ui.contentHeight);

  const onChartClick = (params) => {
    console.log(params.data);
  };

  const onEvents = {
    click: onChartClick,
  };

  const data = {
    id: "1",
    name: "flare",
    value: "20%",
    children: [
      {
        id: "2",
        name: "data",
        value: "18%",
        children: [
          {
            id: "3",
            name: "converters",
            value: "16%",
            children: [
              { name: "Converters", value: "13%" },
              { name: "DelimitedTextConverter", value: "12%" },
            ],
          },
          {
            id: "4",
            name: "DataUtil",
            value: "15%",
          },
        ],
      },
      {
        id: "5",
        name: "display",
        value: "18%",
        children: [
          { id: "6", name: "DirtySprite", value: "15%" },
          { id: "7", name: "LineSprite", value: "15%" },
          { id: "8", name: "RectSprite", value: "15%" },
        ],
      },
      {
        id: "9",
        name: "flex",
        value: "19%",
        children: [{ id: "5", name: "FlareVis", value: "15%" }],
      },
      {
        id: "10",
        name: "query",
        value: "18%",
        children: [
          { id: "11", name: "AggregateExpression", value: "15%" },
          { id: "12", name: "And", value: "15%" },
          { id: "13", name: "Arithmetic", value: "15%" },
          { id: "14", name: "Average", value: "15%" },
          { id: "15", name: "BinaryExpression", value: "15%" },
          { id: "16", name: "Comparison", value: "15%" },
          { id: "17", name: "CompositeExpression", value: "15%" },
          { id: "18", name: "Count", value: "15%" },
          { id: "19", name: "DateUtil", value: "15%" },
          { id: "20", name: "Distinct", value: "15%" },
          { id: "21", name: "Expression", value: "15%" },
          { id: "22", name: "ExpressionIterator", value: "15%" },
          { id: "23", name: "Fn", value: "15%" },
          { id: "24", name: "If", value: "15%" },
          { id: "25", name: "IsA", value: "15%" },
          { id: "26", name: "Literal", value: "15%" },
          { id: "27", name: "Match", value: "15%" },
          { id: "28", name: "Maximum", value: "15%" },
          {
            id: "60",
            name: "methods",
            value: "17%",
            children: [
              { id: "29", name: "add", value: "12%" },
              { id: "30", name: "and", value: "12%" },
              { id: "31", name: "average", value: "12%" },
              { id: "32", name: "count", value: "13%" },
              { id: "33", name: "distinct", value: "12%" },
              { id: "34", name: "div", value: "14%" },
              { id: "35", name: "eq", value: "14%" },
              { id: "36", name: "fn", value: "12%" },
              { id: "37", name: "gt", value: "15%" },
              { id: "38", name: "gte", value: "15%" },
              { id: "39", name: "iff", value: "15%" },
              { id: "40", name: "isa", value: "15%" },
              { id: "41", name: "lt", value: "15%" },
              { id: "42", name: "lte", value: "15%" },
              { id: "43", name: "max", value: "16%" },
              { id: "44", name: "min", value: "13%" },
              { id: "45", name: "mod", value: "12%" },
              { id: "46", name: "mul", value: "12%" },
              { id: "47", name: "neq", value: "12%" },
              { id: "48", name: "not", value: "11%" },
              { id: "49", name: "or", value: "13%" },
              { id: "50", name: "orderby", value: "14%" },
              { id: "51", name: "range", value: "11%" },
              { id: "52", name: "select", value: "12%" },
              { id: "53", name: "stddev", value: "13%" },
              { id: "54", name: "sub", value: "12%" },
              { id: "55", name: "sum", value: "12%" },
              { id: "56", name: "update", value: "13%" },
              { id: "57", name: "variance", value: "12%" },
              { id: "58", name: "where", value: "11%" },
              { id: "59", name: "xor", value: "12%" },
              {
                id: "60",
                name: "x_x",
                value: "13%",
                children: [
                  { id: "61", name: "add", value: "11%" },
                  { id: "62", name: "and", value: "11%" },
                  { id: "63", name: "average", value: "11%" },
                  { id: "64", name: "count", value: "11%" },
                  { id: "65", name: "distinct", value: "11%" },
                  { id: "66", name: "div", value: "11%" },
                  { id: "67", name: "eq", value: "11%" },
                  { id: "68", name: "fn", value: "11%" },
                  { id: "69", name: "gt", value: "11%" },
                  { id: "70", name: "gte", value: "11%" },
                  { id: "71", name: "iff", value: "11%" },
                  { id: "72", name: "isa", value: "11%" },
                  { id: "73", name: "lt", value: "11%" },
                  { id: "74", name: "lte", value: "11%" },
                  { id: "75", name: "max", value: "11%" },
                  { id: "76", name: "min", value: "11%" },
                  { id: "77", name: "mod", value: "11%" },
                  { id: "78", name: "mul", value: "11%" },
                  { id: "79", name: "neq", value: "11%" },
                  { id: "80", name: "not", value: "11%" },
                  { id: "81", name: "or", value: "11%" },
                  { id: "82", name: "orderby", value: "11%" },
                  { id: "83", name: "range", value: "11%" },
                  { id: "84", name: "select", value: "11%" },
                  { id: "85", name: "stddev", value: "11%" },
                  { id: "86", name: "sub", value: "12%" },
                  { id: "87", name: "sum", value: "13%" },
                  { id: "88", name: "update", value: "12%" },
                  { id: "89", name: "variance", value: "12%" },
                  { id: "90", name: "where", value: "12%" },
                  { id: "91", name: "xor", value: "12%" },
                  { id: "92", name: "x_x", value: "12%" },
                ],
              },
            ],
          },
          { id: "93", name: "Minimum", value: "15%" },
          { id: "94", name: "Not", value: "15%" },
          { id: "95", name: "Or", value: "15%" },
          { id: "96", name: "Query", value: "15%" },
          { id: "97", name: "Range", value: "15%" },
          { id: "98", name: "StringUtil", value: "15%" },
          { id: "99", name: "Sum", value: "15%" },
          { id: "100", name: "Variable", value: "15%" },
          { id: "101", name: "Variance", value: "15%" },
          { id: "102", name: "Xor", value: "15%" },
        ],
      },
      {
        id: "103",
        name: "scale",
        value: "17%",
        children: [
          { id: "104", name: "IScaleMap", value: "15%" },
          { id: "105", name: "LinearScale", value: "15%" },
          { id: "106", name: "LogScale", value: "15%" },
          { id: "107", name: "OrdinalScale", value: "15%" },
          { id: "108", name: "QuantileScale", value: "15%" },
          { id: "109", name: "QuantitativeScale", value: "15%" },
          { id: "110", name: "RootScale", value: "15%" },
          { id: "111", name: "Scale", value: "15%" },
          { id: "112", name: "ScaleType", value: "15%" },
          { id: "113", name: "TimeScale", value: "15%" },
        ],
      },
      {
        id: "114",
        name: "testing",
        value: "19%",
        children: [
          { id: "115", name: "IScaleMap", value: "15%" },
          { id: "116", name: "LinearScale", value: "15%" },
          { id: "117", name: "LogScale", value: "15%" },
          { id: "118", name: "OrdinalScale", value: "15%" },
          { id: "119", name: "QuantileScale", value: "15%" },
          { id: "120", name: "QuantitativeScale", value: "15%" },
          { id: "121", name: "RootScale", value: "15%" },
          { id: "122", name: "Scale", value: "15%" },
          { id: "123", name: "ScaleType", value: "15%" },
          { id: "124", name: "TimeScale", value: "15%" },
        ],
      },
      {
        id: "125",
        name: "testing2",
        value: "18%",
        children: [
          { id: "126", name: "IScaleMap", value: "15%" },
          { id: "127", name: "LinearScale", value: "15%" },
          { id: "128", name: "LogScale", value: "15%" },
          { id: "129", name: "OrdinalScale", value: "15%" },
          { id: "130", name: "QuantileScale", value: "15%" },
          { id: "131", name: "QuantitativeScale", value: "15%" },
          { id: "132", name: "RootScale", value: "15%" },
          { id: "133", name: "Scale", value: "15%" },
          { id: "134", name: "ScaleType", value: "15%" },
          { id: "135", name: "TimeScale", value: "15%" },
        ],
      },
    ],
  };
  const option = {
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",

      formatter: function (params) {
        return `${params.name}<br />
                ${params.data.id}`;
      },
    },

    series: [
      {
        roam: true,
        type: "tree",
        id: 0,
        name: "tree1",
        data: [data],
        top: "-1%",
        left: "7%",
        bottom: "-1%",
        right: "20%",
        symbolSize: 7,
        edgeShape: "polyline",
        edgeForkPosition: "63%",
        initialTreeDepth: 3,
        lineStyle: {
          width: 2,
        },
        label: {
          backgroundColor: "#ffffff",
          position: "left",
          verticalAlign: "middle",
          align: "right",
          fontFamily: "Nunito Sans",
          fontSize: 14,

          formatter: function (params) {
            return `${params.name} (${params.data.value})`;
          },
        },
        leaves: {
          label: {
            position: "right",
            verticalAlign: "middle",
            align: "left",
          },
        },
        emphasis: {
          focus: "descendant",
        },
        expandAndCollapse: false,
        animationDuration: 550,
        animationDurationUpdate: 750,
      },
    ],
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Card className={styles.dashboard_card}>
        <ReactEcharts
          style={{
            height: contentHeight,
            width: chartWidth,
            marginLeft: 20,
            marginTop: 20,
          }}
          option={option}
          onEvents={onEvents}
        />
      </Card>
    </div>
  );
};

export default DownlineTree;
