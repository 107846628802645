import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler, queryBuilder } from "../configs";
import { join, isEmpty, includes } from "lodash";

const initialState = {
  user: [],
  pendingCount: {
    kycCount: null
  }
};

export const findAllUsers = createAsyncThunk(
  "users/findAllUsers",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "users/all";

      if (paginate) {
        url = queryBuilder("users/all", getState().ui.table, filter);
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findDashboardUserById = createAsyncThunk(
  "user/findUserById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`users/${id}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Customers (App Users)
export const findAllCustomers = createAsyncThunk(
  "user/findAllCustomers",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "users/customer/all";

      if (paginate) {
        url = queryBuilder("users/customer/all", getState().ui.table, filter);
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCustomerById = createAsyncThunk(
  "user/findCustomerById",
  async ({ id, mode }) => {
    try {
      const { data } = await axios.get(`users/customer/${id}?mode=${mode}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCustomerWalletsById = createAsyncThunk(
  "user/findCustomerWalletsById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`users/customer/wallet/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCustomerById = createAsyncThunk(
  "user/updateCustomerById",
  async ({ id, values }) => {
    try {
      const { data } = await axios.patch(`users/customer/${id}`, values);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCustomerStatusById = createAsyncThunk(
  "user/updateCustomerStatusById",
  async ({ id, values }) => {
    try {
      const { data } = await axios.patch(`users/customer-status/${id}`, values);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Admins

export const createUser = createAsyncThunk(
  "user/createNewUser",
  async (values) => {
    const {
      new_username,
      new_fullname,
      new_email,
      new_phone_no,
      // nationality_id,
      role,
      status
    } = values;

    try {
      let formData = new FormData();

      formData.append("username", new_username.trim());
      formData.append("fullname", new_fullname.trim());
      formData.append("email", new_email);
      formData.append("phone_no", new_phone_no);
      // formData.append("nationality_id", nationality_id);
      formData.append("role_code", role);
      formData.append("status", status);

      const { data } = await axios.post("users/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findUserProfile = createAsyncThunk(
  "users/findUserProfile",
  async (_) => {
    try {
      const { data } = await axios.get(`users/profile`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrievePendingKycsCounts = createAsyncThunk(
  "user/retrievePendingKycsCounts",
  async (_) => {
    try {
      const { data } = await axios.get(`users/kyc/count`);

      return {
        data
      };
    } catch (error) {
      console.log(error);
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findUserDevice = createAsyncThunk(
  "users/findUserDevice",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`users/device/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllSuperAdmins = createAsyncThunk(
  "users/findAllUsersByRoleId",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder("users/role/3", getState().ui.table, filter);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllCustomerServiceUsers = createAsyncThunk(
  "users/findAllUsersByRoleId",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder("users/role/1", getState().ui.table, filter);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllDashboardUsers = createAsyncThunk(
  "users/findAllDashboardUsers",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "users/dashboard/all";

      if (paginate) {
        url = queryBuilder("users/dashboard/all", getState().ui.table, filter);
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllCaseAssignee = createAsyncThunk(
  "users/findAllCaseAssignee",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "users/case/assignee";

      if (paginate) {
        url = queryBuilder("users/case/assignee", getState().ui.table, filter);
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findUsersMobileSecureCode = createAsyncThunk(
  "users/findUsersMobileSecureCode",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder(
        "users/mobile-secure-code",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllMembersWalletBalance = createAsyncThunk(
  "users/findAllMembersWalletBalance",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder(
        "users/wallet-balance",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllPendingKyc = createAsyncThunk(
  "users/findAllPendingKyc",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder("users/kyc/pending", getState().ui.table, filter);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findUserKycById = createAsyncThunk(
  "users/findUserKycById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`users/kyc/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const assignSubmission = createAsyncThunk(
  "users/assignSubmission",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("id", values.id);
      formData.append("action", values.action);

      if (values.action === "move") {
        formData.append("assigned_user_id", values.assigned_user_id);
      }

      const { data } = await axios.post("users/assign", formData, {
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "multipart/form-data",
        // },
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateKycById = createAsyncThunk(
  "user/updateKycById",
  async (values) => {
    try {
      let dataTemp = JSON.stringify({
        status: values.status,
        reason: values.reason ? values.reason : null
      });

      const { data } = await axios.patch(`users/kyc/${values.id}`, dataTemp, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      console.error("Axios Error:", error);
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const resetPinAttemptsByUserId = createAsyncThunk(
  "user/resetPinAttemptsByUserId",
  async ({ values }) => {
    try {
      const { data } = await axios.patch(`users/pin-attempts/reset`, values);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // Just to match the retrieveUserHistory endpoint
    const matchBuilder = (action, status) =>
      action.type.startsWith("user/retrieveUserHistory") &&
      action.type.endsWith(status);
    // Add reducers for additional action types here, and handle loading state as needed

    builder.addCase(
      retrievePendingKycsCounts.fulfilled,
      (state, { payload }) => {
        const count = payload.data;

        state.pendingCount = {
          kycCount: count
        };
      }
    );

    builder.addMatcher(
      (action) => matchBuilder(action, "/pending"),
      (state) => ({
        ...state,
        userHistory: {
          ...state.userHistory,
          loading: true
        }
      })
    );
    builder.addMatcher(
      (action) => matchBuilder(action, "/rejected"),
      (state) => ({
        ...state,
        userHistory: {
          ...state.userHistory,
          loading: false
        }
      })
    );
  }
});

export const {
  setLanguage,
  resetTfa,
  setIsLoggedIn,
  resetUser,
  setIsSessionExpiredModalVisible,
  resetApp,
  resetUserHistory,
  setUserHistoryLoading
} = userSlice.actions;

export default userSlice.reducer;
