import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  Card,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Col,
} from "antd";
import { map, filter } from "lodash";
// ============
import styles from "./styles.module.less";
import { findAllStatuses } from "../../store/app";
import { retrieveBankBranchesWithAccounts } from "../../store/bank";
import transfer, {
  findTransferCaseById,
  updateTransferById,
} from "../../store/transfer";

const { Option } = Select;

const TransferEdit = () => {
  const { params } = useMatch("transfer/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [transferInfo, setTransferInfo] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [bankBranches, setBankBranches] = useState([]);
  const [allowEditing, setAllowEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Processing");
  const userId = useSelector((state) => state.app.user.id);
  const isLoading = useSelector((state) => state.transfer.isLoading);

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve statuses
      try {
        const statusPayload = await dispatch(findAllStatuses()).unwrap();
        const { data: statusData } = statusPayload;
        const filteredStatusData = filter(statusData, (item) =>
          ["Processing", "Successful", "Unsuccessful"].includes(item.name)
        );
        setStatuses(filteredStatusData);

        const transferPayload = await dispatch(
          findTransferCaseById({
            id: params.id,
          })
        ).unwrap();

        const { data: transferData } = transferPayload;
        if (transferData) {
          if (transferData.status == 1 || transferData.status == 2) {
            // Retrieve bank branches
            // const bankBranchPayload = await dispatch(
            //   retrieveBankBranchesWithAccounts({
            //     country: transferData.recipient_country.id,
            //     currency: transferData.currency.id,
            //   })
            // ).unwrap();

            // const { data: bankBranchData } = bankBranchPayload;

            // setBankBranches(bankBranchData);
            setTransferInfo(transferData);

            if (transferData.assigned_user_id) {
              let assignedUserId = transferData.assigned_user_id;

              if (assignedUserId == userId) {
                setAllowEditing(true);
              }
            }

            formRef.current.setFieldsValue({
              from: transferData.user_fullname,
              requested_currency: transferData.to_currency_code,
              recipient_country: transferData.country_name,
              recipient_bank: transferData.bank_name,
              recipient_acc_name: transferData.reference_bank_account_name,
              recipient_account: transferData.reference_bank_account_no,
              amount: numeral(parseFloat(transferData.amount)).format("0.00"),
              // purpose: transferData.purpose,
              status: transferData.status,
              reject_reason: transferData.reject_reason_text,
            });
          } else {
            message.error("This transfer record is not available for editing");
            navigate("/transfer");
          }
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    console.log(allowEditing);
  }, [allowEditing]);

  const sourceBankOnChange = (value) => {
    resetSourceAccountOnBankChange();
  };

  const resetSourceAccountOnBankChange = () => {
    formRef.current.setFieldsValue({
      recipient_account: null,
    });
  };

  const handleSubmit = async (values) => {
    try {
      if (values) {
        await dispatch(
          updateTransferById({
            id: params.id,
            // recipient_bank_id: values.recipient_bank,
            // recipient_acc_no: values.recipient_account,
            // amount: values.amount,
            // purpose: values.purpose,
            status: values.status,
            reject_reason: values.reject_reason ? values.reject_reason : null,
          })
        ).unwrap();

        message.success("You have successfully edited the transfer info");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>TRANSFER INFORMATION</div>
          {transferInfo.assigned_user_fullname && (
            <Tag color="purple">
              Case is assigned to: {transferInfo.assigned_user_fullname}
            </Tag>
          )}
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Card
            title="Sender Details"
            type="inner"
            style={{
              marginBottom: 16,
              borderRadius: "8px",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>Name:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.user_fullname}
              </Col>
              <Col span={12}>Email:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.user_email}
              </Col>
              <Col span={12}>Phone No.:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.user_phone_no}
              </Col>
            </Row>
          </Card>

          <Card
            title="Recipient Details"
            type="inner"
            style={{
              marginBottom: 16,
              borderRadius: "8px",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>Name:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.reference_bank_account_name}
              </Col>
              <Col span={12}>Country:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.country_name}
              </Col>
              <Col span={12}>Bank Name:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.bank_name}
              </Col>
              <Col span={12}>Account No.:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.reference_bank_account_no}
              </Col>
            </Row>
          </Card>

          <Card
            title="Transaction Details"
            type="inner"
            style={{
              marginBottom: 16,
              borderRadius: "8px",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>Amount:</Col>
              <Col span={12} className={styles.details_value}>
                {numeral(parseFloat(transferInfo.amount)).format("0.00")}{" "}
                {transferInfo.from_currency_code}
              </Col>
              <Col span={12}>Fees:</Col>
              <Col span={12} className={styles.details_value}>
                {numeral(parseFloat(transferInfo.processing_fee)).format(
                  "0.00"
                )}{" "}
                {transferInfo.from_currency_code}
              </Col>
              <Col span={12}>Amount to Convert:</Col>
              <Col span={12} className={styles.details_value}>
                {numeral(
                  parseFloat(transferInfo.amount) -
                    parseFloat(transferInfo.processing_fee)
                ).format("0.00")}{" "}
                {transferInfo.from_currency_code}
              </Col>
              <Col span={12}>Exchange Rate:</Col>
              <Col span={12} className={styles.details_value}>
                {transferInfo.exchange_rate ?? "-"}
              </Col>
              <Col span={12}>Converted Amount:</Col>
              <Col span={12} className={styles.details_value_converted}>
                {transferInfo.converted_amount} {transferInfo.to_currency_code}
              </Col>
            </Row>
          </Card>
          {/* <div className={styles.input}>
            <Form.Item
              name="from"
              label={<div className={styles.label}>From</div>}
            >
              <Input disabled />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="requested_currency"
              label={<div className={styles.label}>Requested Currency</div>}
            >
              <Input disabled />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="recipient_country"
              label={<div className={styles.label}>Recipient's Country</div>}
            >
              <Input disabled />
            </Form.Item>
          </div>
          <div className={styles.select_container}>
            <Form.Item
              name="recipient_bank"
              label={<div className={styles.label}>Recipient's Bank</div>}
              rules={[
                {
                  required: true,
                  message: "Please select the recipient's bank",
                },
              ]}
            >
              <Select
                onChange={sourceBankOnChange}
                style={{
                  width: "55%",
                  minWidth: 100,
                }}
                className={styles.select_container}
                disabled={!allowEditing || isLoading}
              >
                {map(bankBranches, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.branch_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.input}>
            <Form.Item
              name="recipient_account"
              label={<div className={styles.label}>Recipient's Account</div>}
              rules={[
                {
                  validator: (_, value) => {
                    if (value.includes(" ")) {
                      return Promise.reject(new Error("No spaces allowed"));
                    } else if (!value) {
                      return Promise.reject(
                        new Error("Please enter the recipient's account")
                      );
                    } else if (!value.match(/^\d+$/)) {
                      return Promise.reject(
                        new Error("Please enter number only")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input disabled={!allowEditing || isLoading} />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="recipient_acc_name"
              label={
                <div className={styles.label}>Recipient's Account Name</div>
              }
            >
              <Input disabled />
            </Form.Item>
          </div>
          <div className={styles.input_number}>
            <Form.Item
              name="amount"
              label={<div className={styles.label}>Amount</div>}
            >
              <InputNumber
                style={{
                  width: "55%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 10,
                  borderRadius: 6,
                  border: "1px solid #bfc9d4",
                  fontSize: 15,
                }}
                defaultValue="0"
                min="0"
                step="0.01"
                stringMode
                disabled={true}
              />
            </Form.Item>
          </div> 
          <div className={styles.input}>
            <Form.Item
              name="purpose"
              label={<div className={styles.label}>Purpose</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the purpose of transfer",
                },
              ]}
            >
              <Input disabled={!allowEditing || isLoading} />
            </Form.Item>
          </div> */}
          <div className={styles.select_container}>
            <Form.Item
              name="status"
              label={<div className={styles.label}>Status</div>}
              rules={[
                {
                  required: true,
                  message: "Please select the status",
                },
              ]}
            >
              <Select
                disabled={!allowEditing || isLoading}
                style={{
                  width: "55%",
                  minWidth: 100,
                }}
                onSelect={(_, option) => {
                  if (option) {
                    setSelectedStatus(option.children);
                  }
                }}
              >
                {map(statuses, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          {selectedStatus == "Unsuccessful" && (
            <div className={styles.input}>
              <Form.Item
                name="reject_reason"
                label={<div className={styles.label}>Reject Reason</div>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your reject reason",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your reject reason"
                  disabled={!allowEditing || isLoading}
                />
              </Form.Item>
            </div>
          )}

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                disabled={
                  !allowEditing || isLoading || selectedStatus === "Processing"
                }
                className={
                  allowEditing || !isLoading
                    ? "secondary_btn"
                    : "secondary_btn_disabled"
                }
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default TransferEdit;
