import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation,
  useParams,
  useMatch,
  useSearchParams,
} from "react-router-dom";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  message,
  Select,
  Input,
  Divider,
  Table,
  TimePicker,
  Switch,
  Upload,
  Modal,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { find, map, isEmpty, filter, assign, chain } from "lodash";
import moment from "moment";

import { unformat, formatNumber } from "accounting-js";
import numeral from "numeral";
// ============
import styles from "./styles.module.less";
import moneyChanger, {
  createMoneyChanger,
  findMoneyChangerById,
  updateMoneyChangerById,
} from "../../../store/money-changer";
import { retrieveAllCurrencies } from "../../../store/currency";
import {
  findActivityLogsByRefIdAndRefTable,
  findCountryStatesMaritalStatus,
  findAllCustomersEnabled,
  removeMediaById,
  createLogoMedia,
} from "@store/app";
import { toNumber, first } from "lodash";
import HistoryTable from "../../../components/history-table";
const { Option } = Select;
const { Dragger } = Upload;

const EditMoneyChanger = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { params } = useMatch("settings/money-changer/:id");

  // const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [countries, setCountries] = useState([]);
  const [users, setUsers] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [mediaId, setMediaId] = useState(null);

  const dimensions = { min: 100, max: 600 };

  const [states, setStates] = useState([]);
  const [moneyChangerData, setMoneyChangerData] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [isCloseArr, setIsCloseArr] = useState([]);
  const [operationList, setOperationList] = useState([
    {
      key: "1",
      days: "Sunday",
      is_closed: false,
      hours: null,
    },
    {
      key: "2",
      days: "Monday",
      is_closed: false,
      hours: null,
    },
    {
      key: "3",
      days: "Tuesday",
      is_closed: false,
      hours: null,
    },
    {
      key: "4",
      days: "Wednesday",
      is_closed: false,
      hours: null,
    },
    {
      key: "5",
      days: "Thursday",
      is_closed: false,
      hours: null,
    },
    {
      key: "6",
      days: "Friday",
      is_closed: false,
      hours: null,
    },
    {
      key: "7",
      days: "Saturday",
      is_closed: false,
      hours: null,
    },
  ]);

  // const country = useSelector((state) =>
  //   filter(state.country.allCountries, (item) => item.is_enabled == 1)
  // );

  const formRef = useRef();

  const columns = [
    {
      title: <div className={styles.label}>Days</div>,
      dataIndex: "days",
      key: "days",
      render: (text) => <a>{text}</a>,
    },
    {
      title: <div className={styles.label}>Hours</div>,
      dataIndex: "hours",
      key: "hours",
      render: (_, record, index) => {
        const { key } = record;
        return (
          // <div>
          <>
            {record.is_closed ? (
              <div>Closed</div>
            ) : (
              <Form.Item
                name={["operations", index, "hours"]}
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select a time",
                  },
                ]}
              >
                <TimePicker.RangePicker
                  className="time-picker"
                  format="HH:mm"
                  onChange={(values) => {
                    onChangeTime(values, key);
                  }}
                />
              </Form.Item>
            )}
          </>

          // </div>
        );
      },
    },

    {
      title: <div className={styles.label}>Action</div>,
      key: "action",
      render: (_, record) => {
        const { key, is_closed } = record;
        return (
          <>
            <Switch
              checkedChildren="Open"
              unCheckedChildren="Close"
              checked={!is_closed}
              onChange={(checked) => {
                onChangeSwitch(checked, key);
              }}
            />
          </>
        );
      },
    },
  ];

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  useEffect(() => {
    // set Status initial value
    formRef.current.setFieldsValue({
      is_active: statusOption[0].value,
    });

    // console.log(operationList);
    formRef.current.setFieldValue("operations", operationList);
    // setOperationList(data);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: initializeData } = await dispatch(
          findCountryStatesMaritalStatus()
        ).unwrap();

        setCountries(initializeData.country);

        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;
        setCurrency(currencyData);

        const { data: memberData } = await dispatch(
          findAllCustomersEnabled({
            id: params.id,
          })
        ).unwrap();

        // filter memberdata to only get users with no money changer and themselves(initial data)
        // memberData = memberData.filter((x) => {return (x.money_changer_id == null || x.money_changer_id == params.id)});

        setUsers(memberData);
        if (params.id !== "add") {
          const { data: moneyChanger } = await dispatch(
            findMoneyChangerById({
              id: params.id,
            })
          ).unwrap();
          setMoneyChangerData(moneyChanger);

          if (moneyChanger) {
            const currencyListed = [];
            const currencyArr = moneyChanger.currency_available.split(",");
            for (var i = 0; i < currencyArr.length; i++) {
              currencyListed.push(toNumber(currencyArr[i]));
            }

            let userArr = [];
            if (moneyChanger.users.length) {
              for (var i = 0; i < moneyChanger.users.length; i++) {
                userArr.push(moneyChanger.users[i].id);
              }
            }

            formRef.current.setFieldsValue({
              name: moneyChanger.name,
              pic: moneyChanger.pic,
              phone: moneyChanger.phone,
              // email: moneyChanger.email,
              currency_available: currencyListed,
              // operation_days: moneyChanger.operation_days,
              // operation_hours: moneyChanger.operation_hours,
              assign_users: userArr ? userArr[0] : null,
              // emergency_phone: moneyChanger.emergency_phone,
              emergency_pic: moneyChanger.emergency_pic,
              is_active: moneyChanger.is_active,
              address_1: moneyChanger.address?.address_1,
              address_2: moneyChanger.address?.address_2,
              country: moneyChanger.address?.country_id,
              state: moneyChanger.address?.state_id,
              city: moneyChanger.address?.city,
              postcode: moneyChanger.address?.postcode,
              longitude: moneyChanger?.longitude,
              latitude: moneyChanger?.latitude,
              google_map: moneyChanger?.google_map,
              waze_map: moneyChanger?.waze_map,
            });

            if (moneyChanger.operation.length) {
              let operationValues = map(moneyChanger.operation, (item) => {
                const { hours, is_closed } = item;
                let remapArrayHours = null;
                if (hours) {
                  let arrayHours = hours.split(" to ");
                  remapArrayHours = map(arrayHours, (hourItem) => {
                    return moment(hourItem, "HH:mm");
                  });
                }

                return { ...item, hours: remapArrayHours, key: item.id };
              });
              setOperationList(operationValues);
              formRef.current.setFieldValue("operations", operationValues);
            }

            if (moneyChanger.address?.country_id) {
              formRef.current.setFieldsValue({
                state: null,
              });

              let selectedCountry = find(initializeData.country, {
                id: moneyChanger.address?.country_id,
              });
              if (selectedCountry) {
                setStates(selectedCountry.state);
              }

              formRef.current.setFieldsValue({
                state: moneyChanger.address?.state_id,
              });
            }

            if (moneyChanger.media) {
              setMediaId(moneyChanger.media_id);
              let newFileList = [
                {
                  uid: moneyChanger.media.id,
                  name: moneyChanger.media.filename,
                  status: "done",
                  url: moneyChanger.media.file_url,
                },
              ];

              setFileList(newFileList);
              formRef.current.setFieldValue("logo", newFileList);
            }
          }

          const { data: moneyChangerHistoryData } = await dispatch(
            findActivityLogsByRefIdAndRefTable({
              reference_id: params.id,
              reference_table: "money_changers",
            })
          ).unwrap();

          const { data: addressHistoryData } = await dispatch(
            findActivityLogsByRefIdAndRefTable({
              reference_id: moneyChanger.address_id,
              reference_table: "addresses",
            })
          ).unwrap();

          // sort by id desc (get latest first)
          let historyData = moneyChangerHistoryData
            .concat(addressHistoryData)
            .sort((x, y) => {
              return y.id - x.id;
            });

          setHistoryData(historyData);
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const customRequest = async ({ file, onSuccess }) => {
    try {
      let { data } = await dispatch(
        createLogoMedia({
          file,
        })
      ).unwrap();

      setMediaId(data[0]);

      onSuccess("Image successfully uploaded");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const logoOnPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const logoOnChange = ({ fileList: newFileList }) => {
    const media = first(newFileList);

    setFileList(newFileList);
    formRef.current.setFieldValue("logo", newFileList);
    formRef.current.validateFields();

    if (media) {
      const { response, status } = media;
      if (status === "done") {
        message.success(response);
      }
    }
  };

  const logoOnRemove = async (file) => {
    await dispatch(
      removeMediaById({
        id: mediaId,
      })
    ).unwrap();
    formRef.current.setFieldValue("logo", undefined);

    message.success("Successfully deleted image");

    setMediaId(null);
  };

  const logoBeforeUpload = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = () => {
          if (
            img.naturalWidth <= dimensions.max &&
            img.naturalWidth >= dimensions.min &&
            img.naturalWidth == img.naturalHeight
          ) {
            const canvas = document.createElement("canvas");
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((result) => resolve(result));
          } else {
            message.error("Logo dimensions invalid");
          }
        };
      };
    });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangeTime = (value, index) => {
    // let { operations } = formRef.current.getFieldsValue();
    // console.log(operations);
    let list = [];

    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === index) {
          item.hours = value;
        }

        return item;
      })
      .value();

    formRef.current.setFieldValue("operations", newOperationList);
    setOperationList(newOperationList);
  };

  const onChangeCloseArr = (value) => {
    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === value) {
          item.is_closed = true;
        }

        return item;
      })
      .value();
    formRef.current.setFieldValue("operations", newOperationList);

    setOperationList(newOperationList);
  };

  const onChangeOpenArr = (value) => {
    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === value) {
          item.hours = null;

          item.is_closed = false;
        }

        return item;
      })
      .value();
    formRef.current.setFieldValue("operations", newOperationList);

    setOperationList(newOperationList);
  };
  const onChangeSwitch = (checked, value) => {
    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === value) {
          if (!checked) {
            item.hours = null;
          }
          item.is_closed = !checked;
        }

        return item;
      })
      .value();

    formRef.current.setFieldValue("operations", newOperationList);
    setOperationList(newOperationList);
  };
  const onChangeCountries = (value) => {
    formRef.current.setFieldsValue({
      state: null,
    });

    let selectedCountry = find(countries, { id: value });
    if (selectedCountry) {
      setStates(selectedCountry.state);
    }
  };
  const phoneNumberOnChange = async ({ target }) => {
    const { value } = target;
    let text = value;
    let id = target.id;
    let newId = id.replace("basic_", "");
    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/gi, "");
    formRef.current.setFieldValue(newId, formattedPhoneNumber);
  };

  const handleEdit = async (values) => {
    const { operations } = values;
    let operationValues = map(operations, (item) => {
      const { hours, is_closed } = item;
      let text = null;
      if (hours && !is_closed) {
        text = `${moment(hours[0], "HH:mm").format("HH:mm")} to ${moment(
          hours[1],
          "HH:mm"
        ).format("HH:mm")}`;
      } else if (hours && is_closed) {
        text = null;
      }

      return { ...item, hours: text };
    });
    try {
      if (values) {
        let numbersToString = values.currency_available.toString();

        let addressReferenceId = null;
        if (moneyChangerData.address && values.address_1) {
          addressReferenceId = moneyChangerData.id;
        }
        for (var key in values) {
          var value = values[key];
          if (value === "") {
            values[key] = null;
          }
        }
        values = {
          ...values,
          media_id: mediaId,
          operations: operationValues,
          currency_available: numbersToString,
          reference_table: "money_changers",
          reference_id: addressReferenceId,
        };
        const dataRes = await dispatch(
          updateMoneyChangerById({
            id: params.id,
            values,
          })
        ).unwrap();
        message.success(dataRes.data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleSubmit = async (values) => {
    const { operations } = values;
    let operationValues = map(operations, (item) => {
      const { hours, is_closed } = item;
      let text = null;
      if (hours && !is_closed) {
        text = `${moment(hours[0], "HH:mm").format("HH:mm")} to ${moment(
          hours[1],
          "HH:mm"
        ).format("HH:mm")}`;
      } else if (hours && is_closed) {
        text = null;
      }

      return { ...item, hours: text };
    });
    try {
      if (values) {
        await dispatch(
          createMoneyChanger({
            address_1: values.address_1 ? values.address_1 : "",
            address_2: values.address_2 ? values.address_2 : "",
            city: values.city ?? "",
            country: values.country ?? "",
            currency_available: values.currency_available,
            // email: values.email,
            // emergency_phone: values.emergency_phone,
            // emergency_pic: values.emergency_pic,
            name: values.name,
            operations: operationValues,
            longitude: values?.longitude ?? null,
            latitude: values?.latitude ?? null,
            google_map: values?.google_map ?? null,
            waze_map: values?.waze_map ?? null,
            phone: values.phone,
            pic: values.pic,
            postcode: values.postcode ?? "",
            state: values.state ?? "",
            is_active: values.is_active,
            assign_users: values.assign_users ?? "",
            media_id: mediaId,
          })
        ).unwrap();
        message.success("You have successfully added new money changer");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>
            {params?.id === "add" ? "New" : "Edit"} Money Changer
          </div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={params.id === "add" ? handleSubmit : handleEdit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item name="is_active" label="Status">
            <Select name="is_active">
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Business Name"
            name="name"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the business name",
              },
            ]}
          >
            <Input placeholder="Business Name" />
          </Form.Item>
          <Form.Item
            label="Person In Charge"
            name="pic"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the person in charge",
              },
            ]}
          >
            <Input placeholder="Person In Charge" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Mobile"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the mobile number",
              },
            ]}
          >
            <Input placeholder="Mobile" onChange={phoneNumberOnChange} />
          </Form.Item>
          {/* <Form.Item
            name="email"
            label="Email"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the email"
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Please enter a valid email"
              }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item> */}

          <Form.Item
            name="logo"
            label="Logo"
            rules={[
              {
                required: true,
                message: "Please upload the logo",
              },
            ]}
          >
            <Dragger
              customRequest={customRequest}
              fileList={fileList}
              onPreview={logoOnPreview}
              onChange={logoOnChange}
              onRemove={logoOnRemove}
              beforeUpload={logoBeforeUpload}
              listType="picture"
              multiple={false}
              maxCount={1}
              withCredentials
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined className={styles.inbox_icon} />
              </p>
              <p className={`ant-upload-text ${styles.inbox_description}`}>
                Click or drag file to this area to upload
              </p>
            </Dragger>
            <Modal
              visible={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
            <div className={styles.dimension_text}>
              (Aspect Ratio: 1:1, Min size: 100 x 100px, Max size: 600 x 600px)
            </div>
          </Form.Item>

          <Form.Item
            name="currency_available"
            // value={selectedCurrency}

            label="Currency"
            rules={[
              {
                required: true,
                message: "Please enter the currency",
              },
            ]}
          >
            <Select
              mode="multiple"
              // value={selectedCurrency}
              placeholder="Select Currency"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="operations" label="Operation" className="input">
            <Table
              columns={columns}
              dataSource={operationList}
              style={{ width: "66%" }}
              pagination={false}
              rowClassName={styles.rowClass}
            />
          </Form.Item>

          {/* <Form.Item
            name="operation_days"
            label="Operation Days"
            className="input"
          >
            <Input placeholder="Operation Days" />
          </Form.Item>

          <Form.Item
            name="operation_hours"
            label="Operation Hours"
            className="input"
          >
            <Input placeholder="Operation Hours" />
          </Form.Item> */}

          {/* <Form.Item
            label={
              // <div>
              //   <div>Emergency Person</div>
              //   <div>In Charge</div>
              // </div>
              <div style={{ marginRight: 20 }}>
                <Col>
                  <Row justify="end">Emergency</Row>
                  <Row justify="end">Person In Charge</Row>
                </Col>
              </div>
            }
            name="emergency_pic"
            className="two-rows-emergency-label"
            // className="input"
            rules={[
              {
                required: true,
                message: "Please enter the emergency person in charge",
              },
            ]}
          >
            <Input placeholder="Emergency Person In Charge" />
          </Form.Item>

          <Form.Item
            name="emergency_phone"
            label="Emergency Contact"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the emergency contact number",
              },
            ]}
          >
            <Input
              placeholder="Emergency Contact"
              onChange={phoneNumberOnChange}
            />
          </Form.Item> */}
          <Divider className="divider" />

          <Form.Item
            name="address_1"
            label="Address Line 1"
            className="input"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  /**
                   * If one of the address related field being filled in, then another 4 fields become required
                   */
                  if (
                    getFieldValue("address_2") ||
                    getFieldValue("country") ||
                    getFieldValue("city") ||
                    getFieldValue("state") ||
                    getFieldValue("postcode")
                  ) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Please enter Address Line 1")
                      );
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Address Line 1" allowClear />
          </Form.Item>

          <Form.Item
            name="address_2"
            label="Address Line 2"
            className={styles.input}
          >
            <Input placeholder="Address Line 2" allowClear />
          </Form.Item>

          <Form.Item
            name="country"
            label="Country"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  /**
                   * If one of the address related field being filled in, then another 4 fields become required
                   */
                  if (
                    getFieldValue("address_1") ||
                    getFieldValue("address_2") ||
                    getFieldValue("city") ||
                    getFieldValue("state") ||
                    getFieldValue("postcode")
                  ) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Please select a country")
                      );
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              // name="country"
              placeholder="Select country"
              //   value={selectedCountry}
              onChange={onChangeCountries}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              allowClear
            >
              {map(countries, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  /**
                   * If one of the address related field being filled in, then another 4 fields become required
                   */
                  if (
                    getFieldValue("address_1") ||
                    getFieldValue("address_2") ||
                    getFieldValue("city") ||
                    getFieldValue("country") ||
                    getFieldValue("postcode")
                  ) {
                    if (!value) {
                      return Promise.reject(new Error("Please select a state"));
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select name="state" placeholder="State" allowClear>
              {map(states, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="city"
            label="City"
            className="input"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  /**
                   * If one of the address related field being filled in, then another 4 fields become required
                   */
                  if (
                    getFieldValue("address_1") ||
                    getFieldValue("address_2") ||
                    getFieldValue("state") ||
                    getFieldValue("country") ||
                    getFieldValue("postcode")
                  ) {
                    if (!value) {
                      return Promise.reject(new Error("Please enter City"));
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="City" allowClear />
          </Form.Item>

          <Form.Item
            name="postcode"
            label="Postcode"
            className="input"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  /**
                   * If one of the address related field being filled in, then another 4 fields become required
                   */
                  if (
                    getFieldValue("address_1") ||
                    getFieldValue("address_2") ||
                    getFieldValue("state") ||
                    getFieldValue("country") ||
                    getFieldValue("city")
                  ) {
                    if (!value) {
                      return Promise.reject(new Error("Please enter Postcode"));
                    }
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Postcode" allowClear />
          </Form.Item>
          <Divider className="divider" />

          <Form.Item
            label="Google Map Link"
            name="google_map"
            className="input"
          >
            <Input placeholder="Google Map Link" allowClear />
          </Form.Item>
          <Form.Item label="Waze Map Link" name="waze_map" className="input">
            <Input placeholder="Waze Map Link" allowClear />
          </Form.Item>

          <Form.Item
            label="Latitude"
            name="latitude"
            className="input"
            rules={[
              {
                pattern: "^([-]?[0-9]+(.[0-9]*)?)$",
                message: "The input is not valid latitude!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value && getFieldValue("longitude")) {
                    return Promise.reject("Please enter latitude");
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Latitude" allowClear />
          </Form.Item>

          <Form.Item
            label="Longitude"
            name="longitude"
            className="input"
            rules={[
              {
                pattern: "^([-]?[0-9]+(.[0-9]*)?)$",
                message: "The input is not valid longitude!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value && getFieldValue("latitude")) {
                    return Promise.reject("Please enter longitude");
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Longitude" allowClear />
          </Form.Item>

          <Form.Item
            name="assign_users"
            // value={selectedCurrency}

            label="Assign Users"
          >
            <Select
              // mode="multiple"
              // value={selectedCurrency}
              placeholder="Select Users"
              showSearch
              optionFilterProp="children"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(users, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.username}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>

        {params?.id !== "add" ? <HistoryTable data={historyData} /> : ""}
      </Card>
    </div>
  );
};

export default EditMoneyChanger;
