import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";

import { Card, Form, Input, Row, Select, Button, message, Divider } from "antd";
// ============
import styles from "./styles.module.less";
import { findAdjustmemtById } from "@store/transaction";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";

import HistoryTable from "@components/history-table";
import moment from "moment-timezone";
import { filter } from "lodash";
import numeral from "numeral";

const AdjustmentView = () => {
  const { params } = useMatch("adjustments/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [adjustmentData, setAdjustmentData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transaction = await dispatch(
          findAdjustmemtById({
            id: params.id
          })
        ).unwrap();

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "adjustments"
          })
        ).unwrap();

        const filteredHistoryData = filter(
          historyData,
          (item) => item.action_type !== "View"
        );

        setHistoryData(filteredHistoryData);

        const { data } = transaction;
        setAdjustmentData(data.adjustment);
        setUserData(data.user);
        setCurrencyData(data.currency);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">View Adjustment </div>
        </Row>
        <Form
          colon={false}
          // ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
        >
          <Form.Item name="doc_id" label="ID" className="label-details">
            <div>{adjustmentData.id}</div>
          </Form.Item>

          <Form.Item
            name="date"
            label="Admission Date"
            className="label-details"
          >
            <div>
              {moment
                .utc(adjustmentData.created_at)
                .tz("Asia/Phnom_Penh")
                .format("DD-MM-YYYY, hh:mm:ss A")}
            </div>
          </Form.Item>

          <Divider className="divider" />

          <Form.Item
            name="customer_topkash_id"
            label="Topkash ID"
            className="label-details"
          >
            <div>{userData.topkash_id ? userData.topkash_id : "-"}</div>
          </Form.Item>

          <Form.Item name="username" label="Username" className="label-details">
            <div>{userData.username ? userData.username : "-"}</div>
          </Form.Item>

          <Form.Item name="name" label="Full Name" className="label-details">
            <div>{userData.fullname ? userData.fullname : "-"}</div>
          </Form.Item>
          <Form.Item name="mobile" label="Mobile" className="label-details">
            <div>{userData.phone_no ? userData.phone_no : "-"}</div>
          </Form.Item>
          <Form.Item name="email" label="Email" className="label-details">
            <div>{userData.email ? userData.email : "-"}</div>
          </Form.Item>
          <Divider className="divider" />

          <Form.Item name="currency" label="Currency" className="label-details">
            <div>
              {currencyData.iso_code} - {currencyData.currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="reason"
            label="Reason For Adjustment"
            className="label-details"
          >
            <div>{adjustmentData.reason}</div>
          </Form.Item>

          {adjustmentData.type == "add" && (
            <Form.Item name="amount" label="Amount" className="label-details">
              <div
                style={{
                  color: "#029b67"
                }}
              >
                Added {currencyData.iso_code} To {userData.username}
              </div>
              {/* <br></br> */}
              <Input
                disabled
                placeholder={numeral(parseFloat(adjustmentData.amount)).format(
                  "0,0.00"
                )}
                style={{
                  width: 235,
                  borderColor: "#029b67",
                  textAlign: "right"
                }}
              />
            </Form.Item>
          )}

          {adjustmentData.type == "deduct" && (
            <Form.Item name="amount" label="Amount" className="label-details">
              <div
                style={{
                  color: "#cf1322",
                  textAlign: "right",
                  display: "inline-block"
                }}
              >
                Deducted {currencyData.iso_code} From {userData.username}
              </div>
              <br></br>
              <Input
                placeholder={numeral(parseFloat(adjustmentData.amount)).format(
                  "0,0.00"
                )}
                style={{
                  width: 235,
                  borderColor: "#cf1322",
                  textAlign: "right"
                }}
                disabled
              />
            </Form.Item>
          )}

          <Divider className="divider" />

          <Form.Item
            name="created_by"
            label="Assigned To"
            className="label-details"
          >
            <div>{adjustmentData.created_by}</div>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            </div>
          </Row>
        </Form>
        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default AdjustmentView;
