import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Select,
  Button,
  Col,
  DatePicker,
  Divider,
  Card,
  Form
} from "antd";
import { map } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// ============
import { retrieveAllCurrencies } from "@store/currency";
import { getAllCountries } from "@store/country";
import { findAllDashboardUsers, findAllStatuses } from "@store/app";
import { generateAllTransactionsReport } from "@store/report";
import ReportPreviewModal from "../../../components/report-preview-modal";
import { downloadFromUrl } from "../../../configs/utilities";
import { findAllCustomers } from "@store/user";

import styles from "./styles.module.less";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AllTransactionsReport = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const mode = useRef(null)

  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [members, setMembers] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [file, setFile] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    initialise();

    //Set default date value
    formRef.current.setFieldsValue({
      submission_date: [moment(), moment()]
    });
  }, []);

  const initialise = async () => {
    const { data: currencyData } = await dispatch(
      retrieveAllCurrencies()
    ).unwrap();
    const { data: countryData } = await dispatch(getAllCountries()).unwrap();
    const { data: userData } = await dispatch(
      findAllDashboardUsers({ paginate: false })
    ).unwrap();
    const { data: statusData } = await dispatch(findAllStatuses()).unwrap();
    const filteredStatusData = statusData.filter((item) =>
      ["Pending", "Processing", "Successful", "Unsuccessful"].includes(
        item.name
      )
    );
    const { data: memberData } = await dispatch(
      findAllCustomers({ paginate: false })
    ).unwrap();

    setMembers(memberData);
    setStatuses(filteredStatusData);
    setUsers(userData);
    setCountries(countryData);
    setCurrencies(currencyData);
  };

  const transactionTypeOptions = [
    { value: "1", label: "Top-Ups (Bank)" },
    { value: "2", label: "Sends (Bank)" },
    { value: "3", label: "Conversions" },
    { value: "4", label: "Sends (Member)" },
    { value: "5", label: "Top-Ups (Gift Card)" },
    { value: "6", label: "Top-Ups (Crypto)" },
    { value: "8", label: "Adjustments" }
  ];

  const exportReport = async () => {
    mode.current = 'download'

    formRef.current.submit()
  };

  const onFinish = async (values) => {
    if (values.submission_date) {
      let start = values.submission_date[0].format("YYYYMMDD");
      let end = values.submission_date[1].format("YYYYMMDD");

      values = {
        ...values,
        submission_date: [start, end]
      };
    }

    setLoading(true)

    try {
      const { url, file_name } = await dispatch(
        generateAllTransactionsReport({ filter: values, mode: mode.current })
      ).unwrap();

      if (mode.current === 'preview') {
        setFile({ url, name: file_name })
        setOpenPreviewModal(true)
      }
      else {
        downloadFromUrl(url, file_name)
      }

    } catch (error) {
      message.error(error.message);
    }
    finally {
      setLoading(false)
    }
  };

  const previewReport = () => {
    mode.current = 'preview'

    formRef.current.submit()
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">All Transactions Report</div>
        </Row>
        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item
            name="submission_date"
            label="Submission Date"
            rules={[
              {
                required: true,
                message: "Please enter the submission date"
              }
            ]}
          >
            <RangePicker
              name="selected_date"
              placeholder={["Start Date", "End Date"]}
              allowClear
              format="DD-MM-YYYY"
              disabledDate={(date) => {
                if (date <= moment().subtract(3, 'month') || moment().add(0, "days") <= date) {
                  return true
                }
                else {
                  return false
                }
              }}
            />
          </Form.Item>

          <Form.Item name="transaction_type" label="Transaction Type">
            <Select
              allowClear
              name="country"
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(transactionTypeOptions, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="from_currency" label="From Currency">
            <Select
              allowClear
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.iso_code}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="to_currency" label="To Currency">
            <Select
              allowClear
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.iso_code}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="sender_username" label="Sender Username">
            <Select
              allowClear
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(members, (item) => {
                return (
                  <Option key={item.id} value={item.username}>
                    {item.username && item.fullname
                      ? item.username + " - " + item.fullname
                      : item.username}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="status" label="Status">
            <Select
              allowClear
              name="status"
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(statuses, (item) => {
                return (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="user" label="User">
            <Select
              allowClear
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(users, (item) => {
                return (
                  <Option key={item.id} value={item.username}>
                    {item.username + " - " + item.fullname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Row justify="space-between">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>

            <div className={styles.btn_container}>
              <Button
                type="default"
                // htmlType="submit"
                className="ant-btn-secondary"
                onClick={() => exportReport()}
                loading={mode.current === 'download' && loading}
              >
                Export Report
              </Button>

              <Button
                type="default"
                className="ant-btn-secondary"
                style={{ marginLeft: 10 }}
                onClick={() => previewReport()}
                loading={mode.current === 'preview' && loading}
              >
                Preview Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
      <ReportPreviewModal
        file={file}
        visible={openPreviewModal}
        onCancel={() => setOpenPreviewModal(false)}
      />
    </div>
  );
};

export default AllTransactionsReport;
