import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../../store/ui";
import { findAllDashboardUsers } from "../../../store/app";
import { findAllCommunicationChannels } from "../../../store/communication-channel";
import TableHeader from "../../../components/table-header";
import { retrieveBanks, setSelectedBank } from "../../../store/bank";

const CommunicationChannel = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);

  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [filter, setFilter] = useState(null);

  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(resetTablePagination());
  //   fetchData();
  // }, [filter]);

  const editOnClick = (item) => {
    navigate(`/settings/communication-channel/${item.id}`);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data: communicationChannel } = await dispatch(
        findAllCommunicationChannels()
      ).unwrap();

      const data = communicationChannel.data;

      setTableData(data);
      setTableTotalData(communicationChannel.total);

      // dispatch(resetTableSearch());
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());

    fetchData();
  }, []);

  const columns = [
    {
      title: "Channel Name",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: 50,
      // sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "left",
      fixed: "left",
      width: 60,
      // sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "left",
      fixed: "left",
      width: 30,
    },
    {
      title: "URL Link",
      dataIndex: "url",
      align: "left",
      fixed: "left",
      width: 60,
      render: (_, record) => {
        return <div>{record?.url ? record.url : "-"}</div>;
      },
      // sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      fixed: "left",
      width: 60,
      render: (_, record) => {
        return <div>{record?.email ? record.email : "-"}</div>;
      },
      // sorter: true,
    },
    {
      title: "Status",
      dataIndex: "is_enabled",
      align: "center",
      width: 50,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        if (record.is_enabled === 1) {
          status = "Active";
          color = "#95de64";
        } else {
          status = "Inactive";
          color = "#FF0000";
        }
        return (
          <div
            align={"middle"}
            style={{
              color,
              alignItems: "center",
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 20,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Communication Channels"
        // Temporary Hide
        showCreateButton={true}
        // showCreateButton={false}
        createButtonText={"Create New Channel"}
        createOnClick={() => navigate("/settings/communication-channel/add")}
      ></TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default CommunicationChannel;
