import React, { useEffect, useState, useCallback } from "react";
import { Col, Input, Table, message, DatePicker, Select, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { map } from "lodash";
import numeral from "numeral";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findAllMembersWalletBalance } from "@store/user";
import TableHeader from "@components/table-header";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@configs/can";

const Customer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const currencies = useSelector((state) => state.currency.currencies);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // if (filter != null) {
      // filter["localTimeOffset"] = localTimeOffset;
      // }
      // const { data } = await dispatch(findAllCustomers({ filter })).unwrap();
      const { data } = await dispatch(
        findAllMembersWalletBalance({ filter })
      ).unwrap();
      console.log("data");
      console.log(data);
      const { data: userData, total } = data;

      setTableData(userData);
      setTableTotalData(total);
      console.log(userData);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const columns = [
    {
      title: "Topkash ID",
      dataIndex: "topkash_id",
      align: "left",
      fixed: "left",
      width: 100
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      width: 160,
      render: (_, record) => {
        const { username, is_testing_user } = record;

        if (is_testing_user === 1) {
          return (
            <div>{`${username} (TESTING)`}</div>
          );
        }
        else {
          return (
            <div>{username}</div>
          );
        }
      }
    },

    {
      title: "Mobile",
      dataIndex: "phone_no",
      align: "left",
      width: 140
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      width: 200
    },

    {
      title: "Currency",
      dataIndex: "currency",
      align: "right",
      width: 150,
      render: (_, record) => {
        const { currency } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>
              {currency}
            </div>
          </>
        );
      }
    },

    {
      title: "Balance",
      dataIndex: "current_balance",
      align: "right",
      width: 150,
      render: (_, record) => {
        const { current_balance } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(current_balance)).format("0,0.00")}
            </div>
          </>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Wallet Balance"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        createOnClick={() => navigate("/customer/add")}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"topkash_id"} initialValue={null}>
              <Input
                placeholder="Topkash ID"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"username"} initialValue={null}>
              <Input
                placeholder="Username"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* <Col span="6">
            <Form.Item name={"fullname"} initialValue={null}>
              <Input
                placeholder="Full Name"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col> */}
          <Col span="6">
            <Form.Item name={"phone_no"} initialValue={null}>
              <Input
                placeholder="Mobile"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item
              name={"currency"}
              initialValue={[]}
              className={styles.multiple_select}
            >
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Currency"
                options={
                  currencies && currencies.length > 0
                    ? map(currencies, (item) => {
                      return {
                        value: item.iso_code,
                        label: item.iso_code
                      };
                    })
                    : null
                }
                showArrow
              ></Select>
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Customer;
