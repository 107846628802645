import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";
import { isEmpty } from "lodash";

const initialState = {};

export const createMoneyChanger = createAsyncThunk(
  "money-changer/createMoneyChanger",
  async (values) => {
    const {
      address_1,
      address_2,
      city,
      country,
      currency_available,
      email,
      emergency_phone,
      emergency_pic,
      name,
      operations,
      operation_days,
      operation_hours,
      longitude,
      latitude,
      waze_map,
      google_map,
      phone,
      pic,
      postcode,
      state,
      is_active,
      assign_users,
      media_id,
    } = values;
    // console.log(operations);
    try {
      let formData = new FormData();
      formData.append("address_1", address_1);
      formData.append("address_2", address_2);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("currency_available", currency_available);
      formData.append("emergency_phone", emergency_phone);
      formData.append("emergency_pic", emergency_pic);
      formData.append("name", name.trim());
      formData.append("media_id", media_id);
      for (var i = 0; i < operations.length; i++) {
        formData.append("operations[]", JSON.stringify(operations[i]));
      }

      if (longitude) {
        formData.append("longitude", longitude);
      }

      if (latitude) {
        formData.append("latitude", latitude);
      }

      if (waze_map) {
        formData.append("waze_map", waze_map);
      }

      if (google_map) {
        formData.append("google_map", google_map);
      }

      // formData.append("operations", operations);
      // formData.append("operation_days", operation_days);
      // formData.append("operation_hours", operation_hours);
      formData.append("phone", phone);
      formData.append("pic", pic);
      formData.append("postcode", postcode);
      formData.append("state", state);
      formData.append("email", email);
      formData.append("is_active", is_active);
      // if (assign_users != null) {
      formData.append("assign_users", assign_users);
      // }

      const { data } = await axios.post("money-changer/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const retrieveMoneyChanger = createAsyncThunk(
  "money-changer/retrieveMoneyChanger",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "money-changer";

      if (paginate) {
        url = queryBuilder("money-changer", getState().ui.table, filter);
      }
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findMoneyChangerById = createAsyncThunk(
  "money-changer/findMoneyChangerById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`money-changer/${id}`);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const updateMoneyChangerById = createAsyncThunk(
  "money-changer/updateMoneyChangerrById",
  async ({ id, values }) => {
    try {
      const { data } = await axios.patch(`money-changer/${id}`, values);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const moneyChangerSlice = createSlice({
  name: "money-changer",
  initialState,
  reducers: {
    resetExchange: () => {
      return {
        ...initialState,
      };
    },
  },
});

export default moneyChangerSlice.reducer;
