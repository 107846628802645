const errorHandler = {
  generateErrorString: (error) => {
    if (error.response) {
      console.log(error.response);
      if (error.response.data) {
        if (error.response.data.message) {
          return error.response.data.message;
        }

        return error.toString();
      }

      return error.toString();
    }

    return error.toString();
  },
};

export default errorHandler;
