import React, { useEffect, useState } from "react";
import {
  Table,
  DatePicker,
  message,
  Input,
  Form,
  Select,
  Col,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { useNavigate, useLocation } from "react-router-dom";
import { AbilityContext, Can } from "@configs/can";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTablePagination,
  resetTableSort,
  resetTableSearch,
  resetTableFilter,
} from "@store/ui";
import TableHeader from "@components/table-header";
import {
  findAllActivityLogs,
  findAllDashboardUsers,
  findActivityLogSections,
} from "@store/app";
import { findAllUsers } from "@store/user";
import { split } from "lodash";
import { map, replace, remove } from "lodash";
import constant from "@configs/constant";
import { useAbility } from "@casl/react";
const { Option } = Select;

const ActivityLog = () => {
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);

  const location = useLocation();
  const { contentHeight, table } = useSelector((state) => state.ui);
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [filter, setFilter] = useState(null);
  const { RangePicker } = DatePicker;
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [activityLogSection, setActivityLogSection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { Link } = Typography;

  const initialize = async () => {
    // setIsLoading(true);
    try {
      // Retrieve admins
      const { data } = await dispatch(
        findAllUsers({ paginate: false })
      ).unwrap();

      const dashboardUser = map(data, (item) => {
        return {
          value: item.id,
          label: item.username,
        };
      });
      setDashboardUsers(dashboardUser);

      // Retrieve activity log sections
      const activityLogSectionPayload = await dispatch(
        findActivityLogSections({ paginate: false })
      ).unwrap();
      const { data: sectionData } = activityLogSectionPayload;
      // const filteredSectionData = sectionData.filter(
      //   (item) => item.value !== "users"
      // );
      setActivityLogSection(sectionData);
    } catch (error) {
      message.error(error.message);
    }
  };

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());

    initialize();
    fetchData();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data: activityLogsData } = await dispatch(
        findAllActivityLogs({
          filter,
        })
      ).unwrap();

      setTableData(activityLogsData.data);
      setTableTotalData(activityLogsData.total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const userTypeOptions = [
    {
      value: "users",
      label: "Staff",
    },
    {
      value: "app_users",
      label: "Member",
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "effective_date",
      key: "effective_date",
      align: "left",
      width: 200,
      render: (_, record) => {
        return (
          <div>
            {moment
              .utc(record.created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      },
    },
    ability.can("filter", "User")
      ? {
          title: "Username",
          dataIndex: "username",
          key: "username",
          align: "left",
          width: 200,
          render: (_, record) => {
            return <div>{record.user?.username}</div>;
          },
        }
      : {
          width: 0,
        },

    ability.can("filter", "User")
      ? {
          title: "User Type",
          dataIndex: "role_id",
          key: "role_id",
          align: "left",
          width: 200,

          render: (_, record) => {
            if (
              record.user?.role_id == 1 ||
              record.user?.role_id == 3 ||
              record.user?.role_id == 4 ||
              record.user?.role_id == 5
            ) {
              return <div>Staff</div>;
            } else {
              return <div>Member</div>;
            }
          },
        }
      : {
          width: 0,
        },

    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      align: "left",
      width: 200,
      render: (_, record) => {
        const { section } = record;
        let type;

        if (section === "Super Admins") {
          type = "Users";
        } else if (section === "Customer Service Users") {
          type = "Users";
        } else if (section === "Payment Officer") {
          type = "Users";
        } else {
          type = section;
        }
        return <div>{type}</div>;
      },
    },

    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "left",
      width: 200,
      render: (_, record) => {
        const { reference_id, reference_table, section, doc_id } = record;

        let pathString = "/";

        if (reference_table === "users") {
          if (section === "Members") {
            pathString = replace(
              constant.activityLogPath["members"],
              ":id",
              reference_id
            );
          } else if (section === "Customer Service Users") {
            pathString = replace(
              constant.activityLogPath["users"],
              ":id",
              reference_id
            );
          } else if (section === "Super Admins") {
            pathString = replace(
              constant.activityLogPath["users"],
              ":id",
              reference_id
            );
          } else if (section === "Payment Officer") {
            pathString = replace(
              constant.activityLogPath["users"],
              ":id",
              reference_id
            );
          }
        } else if (reference_table.match(/transfers/)) {
          let type = doc_id.match(/[A-Z]+/)[0];

          pathString = replace(
            constant.activityLogPath[reference_table][type],
            ":id",
            reference_id
          );
        } else if (reference_table.match(/reloads/)) {
          let type = doc_id.match(/[A-Z]+/)[0];

          pathString = replace(
            constant.activityLogPath[reference_table][type],
            ":id",
            reference_id
          );
        } else {
          pathString = replace(
            constant.activityLogPath[reference_table],
            ":id",
            reference_id
          );
        }

        return (
          <div>
            <Link
              // href={pathString}
              onClick={() => window.open(pathString, "_blank")}
              className="text-blue ant-btn-link"
            >
              {reference_table.match(/transfers|reloads|redeems/)
                ? doc_id
                : reference_id}
            </Link>
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 200,
      render: (_, record) => {
        return <div>{record.action_type}</div>;
      },
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      width: 350,
      render: (_, record) => {
        const { description } = record;
        const descriptionArr = split(description, ";");

        return (
          <>
            {map(descriptionArr, (item) => {
              return <div>{item}</div>;
            })}
          </>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Activity Log"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
      >
        <>
          <Col>
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Can I="filter" a="User">
            <Col span="6">
              <Form.Item
                name={"created_by"}
                initialValue={[]}
                className={styles.multiple_select}
              >
                <Select
                  name="select-multiple"
                  mode="multiple"
                  allowClear
                  className="table-search-select"
                  placeholder="Username"
                  options={
                    dashboardUsers && dashboardUsers.length > 0
                      ? dashboardUsers
                      : null
                  }
                  showArrow
                  filterOption={(input, option) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  filterSort={(optionA, optionB) =>
                    optionA.label
                      .toLowerCase()
                      .localeCompare(optionB.label.toLowerCase())
                  }
                >
                  {/* {map(dashboardUsers, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.username}
                    </Option>
                  );
                })} */}
                </Select>
              </Form.Item>
            </Col>
          </Can>

          <Can I="filter" a="User">
            <Col span="6">
              <Form.Item
                name={"user_type"}
                initialValue={[]}
                className={styles.multiple_select}
              >
                <Select
                  allowClear
                  className="table-search-select"
                  placeholder="User Type"
                  options={userTypeOptions}
                  showArrow
                  filterOption={(input, option) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  filterSort={(optionA, optionB) =>
                    optionA.label
                      .toLowerCase()
                      .localeCompare(optionB.label.toLowerCase())
                  }
                >
                  {map(userTypeOptions, (item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.username}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Can>

          <Col span="6">
            <Form.Item name={"section"}>
              <Select
                className="search-select"
                placeholder="Section"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(activityLogSection, (item) => {
                  return (
                    <Option key={item.label} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"description"} initialValue={null}>
              <Input
                placeholder="Description"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>

      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default ActivityLog;
