import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Row, Button, message, Space, Divider } from "antd";

import styles from "./styles.module.less";
import { getAllRoles, findUserProfile } from "@store/app";

const Profile = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    dispatch(getAllRoles());
    const fetchData = async () => {
      try {
        // Retrieve dashbord user
        const userPayload = await dispatch(findUserProfile()).unwrap();
        const { data: userData } = userPayload;

        if (userData) {
          formRef.current.setFieldsValue({
            role: userData.role,
            role_id: userData.role_id,
            username: userData.username,
            fullname: userData.fullname,
            email: userData.email,
            phone_no: userData.phone_no,
            status: userData.status,
          });

          setUserDetail(userData);
        }
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  console.log(userDetail);

  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>My Profile</div>
        </Row>
        <Form
          className="form-details"
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="status"
            label="Status"
           
          >
            {userDetail.status === 1 ? "Active" : "Inactive"}
          </Form.Item>

          <Form.Item
            name="id"
            label="User ID"
         
          >
            <div>{userDetail.id}</div>
          </Form.Item>

          <Form.Item
            name="role"
            label="User Type"
        
          >
            <div>{userDetail.role?.name}</div>
          </Form.Item>

          <Divider className="divider" />

          <Form.Item
         
            name="username"
            label="Username"
          >
            <div>{userDetail.username}</div>
          </Form.Item>

          <Form.Item

            name="fullname"
            label="Full Name"
          >
            <div>{userDetail.fullname}</div>
          </Form.Item>
          <Form.Item
            name="email"
   
            label="Email"
          >
            <div>{userDetail.email}</div>
          </Form.Item>

          <Form.Item
  
            name="phone_no"
            label="Mobile"
          >
            <div>{userDetail.phone_no}</div>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Button onClick={() => navigate("/")} className="default_btn">
                  Close
                </Button>
              </div>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Profile;
