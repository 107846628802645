import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  Button,
  Menu,
  Dropdown,
  message,
  Space,
  Tooltip,
} from "antd";
import { SearchOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { getDashboardUsers } from "../../store/app";

const DashboardUser = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  const editOnClick = (item) => {
    navigate(`/dashboard-user/${item.id}`);
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatch(getDashboardUsers()).unwrap();
      const { data: userData, total } = data;

      setTableData(userData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(
          setTableSearch({
            search: text,
          })
        );
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      align: "center",
      width: "12%",
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      align: "center",
      width: "18%",
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      width: "12%",
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      width: "18%",
    },
    {
      title: "Phone No",
      dataIndex: "phone_no",
      align: "center",
      width: "12%",
      render: (_, record) => {
        if (record.phone_no) {
          return <div>{record.phone_no}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "center",
      width: "12%",
      sorter: true,
      render: (_, record) => {
        if (record.country_name) {
          return <div>{record.country_name}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "left",
      width: "15%",
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment(created_at.created_at).format("YYYY-MM-DD, hh:mm:ss A")}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
      width: "10%",
      render: (_, record) => {
        return (
          <div>
            <Space size="middle">
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#4361ee" }}
                  onClick={() => editOnClick(record)}
                />
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row align="middle" justify="space-between">
          <div className="table_search_input">
            <Input
              placeholder="Search..."
              suffix={<SearchOutlined className={styles.search_icon} />}
              allowClear
              onChange={(e) => {
                setText(e.target.value);
                onChangeText(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              onClick={() => navigate("/dashboard-user/add")}
              className="secondary_btn"
              icon={<PlusOutlined />}
            >
              Create
            </Button>
          </div>
        </Row>
      </div>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
        }}
      />
    </div>
  );
};

export default DashboardUser;
