import React, { useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Select,
  Button,
  Form,
  Col,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../../store/ui";
import TableHeader from "../../../components/table-header";
import {
  retrieveCryptoAccounts,
  retrieveCryptoNetworks,
  retrieveCryptoProviders,
} from "../../../store/crypto";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CryptoAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [provider, setProvider] = useState([]);
  const [network, setNetwork] = useState([]);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const statusOption = [
    {
      value: "1",
      label: "Active",
    },
    {
      value: "2",
      label: "Inactive",
    },
  ];

  useEffect(() => {
    dispatch(resetTableFilter());
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Retrieve accounts
      const { data } = await dispatch(
        retrieveCryptoAccounts({ filter })
      ).unwrap();
      const { data: accountData, total } = data;

      // Retrieve providers
      const provider = await dispatch(retrieveCryptoProviders()).unwrap();
      const { data: providersData } = provider;
      setProvider(providersData);

      // Retrieve networks
      const network = await dispatch(retrieveCryptoNetworks()).unwrap();
      const { data: networksData } = network;
      setNetwork(networksData);

      setTableData(accountData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const editOnClick = async (item) => {
    navigate(`/settings/crypto-account/${item.id}`);
  };

  const columns = [
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} left`}>
            <div>USDT Acc ID</div>
          </span>
        );
      },
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 50,
      key: "id",
      render: (_, record) => {
        const { id } = record;
        return <div>{id ? id : "-"}</div>;
      },
    },
    {
      title: "Provider",
      dataIndex: "crypto_provider_id",
      align: "left",
      key: "crypto_provider_id",
      width: 80,
      render: (_, record) => {
        const { provider } = record;
        return <div style={{ textAlign: "left" }}>{provider.name}</div>;
      },
    },

    {
      title: "Network",
      // dataIndex: "crypto_network_id",
      align: "left",
      key: "crypto_network_id",
      width: 80,
      render: (_, record) => {
        const { network } = record;
        return <div style={{ textAlign: "left" }}>{network.name}</div>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      align: "left",
      width: 120,
      key: "address",
      render: (_, record) => {
        const { address } = record;
        return <div>{address ? address : "-"}</div>;
      },
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 120,
      sorter: true,
      key: "created_at",
      render: (_, record) => {
        return <div>{moment(record.created_at).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      fixed: "right",
      width: 90,
      key: "status",
      render: (_, record) => {
        let status;
        let color;

        if (record.status === 1) {
          status = "Active";
          color = "#95de64";
        } else if (record.status === 2) {
          status = "Inactive";
          color = "#FF0000";
        }

        return <div style={{ color }}>{status}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 90,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="USDT Accounts"
        showCreateButton={true}
        showResetButton={true}
        showFilterButton={true}
        createButtonText="Create New USDT Crypto Account"
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
        }}
        createOnClick={() => navigate("/settings/crypto-account/add")}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="USDT Acc ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"provider"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Provider"
                    options={
                      provider && provider.length > 0
                        ? map(provider, (item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"network"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Network"
                    options={
                      network && network.length > 0
                        ? map(network, (item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  ></Select>
                </Form.Item>
              </Col>
             
              <Col span="8">
                <Form.Item name={"acc_no"} initialValue={null}>
                  <Input
                    placeholder="Address"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"status"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Status"
                    showArrow
                  >
                    {map(statusOption, (item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        onChange={onChange}
        loading={isLoading}
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        dataSource={tableData}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default CryptoAccounts;
