import React, { useEffect, useState } from "react";
import { Table, Dropdown, Menu, Row, Button, Empty, message } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import styles from "./styles.module.less";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { map } from "lodash";

import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { retrieveBanksByCountry } from "../../store/bank";

const BanksByCountry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await dispatch(retrieveBanksByCountry()).unwrap();

      // setTableTotalData(bankCountryData.data.bank_country_id);

      // setTableData(
      //   map(bankCountryData.data, (item) => {
      //     return {
      //       ...item,
      //       key: item.bank_country_id,
      //     };
      //   })
      // );

      setTableData(
        map(data, (item) => {
          return {
            ...item,
            key: item.id,
          };
        })
      );
    } catch (error) {
      message.error(error.message);
    }
  };

  const parentMenuOnClick = async ({ key }, item) => {
    if (key === "add") {
      navigate(`/banks-by-country-add-branch/${item.id}`);
      // navigate(`/banks-by-country-add/${item.bank_country_id}`, {
      //   state: {
      //     bank_account_country_id: item.bank_account_country_id,
      //     country_currency_id: item.country_currency_id,
      //   },
      // });
    }

    // if (key === "edit") {
    //   navigate(`/banks-by-country-edit-branch/${item.bank_country_id}`, {
    //     state: {
    //       bank_id: item.bank_country_id,
    //       bank_name: item.bank_name,
    //       branch_name: item.branch_name,
    //       country_id: item.country_id,
    //     },
    //   });
    // }
  };

  const childMenuOnClick = async ({ key }, item) => {
    if (key === "edit") {
      navigate(`/banks-by-country-edit/${item.id}`, {
        state: {
          bank_country_id: item.country_id,
          bank_id: item.bank_id,
          branch_name: item.branch_name,
        },
      });
    }
  };

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let key = sorter.columnKey;
      let field = sorter.field;

      if (key == "id") {
        field = "bank_country_id";
      }

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  const expandedRowRender = (record) => {
    let banks = record.bank_countries;
    const columns = [
      // {
      //   title: <div style={{ fontWeight: 700 }}>Bank ID</div>,
      //   dataIndex: "id",
      //   key: "id",
      //   align: "center",
      // },
      {
        title: <div style={{ fontWeight: 700 }}>Branch Name</div>,
        dataIndex: "branch_name",
        key: "branch_name",
        align: "center",
      },
      {
        title: <div style={{ fontWeight: 700 }}>Action</div>,
        dataIndex: "action",
        align: "center",
        width: "10%",
        key: "action",
        render: (_, record) => {
          const actions = [
            {
              label: "Edit",
              key: "edit",
            },
          ];

          return (
            <div>
              {record.id && record.branch_name && (
                <Dropdown
                  overlay={
                    <Menu
                      selectable
                      onClick={(event) => childMenuOnClick(event, record)}
                      items={actions}
                    />
                  }
                  arrow={true}
                  destroyPopupOnHide={true}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <EllipsisOutlined />
                  </a>
                </Dropdown>
              )}
            </div>
          );
        },
      },
    ];
    // if (record.bank_countries.length > 0) {
    return <Table columns={columns} dataSource={banks} pagination={false} />;
    // } else {
    //   return null;
    // }
  };

  const rowExpandable = (record) => {
    if (record.bank_countries.length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      align: "center",
      key: "id",
      sorter: true,
      render: (_, record) => {
        return (
          <div style={{ color: "blue", fontWeight: "700" }}>{record.id}</div>
        );
      },
    },

    {
      title: "Country Name",
      dataIndex: "name",
      align: "left",
      width: "30%",
      key: "name",
      sorter: true,
      // render: (_, record) => {
      //   return <div>{record.name}</div>;
      // },
    },
    {
      title: "Country Code",
      dataIndex: "iso_code_3",
      align: "center",
      width: "30%",
      key: "iso_code_3",
      sorter: true,
      // render: (_, record) => {
      //   return <div>{record.country_code}</div>;
      // },
    },
    // {
    //   title: "Bank Name",
    //   dataIndex: "bank_name",
    //   align: "center",
    //   width: "30%",
    //   key: "bank_name",
    //   sorter: true,
    //   render: (_, record) => {
    //     return <div>{record.bank_name}</div>;
    //   },
    // },
    // {
    //   title: "Branch Name",
    //   dataIndex: "branch_name",
    //   align: "center",
    //   width: "30%",
    //   key: "branch_name",
    //   sorter: true,
    //   render: (_, record) => {
    //     return <div>{record.branch_name}</div>;
    //   },
    // },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: "15%",
      key: "action",
      render: (_, record) => {
        const actions = [
          {
            label: "Add New Accounts",
            key: "add",
          },
          // {
          //   label: "Edit Branch",
          //   key: "edit",
          // },
        ];

        return (
          <div>
            <Dropdown
              overlay={
                <Menu
                  selectable
                  onClick={(event) => parentMenuOnClick(event, record)}
                  items={actions}
                />
              }
              arrow={true}
              destroyPopupOnHide={true}
            >
              <a onClick={(e) => e.preventDefault()}>
                <EllipsisOutlined />
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <div style={{ marginBottom: 40 }}>
          <Row justify="end">
            <div className={styles.secondary_btn}>
              <Button
                className="secondary_btn"
                onClick={() => navigate("/banks-by-country-add-branch")}
              >
                + New Bank Branch
              </Button>
            </div>
          </Row>
        </div>

        <Table
          className={styles.pagination_item}
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
          size="small"
          scroll={{
            y: contentHeight - 90 - 40 - 56 - 70,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable,
            defaultExpandedRowKeys: [],
          }}
          pagination={{
            total: tableTotalData,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
          }}
        />
      </div>
    </>
  );
};

export default BanksByCountry;
