import React, { useEffect, useState, useCallback } from "react";
import { Row, Input, Table, message, Space, Tag, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "../../store/ui";
import { findAllTransactions } from "../../store/transaction";
const { CheckableTag } = Tag;

const Transaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState([]);

  const currencies = useSelector((state) => [...state.currency.currencies]);

  const statusOptions = [
    {
      key: 3,
      value: "Successful",
      label: "Successful"
    },
    {
      key: 5,
      value: "Unsuccessful",
      label: "Unsuccessful"
    }
  ];

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedTags]);
  useEffect(() => {
    fetchData();
  }, [selectedStatus]);
  useEffect(() => {
    fetchData();
  }, [selectedTransaction]);

  const fetchData = async () => {
    try {
      let transaction = await dispatch(
        findAllTransactions({
          currencies: selectedTags,
          status: selectedStatus,
          type: "reload"
        })
      ).unwrap();

      let { data, total } = transaction.data;

      setTableData(data);
      setTableTotalData(total);
      // dispatch(resetTableSearch());
      // dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(setTableSearch({ search: text }));
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const handleChange = (tag, checked) => {
    dispatch(resetTablePagination());
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "left",
      key: "created_at",
      render: (_, record) => {
        return (
          <div>{moment(record.created_at).format("YYYY-MM-DD hh:mm A")}</div>
        );
      }
    },
    {
      title: "Reference Username",
      dataIndex: "reference_username",
      align: "center",
      key: "reference_username",
      render: (_, record) => {
        if (record.reference_username) {
          return <div>{record.reference_username}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type"
    },
    {
      title: "From Currency",
      dataIndex: "from_currency_code",
      align: "center",
      key: "from_currency_code"
    },
    {
      title: "To Currency",
      dataIndex: "to_currency_code",
      align: "center",
      key: "to_currency_code"
    },
    {
      title: "Balance (Before)",
      dataIndex: "old_balance",
      align: "center",
      key: "old_balance",
      render: (_, record) => {
        console.log(record);
        return (
          <div style={{ textAlign: "right" }}>
            {(Math.round(record.old_balance * 100) / 100).toFixed(2)}
          </div>
        );
      }
    },
    {
      title: "Balance (After)",
      dataIndex: "new_balance",
      align: "center",
      key: "new_balance",
      render: (_, record) => {
        return (
          <div style={{ textAlign: "right" }}>
            {(Math.round(record.new_balance * 100) / 100).toFixed(2)}
          </div>
        );
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (_, record) => {
        return (
          <div style={{ textAlign: "right" }}>
            {(Math.round(record.amount * 100) / 100).toFixed(2)}
          </div>
        );
      }
    },

    {
      title: "Bank",
      dataIndex: "reload_bank",
      align: "center",
      key: "reload_bank",
      render: (_, record) => {
        if (record.transfer_bank) {
          return <div>{record.transfer_bank}</div>;
        } else if (record.reload_bank) {
          return <div>{record.reload_bank}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Recipient's Account",
      dataIndex: "user_bank_account_name",
      align: "center",
      key: "user_bank_account_name",
      render: (_, record) => {
        if (record.user_bank_account_name) {
          return <div>{record.user_bank_account_name}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Account's no",
      dataIndex: "acc_no",
      align: "center",
      key: "acc_no",
      render: (_, record) => {
        if (record.acc_no) {
          return <div>{record.acc_no}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      width: "15%",
      key: "status",
      render: (_, record) => {
        let status;
        let color;
        if (record.status === "3") {
          status = "Successful";
          color = "#339933";
        } else if (record.status === "5") {
          status = "Unsuccessful";
          color = "#FF0000";
        }
        return <div style={{ color }}>{status}</div>;
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };
  const handleStatusChange = (value) => {
    dispatch(resetTablePagination());
    let status = value.map((item) => {
      return item.key;
    });
    setSelectedStatus(status);
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row justify="space-between" align="middle">
          <Space>
            <div className="table_search_input">
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
                onChange={(e) => {
                  onChangeText(e.target.value);
                }}
              />
            </div>
            <Select
              mode="multiple"
              allowClear
              className="search-select"
              labelInValue
              placeholder="Status"
              defaultValue={[]}
              onChange={handleStatusChange}
              options={statusOptions}
              showArrow
            />
          </Space>
        </Row>
        <div style={{ marginTop: 20 }}>
          <span
            style={{
              marginRight: 8
            }}
          >
            Currencies:
          </span>
          <Space size={[0, 8]} wrap>
            {currencies.map((tag) => (
              <CheckableTag
                key={tag.id}
                checked={selectedTags.includes(tag.id)}
                onChange={(checked) => handleChange(tag.id, checked)}
              >
                {tag.currency_iso}
              </CheckableTag>
            ))}
          </Space>
        </div>
      </div>

      <Table
        className={styles.pagination_item}
        columns={columns}
        onChange={onChange}
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        dataSource={tableData}
        size="middle"
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1
        }}
      />
    </div>
  );
};

export default Transaction;
