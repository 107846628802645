import React from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Form,
  InputNumber,
  Row,
  DatePicker,
  Button,
  message,
  Space,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { map } from "lodash";
import { useSelector } from "react-redux";

// ============
import styles from "./styles.module.less";
import { createExchangeRate } from "@store/exchange";
import moment from "moment";

const { Option } = Select;

const ExchangeRateAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currencies = useSelector((state) => state.currency.currencies);

  const onFinish = async (values) => {
    values = {
      ...values,
      date: values.date.format("YYYY-MM-DD"),
    };

    try {
      const { data } = await dispatch(createExchangeRate(values)).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>ADD EXCHANGE RATE</div>
        </Row>

        <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
          <Form.Item
            name="from_currency"
            label="From Currency"
            rules={[
              {
                required: true,
                message: "Please enter source currency",
              },
            ]}
          >
            <Select
              className={styles.select_container}
              placeholder="Please select a currency"
              bordered={false}
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.currency_iso}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="to_currency"
            label="To Currency"
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Please enter target currency",
              },
            ]}
          >
            <Select
              className={styles.select_container}
              placeholder="Please select a currency"
              bordered={false}
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.currency_iso}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="rate"
            label="Exchange Rate"
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Please enter exchange rate",
              },
              {
                type: "number",
                message: "Rate must be a floating point number",
              },
            ]}
          >
            <InputNumber
              className={styles.input}
              min="0"
              placeholder="Enter exchange rate"
            />
          </Form.Item>

          <Form.Item
            name="date"
            label="Effective Date"
            rules={[
              {
                required: true,
                message: "Please choose effective date",
              },
            ]}
          >
            <DatePicker
              disabledDate={(date) => moment().add(-1, "days") >= date}
              className={styles.date_picker}
            />
          </Form.Item>

          <Row justify="end">
            <Space>
              <div className={styles.button}>
                <Button onClick={() => navigate(-1)} className="default_btn">
                  Cancel
                </Button>
              </div>
              <div className={styles.button}>
                <Button htmlType="submit" className="secondary_btn">
                  Create
                </Button>
              </div>
            </Space>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ExchangeRateAdd;
