import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
import { find, map, isEmpty } from "lodash";

// ============
import styles from "./styles.module.less";
import { getAllCountries, countryWithCurrencies } from "../../store/country";
import {
  getAllBanks,
  createBankAccount,
  retrieveBanksByCountry,
} from "../../store/bank";
import { retrieveAllCurrencies } from "../../store/currency";

const { Option } = Select;

const BankAccountsAddAccount = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [branch, setBranch] = useState([]);
  const [bank, setBank] = useState([]);
  const [currency, setCurrency] = useState([]);

  const location = useLocation();

  const formRef = useRef();

  const [selectedCountry, setSelectedCountry] = useState({
    id: null,
    name: "",
    iso_code: "",
  });

  const [selectedCurrency, setSelectedCurrency] = useState({
    id: null,
    name: "",
    iso_code_2: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve banks
        const bankPayload = await dispatch(getAllBanks()).unwrap();
        const { data: bankData } = bankPayload;

        setBank(bankData);

        const bankCountry = await dispatch(retrieveBanksByCountry()).unwrap();

        const { data: bankAccountData } = bankCountry;
        const bankBranch = find(bankAccountData, { id: parseInt(params.id) });

        setBranch(bankBranch);

        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;
        setCurrency(currencyData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isEmpty(params)) {
      countriesOnChange(parseInt(params.id));
    }
  }, [countries]);

  useEffect(() => {
    formRef.current.setFieldsValue({
      country: selectedCountry.id,
    });
  }, [selectedCountry]);

  useEffect(() => {
    formRef.current.setFieldsValue({
      bank: selectedCurrency.bank_id,
    });
  }, [selectedCurrency]);

  const countriesOnChange = (value) => {
    const selectCountry = find(countries, (item) => item.id === value);
    if (selectCountry) {
      setSelectedCountry({
        id: selectCountry.id,
        name: selectCountry.name,
        iso_code: selectCountry.iso_code_2,
      });
    }
  };

  const branchOnChange = (value) => {
    const selectBranch = find(
      branch.bank_countries,
      (item) => item.id === value
    );
    if (selectBranch) {
      setSelectedCurrency({
        id: selectBranch.id,
        branch_name: selectBranch.branch_name,
        bank_id: selectBranch.bank_id,
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (values) {
        await dispatch(
          createBankAccount({
            bank_id: values.bank_id,
            acc_no: parseInt(values.account_no),
            acc_name: values.account_name,
            country_id: values.country,
            currency_id: values.currency_id,
          })
        ).unwrap();

        message.success("You have successfully added new bank account");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const currencyOnChange = async (id) => {
    // Retrieve countries

    const countriesPayload = await dispatch(
      countryWithCurrencies({ currencyId: id })
    ).unwrap();
    const { data: countryData } = countriesPayload;

    setCountries(countryData);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>ADD NEW BANK ACCOUNT</div>
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <div className={styles.select_container}>
            <Form.Item
              name="bank_id"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Bank name cannot be empty",
                },
              ]}
            >
              <Select name="bank" Input placeholder="Select Bank">
                {map(bank, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          {/* <div className={styles.select_container}>
            <Form.Item
              name="branch_id"
              value={selectedCurrency}
              label={<div className={styles.label}>Branch Name</div>}
              rules={[
                {
                  required: true,
                  message: "Branch name cannot be empty",
                },
              ]}
            >
              <Select
                name="branch"
                value={selectedCurrency}
                placeholder="Branch Name"
                onChange={branchOnChange}
              >
                {map(branch.bank_countries, (item) => {
                  return (
                    <Option
                      key={item.id}
                      value={item.id}
                      disabled={item.bank ? false : true}
                    >
                      {item.branch_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div> */}

          <div className={styles.select_container}>
            <Form.Item
              name="currency_id"
              // value={selectedCurrency}
              label={<div className={styles.label}>Currency</div>}
              rules={[
                {
                  required: true,
                  message: "Branch name cannot be empty",
                },
              ]}
            >
              <Select
                name="currency_id"
                // value={selectedCurrency}
                placeholder="Select Currency"
                onChange={currencyOnChange}
              >
                {map(currency, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.currency_iso}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.select_container}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty",
                },
              ]}
            >
              <Select
                name="country"
                value={selectedCountry}
                placeholder="Select Country"
                onChange={countriesOnChange}
                showSearch
                input={params?.id}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(countries, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className={styles.input}>
            <Form.Item
              name="account_no"
              label={<div className={styles.label}>Account No.</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the account number",
                },
              ]}
            >
              <Input placeholder="Account No." />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="account_name"
              label={<div className={styles.label}>Account Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the account name",
                },
              ]}
            >
              <Input placeholder="Account Name" />
            </Form.Item>
          </div>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Add
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BankAccountsAddAccount;
