import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  Space,
  Button,
  Modal,
  message,
  Tooltip
} from "antd";
import {
  SearchOutlined,
  EllipsisOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import numeral from "numeral";
import { capitalCase } from "change-case";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "../../../store/ui";
import {
  retrieveCurrencies,
  deleteCurrency,
  findAllCurrencies
} from "../../../store/currency";
import TableHeader from "../../../components/table-header";

const Currency = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnableDelete, setIsEnableDelete] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedDeleteCurrencyId, setSelectedDeleteCurrencyId] =
    useState(null);
  const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  useEffect(() => {
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isEnableDelete]);

  const deleteOnClick = async (item) => {
    setIsConfirmModalVisible(true);
    setSelectedDeleteCurrencyId(item.id);
    setIsEnableDelete(false);
  };

  const editOnClick = (item) => {
    navigate(`/settings/currency/${item.id}`);
  };

  const deleteSelectedCurrency = async () => {
    try {
      await dispatch(
        deleteCurrency({
          id: selectedDeleteCurrencyId
        })
      ).unwrap();

      setIsConfirmModalVisible(false);
      message.success("You have successfully deleted the currency");

      // setTimeout(() => {
      setIsEnableDelete(true);
      // }, 1000);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(retrieveCurrencies()).unwrap();

      const { data: currencyData, total } = data;

      setTableData(currencyData);
      setTableTotalData(total);

      dispatch(resetTableSearch());
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(
          setTableSearch({
            search: text
          })
        );
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const columns = [
    {
      title: "Currency Code",
      dataIndex: "iso_code",
      width: 40,
      align: "right",
      render: (_, record) => {
        return <div align={"right"}>{record.iso_code}</div>;
      }
    },
    {
      title: "Currency Name",
      dataIndex: "currency_name",
      align: "left",
      width: 80,
      render: (_, record) => {
        return <div>{record.currency_name}</div>;
      }
    },

    {
      title: "Type",
      dataIndex: "type",
      align: "left",
      width: 80,
      render: (_, record) => {
        const { type } = record;

        return <div>{capitalCase(type)}</div>;
      }
    },

    {
      title: (
        <>
          <div>Minimum Amount Limit</div>
          <div>Per Transaction</div>
        </>
      ),
      dataIndex: "minimum_amount",
      width: 80,
      align: "right",
      render: (_, record) => {
        return (
          <div>
            {" "}
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(record.minimum_amount)).format("0,0.00")}
            </div>
          </div>
        );
      }
    },

    {
      title: (
        <>
          <div>Maximum Amount Limit</div>
          <div>For Unverified Members</div>
        </>
      ),
      dataIndex: "unverified_maximum_amount",
      width: 80,
      align: "right",
      render: (_, record) => {
        return (
          <div>
            {" "}
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(record?.unverified_maximum_amount)).format(
                "0,0.00"
              )}
            </div>
          </div>
        );
      }
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 80,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div align="left">
            {moment
              .utc(created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 40,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Currencies"
        createButtonText="Create New Currency"
        showCreateButton={true}
        showResetButton={false}
        showFilterButton={false}
        createOnClick={() => navigate("/settings/currency/add")}
      ></TableHeader>

      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center"
        }}
        visible={isConfirmModalVisible}
        closable={false}
        onOk={deleteSelectedCurrency}
        onCancel={() => {
          setIsConfirmModalVisible(false);
        }}
        okText="Confirm"
        cancelButtonProps={{
          style: { display: "inline-block" }
        }}
        okButtonProps={{
          style: { display: "inline-block" }
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 14, marginBottom: 18 }}>
          Do you confirm to delete this currency?
        </div>
      </Modal>
    </div>
  );
};

export default Currency;
