import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Avatar,
  Col,
  Layout,
  Row,
  Button,
  Dropdown,
  Menu,
  Space,
  Popover,
  List,
  Badge,
  Modal,
  Tag,
  Image,
  Divider
} from "antd";
import {
  BellOutlined,
  SearchOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  TransactionOutlined,
  SettingOutlined,
  PayCircleOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  FlagOutlined,
  UnorderedListOutlined,
  BankOutlined,
  AccountBookOutlined,
  GiftOutlined,
  CreditCardOutlined
} from "@ant-design/icons";
import { GrUserSettings } from "react-icons/gr";
import { BsCurrencyExchange } from "react-icons/bs";
import { capitalCase } from "change-case";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { useAbility } from "@casl/react";
// ============
import styles from "./styles.module.less";
import {
  setLanguage,
  logout,
  setIsLoggedIn,
  resetApp
  // setLocalTimeOffset,
} from "@store/app";
import {
  setContentHeight,
  setContentWidth,
  setNotificationCurrentPage,
  resetNotificationPage,
  setSiderMenuSelectedKey,
  resetUi
} from "@store/ui";
import {
  retrieveNotifications,
  markAllAsRead,
  markMessageAsRead,
  resetNotification,
  toggleNotificationModal,
  setModalContent,
  resetModalContent
} from "@store/notification";
import { getAllCountries } from "@store/country";
import { retrieveAllCurrencies } from "@store/currency";
import FullPageSpinner from "../../components/full-page-spinner";
import { resetCurrency } from "@store/currency";
import { resetTransfer } from "@store/transfer";
import { resetBank } from "@store/bank";
import { resetExchange } from "@store/exchange";
import { resetCountry } from "@store/country";
import { resetReload } from "@store/reload";
import { AbilityContext } from "@configs/can";
import {
  resetTransaction,
  retrievePendingTransactions
} from "@store/transaction";
import { retrievePendingKycsCounts } from "@store/user";
import { createMongoAbility } from "@casl/ability";
import {
  chain,
  filter,
  isArray,
  map,
  some,
  size,
  find,
  round,
  isEmpty,
  flatten,
  concat
} from "lodash";
import message from "@configs/message";
import { isNull } from "lodash";

const { Header, Footer, Sider, Content } = Layout;

const CustomLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const nid = searchParams.get("nid");
  const ability = useAbility(AbilityContext);

  const { permissions } = useSelector((state) => state.app.user);
  const contentWidth = useSelector((state) => state.ui.contentWidth);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const currentPage = useSelector((state) => state.ui.notification.current);
  const siderMenuSelectedKey = useSelector(
    (state) => state.ui.siderMenuSelectedKey
  );

  const isLoggedIn = useSelector((state) => state.app.isLoggedIn);
  const notifications = useSelector((state) => state.notification.notification);
  const messages = useSelector(
    (state) => state.notification.notification.message
  );
  const [timerSecond, setTimerSecond] = useState(0);

  const contentRef = useRef(null);
  const notificationList = useRef();

  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [languageFlag, setLanguageFlag] = useState(
    <Avatar
      className={styles.flag_icon_style}
      src={require("../../assets/images/en.png")}
    />
  );
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const reloadCryptoCount = useSelector(
    (state) => state.transaction.pendingCount?.reloadCryptoCount
  );
  const reloadBankCount = useSelector(
    (state) => state.transaction.pendingCount?.reloadBankCount
  );
  const transferCount = useSelector(
    (state) => state.transaction.pendingCount?.transferCount
  );

  const kycCount = useSelector((state) => state.user.pendingCount?.kycCount);

  const [filteredAbility, setFilteredAbility] = useState([]);
  const [isAbleToAccessPage, setIsAbleToAccessPage] = useState(null);

  const pendingCountIntervalRef = useRef(null);

  const user = useSelector((state) => state.app.user);

  const onScroll = () => {
    if (notificationList.current.clientHeight) {
      const { scrollTop, scrollHeight, clientHeight } =
        notificationList.current;
      if (round(scrollTop + clientHeight) === scrollHeight) {
        const totalPage = round(notifications.total / 10);

        if (currentPage <= totalPage) {
          dispatch(setNotificationCurrentPage({ current: currentPage + 1 }));
        }

        try {
          dispatch(retrieveNotifications()).unwrap();
        } catch (error) {
          message.error(error.toString());
        }
      }
    }
  };

  const languages = [
    {
      label: "English",
      key: "1",
      type: "en",
      icon: (
        <Avatar
          className={styles.flag_icon_style}
          src={require("../../assets/images/en.png")}
        />
      )
    },
    {
      label: "Chinese",
      key: "2",
      type: "cn",
      icon: (
        <Avatar
          className={styles.flag_icon_style}
          src={require("../../assets/images/cn.png")}
        />
      )
    },
    {
      label: "Bahasa Malaysia",
      key: "3",
      type: "ms",
      icon: (
        <Avatar
          className={styles.flag_icon_style}
          src={require("../../assets/images/ms.png")}
        />
      )
    },
    {
      label: "Bangladesh",
      key: "4",
      type: "bd",
      icon: (
        <Avatar
          className={styles.flag_icon_style}
          src={require("../../assets/images/bd.png")}
        />
      )
    }
  ];

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      // const ability = createMongoAbility(permissions);
      let permissionChild = [];

      const filterPermissionChild = filter(permissions, (item) => {
        const { child } = item;
        if (child) {
          return child;
        }
      });
      map(filterPermissionChild, (item) => {
        const { child } = item;

        permissionChild = concat(permissionChild, child);
      });

      //concat permission with child and create as a mongo ability
      const concatPermission = concat(permissions, permissionChild);
      const ability = createMongoAbility(concatPermission);

      const filterAbility = chain(items)
        .filter((item) => {
          const { subject } = item;

          if (isArray(subject)) {
            return some(subject, (item) => ability.can("read", item));
          }

          return ability.can("read", item.subject);
        })
        .map((item) => {
          const { children } = item;

          if (size(children)) {
            const filteredChildren = filter(children, (item) => {
              const { subject } = item;

              return ability.can("read", subject);
            });

            return {
              ...item,
              children: filteredChildren
            };
          }

          return item;
        })
        .value();

      setFilteredAbility(filterAbility);
    }
  }, [
    permissions,
    reloadBankCount,
    reloadCryptoCount,
    transferCount,
    kycCount
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (contentRef.current.clientHeight) {
        dispatch(
          setContentHeight({
            contentHeight: contentRef.current.clientHeight
          })
        );
      }

      if (contentRef.current.clientWidth) {
        dispatch(
          setContentWidth({
            contentWidth: contentRef.current.clientWidth
          })
        );
      }
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(resetNotificationPage());
    dispatch(resetNotification());
    dispatch(resetModalContent());

    const fetchData = async () => {
      try {
        await dispatch(retrieveNotifications()).unwrap();
        await dispatch(getAllCountries());
        await dispatch(retrieveAllCurrencies());
        _retrievePendingTransactions();
        _retrievePendingKycsCounts();
      } catch (error) {
        message.error(error.message);
      }
    };

    // dispatch(setLocalTimeOffset(new Date().getTimezoneOffset()));

    fetchData();
  }, []);

  useEffect(() => {
    if (nid && messages.length) {
      const content = find(messages, { id: parseInt(nid) });

      if (content) {
        dispatch(
          setModalContent({
            title: content.title,
            content: content.content
          })
        );
        dispatch(toggleNotificationModal(true));
      }
    }
  }, [nid, messages]);

  useEffect(() => {
    const path = location.pathname;

    if (path == "/") {
      // setBreadcrumbs(["Dashboard"]);
      navigate(`/top-up/bank/pending`);
    } else {
      let pathArray = path.split("/");
      pathArray = map(pathArray, (path) => capitalCase(path));
      setBreadcrumbs(pathArray);
    }

    //Route Key
    let routeKeys = [];
    routeKeys = map(filteredAbility, (item) => {
      if (item.children) {
        return map(item.children, (child) => {
          return child.key;
        });
      }

      return item.key;
    });

    routeKeys = flatten(routeKeys);

    const isRootRoute = location.pathname == "/";

    if (isRootRoute) {
      setIsAbleToAccessPage(true);
    } else {
      if (size(routeKeys)) {
        const checkIsAbleToAccessPage = find(routeKeys, (item) => {
          // return item === router.asPath;
          return location.pathname.startsWith(item);
        });
        setIsAbleToAccessPage(checkIsAbleToAccessPage ? true : false);
      }
    }
  }, [location]);
  useEffect(() => {
    let routeKeys = [];

    routeKeys = map(filteredAbility, (item) => {
      if (item.children) {
        return map(item.children, (child) => {
          return child.key;
        });
      }

      return item.key;
    });

    routeKeys = flatten(routeKeys);

    const isRootRoute = location.pathname == "/";

    if (isRootRoute) {
      setIsAbleToAccessPage(true);
    } else {
      if (size(routeKeys)) {
        const checkIsAbleToAccessPage = find(routeKeys, (item) => {
          // return item === router.asPath;
          return location.pathname.startsWith(item);
        });
        setIsAbleToAccessPage(checkIsAbleToAccessPage ? true : false);
      }
    }
  }, [filteredAbility]);

  useEffect(() => {
    if (!isNull(isAbleToAccessPage)) {
      if (!isAbleToAccessPage) {
        if (
          location.pathname.startsWith("/signin") ||
          location.pathname.startsWith("/first-login") ||
          location.pathname.startsWith("/new-profile") ||
          location.pathname.startsWith("/change-password") ||
          location.pathname.startsWith("/error")
        ) {
          // router.push("/errors");
        } else {
          navigate("/error");
        }
      } else {
        // if (location.pathname.match(/mode=edit/g)) {
        //   // console.log()
        //   if (ability.cannot("update", "EditOnClick")) {
        //     navigate("/errors");
        //   }
        // }
      }
    }
  }, [isAbleToAccessPage]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLanguage = ({ key }) => {
    message.info(`Click on item ${key}`);
    const selectedLanguage = find(languages, { key: key });

    if (selectedLanguage) {
      dispatch(setLanguage(selectedLanguage.type));
      setLanguageFlag(selectedLanguage.icon);
    }
  };

  const _retrievePendingTransactions = async () => {
    try {
      await dispatch(retrievePendingTransactions()).unwrap();

      setTimerSecond(5);
      handleRefreshData();
    } catch (error) {
      message.error(error.toString());
    }
  };

  const _retrievePendingKycsCounts = async () => {
    try {
      await dispatch(retrievePendingKycsCounts()).unwrap();

      setTimerSecond(5);
      handleRefreshData();
    } catch (error) {
      message.error(error.toString());
    }
  };

  const handleRefreshData = () => {
    clearInterval(pendingCountIntervalRef.current);
    pendingCountIntervalRef.current = setInterval(
      () => setTimerSecond((prev) => prev - 1),
      1000
    );
  };

  useEffect(() => {
    if (timerSecond === 0) {
      _retrievePendingTransactions();
    }
  }, [timerSecond]);

  useEffect(() => {
    if (timerSecond === 0) {
      _retrievePendingKycsCounts();
    }
  }, [timerSecond]);

  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
  };

  const items = useMemo(() => {
    return [
      {
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center"
            }}
          >
            <div style={{ flex: 1 }}>
              <Link to="top-up/bank-deposit/pending">
                Pending Top-Ups (Banks)
              </Link>
            </div>
            <Badge
              count={reloadBankCount ? reloadBankCount : 0}
              style={{ flex: 1, backgroundColor: "#ff8000" }}
            ></Badge>
          </div>
        ),
        subject: "ReloadPendingBank",
        key: "/top-up/bank/pending"
      },

      {
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center"
            }}
          >
            <div style={{ flex: 1 }}>
              <Link to="/top-up/crypto/pending">Pending Top-Ups (Crypto)</Link>
            </div>
            <Badge
              count={reloadCryptoCount ? reloadCryptoCount : 0}
              style={{ flex: 1, backgroundColor: "#ff8000" }}
            ></Badge>
          </div>
        ),
        subject: "ReloadPendingCrypto",
        key: "/top-up/crypto/pending"
      },

      {
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center"
            }}
          >
            <div style={{ flex: 1 }}>
              <Link to="/send/bank/pending">Pending Sends</Link>
            </div>

            <Badge
              count={transferCount ? transferCount : 0}
              style={{ flex: 1, backgroundColor: "#ff8000" }}
            ></Badge>
          </div>
        ),
        subject: "TransferPending",
        key: "/send/bank/pending"
      },

      {
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center"
            }}
          >
            <div style={{ flex: 1 }}>
              <Link to="/kyc">Pending ID Verifications</Link>
            </div>

            <Badge
              count={kycCount ? kycCount : 0}
              style={{ flex: 1, backgroundColor: "#ff8000" }}
            ></Badge>
          </div>
        ),
        subject: "ReloadBank",
        key: "/kyc"
      },
      {
        type: "divider",
        subject: ["Reload", "Transfer"]
      },
      {
        label: <Link to="/top-up/bank">Top-Ups (Bank)</Link>,
        subject: "ReloadBank",
        key: "/top-up/bank"
      },
      {
        label: <Link to="/top-up/crypto">Top-Ups (Crypto)</Link>,
        subject: "ReloadCrypto",
        key: "/top-up/crypto"
      },
      {
        label: <Link to="/top-up/gift-card">Top-Ups (Gift Card)</Link>,
        subject: "ReloadGiftCard",
        key: "/top-up/gift-card"
      },
      {
        label: <Link to="/conversion">Conversions</Link>,
        subject: "Transfer",
        key: "/conversion"
      },
      {
        label: <Link to="/send/bank">Sends (Bank)</Link>,
        subject: "TransferBank",
        key: "/send/bank"
      },
      {
        label: <Link to="/send/member">Sends (Member)</Link>,
        subject: "TransferMember",
        key: "/send/member"
      },
      {
        label: <Link to="/adjustments">Adjustments</Link>,
        subject: "Adjustment",
        key: "/adjustments"
      },
      {
        type: "divider",
        subject: "Transaction"
      },
      {
        label: <Link to="/transaction/all">All Transactions</Link>,
        subject: "Transaction",
        key: "/transaction/all"
      },
      {
        type: "divider",
        subject: "Member"
      },
      {
        label: <Link to="/member">Members</Link>,
        subject: "Member",
        key: "/member"
      },
      {
        label: <Link to="/member/wallet-balance">Wallet Balance</Link>,
        subject: "Member",
        key: "/member/wallet-balance"
      },
      {
        label: <Link to="/member/mobile-secure-code">Mobile Secure Code</Link>,
        subject: "Member",
        key: "/member/mobile-secure-code"
      },

      {
        type: "divider",
        subject: ["Commission", "Report", "ActivityLog", "Setting"]
      },
      // {
      //   label: "Commissions",
      //   subject: "Commission",
      //   key: "/commission",
      //   onClick: () => {
      //     navigate(`/commission`);
      //   },
      // },
      {
        label: <Link to="/commission-log">Commission Logs</Link>,
        subject: "Commission",
        key: "/commission-log"
      },
      {
        label: <Link to="/report">Reports</Link>,
        subject: "Report",
        key: "/report"
      },
      {
        label: <Link to="/activity-log">Activity Log</Link>,
        subject: "ActivityLog",
        key: "/activity-log"
      },
      {
        label: <Link to="/settings">Settings</Link>,
        subject: "Setting",
        key: "/settings"
      },
      {
        type: "divider",
        subject: "Dashboard"
      },
      {
        label: <Link to="/dashboard">Dashboard</Link>,
        subject: "Dashboard",
        key: "/dashboard"
      }
      // {
      //   label: "Home",
      //   subject: "Setting",
      //   key: "/home",
      //   onClick: () => {
      //     navigate(`/home`);
      //   },
      // },
      // {
      //   type: "divider",
      //   subject: [
      //     "Reload",
      //     "Redeem",
      //     "Transfer",
      //     "Transaction",
      //     "Report",
      //     "ActivityLog",
      //     "Setting",
      //     "User",
      //   ],
      // },
      // {
      //   label: "OLD UI",
      //   key: "old-ui",
      //   subject: [
      //     "Reload",
      //     "Redeem",
      //     "Transfer",
      //     "Transaction",
      //     "Report",
      //     "ActivityLog",
      //     "Setting",
      //     "User",
      //   ],
      //   children: [
      //     {
      //       label: "Dashboard",
      //       subject: "Reload",
      //       key: "/",
      //       onClick: () => {
      //         navigate(`/`);
      //       },
      //     },
      //     {
      //       label: "Pending Transfer",
      //       subject: "Transfer",
      //       key: "/transfer",
      //       onClick: () => {
      //         navigate(`/transfer`);
      //       },
      //     },
      //     {
      //       label: "All Transfer",
      //       subject: "Transfer",
      //       key: "/transfer-history",
      //       onClick: () => {
      //         navigate(`/transfer-history`);
      //       },
      //     },
      //     {
      //       label: "Pending Reload",
      //       subject: "Reload",
      //       key: "/reload",
      //       onClick: () => {
      //         navigate(`/reload`);
      //       },
      //     },
      //     {
      //       label: "All Reload",
      //       subject: "Reload",
      //       key: "/reload-history",
      //       onClick: () => {
      //         navigate(`/reload-history`);
      //       },
      //     },
      //     {
      //       label: "Transaction",
      //       subject: "Transaction",
      //       key: "/transaction",
      //       onClick: () => {
      //         navigate(`/transaction`);
      //       },
      //     },
      //     {
      //       label: "Redeem",
      //       subject: "Redeem",
      //       key: "/redeem",
      //       onClick: () => {
      //         navigate(`/redeem`);
      //       },
      //     },
      //     {
      //       label: "User",
      //       subject: "User",
      //       key: "/user",
      //       onClick: () => {
      //         navigate(`/user`);
      //       },
      //     },
      //     {
      //       label: "Profile",
      //       subject: "User",
      //       key: "/profile",
      //       onClick: () => {
      //         navigate(`/profile`);
      //       },
      //     },
      //     {
      //       label: "AdvancedSettings",
      //       subject: "AdvancedSettings",
      //       key: "/advance-setting",
      //       children: [
      //         {
      //           label: "Currency",
      //           // subject: "Currency",
      //           key: "/currency",
      //           onClick: () => {
      //             navigate(`/currency`);
      //           },
      //         },
      //         {
      //           label: "Country",
      //           // subject: "Country",
      //           key: "/country",
      //           onClick: () => {
      //             navigate(`/country`);
      //           },
      //         },
      //         {
      //           label: "Banks",
      //           // subject: "Bank",
      //           key: "/bank",
      //           onClick: () => {
      //             navigate(`/bank`);
      //           },
      //         },
      //         {
      //           label: "Banks Accounts",
      //           // subject: "BankAccount",
      //           key: "/bank-accounts",
      //           onClick: () => {
      //             navigate(`/bank-accounts`);
      //           },
      //         },
      //         {
      //           label: "Exchange Rate",
      //           // subject: "ExchangeRate",
      //           key: "/exchange-rate",
      //           onClick: () => {
      //             navigate(`/exchange-rate`);
      //           },
      //         },
      //         {
      //           label: "Base Fixed Rate",
      //           // subject: "BaseFixedRate",
      //           key: "/base-fixed-rate",
      //           onClick: () => {
      //             navigate(`/base-fixed-rate`);
      //           },
      //         },
      //         {
      //           label: "Dashboard Users",
      //           // subject: "DashboardUser",
      //           key: "/dashboard-user",
      //           onClick: () => {
      //             navigate(`/dashboard-user`);
      //           },
      //         },
      //         {
      //           label: "General",
      //           // subject: "GeneralSetting",
      //           key: "/general-setting",
      //           onClick: () => {
      //             navigate(`/general-setting`);
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
    ];
  }, [reloadBankCount, reloadCryptoCount, transferCount, kycCount]);

  const language = (
    <Menu selectable onClick={handleLanguage} items={languages} />
  );

  const setting = (
    <Menu
      onClick={onClick}
      items={[
        {
          label: "Log Out",
          key: "1"
        }
      ]}
    />
  );

  // const menu = (
  //   <Menu
  //     onClick={onClick}
  //     items={[
  //       {
  //         label: "Settings",
  //         key: "1",
  //       },
  //       {
  //         label: "Mail",
  //         key: "2",
  //       },
  //       {
  //         label: "Print",
  //         key: "3",
  //       },
  //       {
  //         label: "Download",
  //         key: "4",
  //       },
  //       {
  //         label: "Share",
  //         key: "5",
  //       },
  //     ]}
  //   />
  // );

  // function getItem(label, key, icon, children, className, type) {
  //   return {
  //     key,
  //     icon,
  //     label,
  //     className,
  //     children,
  //     type,
  //   };
  // }

  // const items = [
  //   getItem(<div>NEW UI</div>, "20", <SettingOutlined />, [
  //     getItem(
  //       <Link to="top-up/bank-deposit/pending">
  //         Pending Top-Ups (Banks)
  //       </Link>,
  //       "26"
  //     ),
  //     getItem(<Link to="send/pending">Pending Sends</Link>, "29"),

  //     {
  //       type: "divider",
  //     },

  //     // ----------------

  //     getItem(
  //       <Link to="top-up/bank-deposit">Top-Ups (Banks)</Link>,
  //       "24"
  //     ),
  //     getItem(<Link to="top-up/gift-card">Top-Ups (Gift Card)</Link>, "25"),

  //     getItem(<Link to="conversion">Conversions</Link>, "34"),

  //     getItem(<Link to="send">Sends</Link>, "30"),

  //     {
  //       type: "divider",
  //     },
  //     // ----------------

  //     getItem(<Link to="transaction/all">All Transactions</Link>, "31"),

  //     {
  //       type: "divider",
  //     },

  //     // ----------------
  //     getItem(<Link to="customer">Customers</Link>, "32"),

  //     {
  //       type: "divider",
  //     },

  //     // ----------------

  //     getItem(<Link to="report">Reports</Link>, "42"),
  //     getItem(<Link to="activity-log">Activity Log</Link>, "43"),
  //     getItem(<Link to="settings">Settings</Link>, "39"),

  //     {
  //       type: "divider",
  //     },

  //     // ----------------

  //     // getItem(<Link to="new-exchange-rate">Exchange Rates</Link>, "21"),
  //     // getItem(<Link to="base-flat-rate">Base Flat Rates</Link>, "22"),
  //     // getItem(
  //     //   <Link to="customer-service-user">Customer Service Users</Link>,
  //     //   "23"
  //     // ),

  //     // getItem(<Link to="new-bank-account">Bank Accounts</Link>, "27"),

  //     // getItem(<Link to="new-currency">Currencies</Link>, "33"),

  //     // getItem(<Link to="new-bank">Banks List</Link>, "35"),
  //     // getItem(<Link to="super-admin">Super Admins</Link>, "36"),
  //     // getItem(<Link to="new-country">Active Countries</Link>, "37"),
  //     // getItem(
  //     //   <Link to="exchange-rate-margin">Exchange Rate Margin</Link>,
  //     //   "38"
  //     // ),

  //     // getItem(<Link to="new-profile">Profile</Link>, "40"),
  //     // getItem(<Link to="change-password">Change Password</Link>, "41"),
  //   ]),
  //   getItem(<Link to="/">Dashboard</Link>, "1", <HomeOutlined />),
  //   getItem(
  //     <Link to="transfer">
  //       <Badge count={transferCount} offset={[30, 7]}>
  //         Pending Transfer
  //       </Badge>
  //     </Link>,
  //     "2",
  //     <TransactionOutlined />
  //   ),
  //   getItem(
  //     <Link to="transfer-history">All Transfer</Link>,
  //     "3",
  //     <AccountBookOutlined />
  //   ),
  //   getItem(
  //     <Link to="reload">
  //       <Badge count={reloadCount} offset={[30, 7]}>
  //         Pending Reload
  //       </Badge>
  //     </Link>,
  //     "5",
  //     <TransactionOutlined />
  //   ),
  //   getItem(
  //     <Link to="reload-history">All Reload</Link>,
  //     "6",
  //     <AccountBookOutlined />
  //   ),
  //   getItem(
  //     <Link to="transaction">Transaction</Link>,
  //     "7",
  //     <CreditCardOutlined />
  //   ),

  //   getItem(<Link to="redeem">Redeem</Link>, "8", <GiftOutlined />),

  //   getItem(<Link to="user">User</Link>, "9", <UserOutlined />),

  //   getItem(<Link to="profile">Profile</Link>, "10", <GrUserSettings />),

  //   ability.can("manage", "AdvancedSettings") &&
  //     getItem("Advanced Settings", "group-1", null, [
  //       getItem(
  //         <Link to="currency">Currency</Link>,
  //         "11",
  //         <MoneyCollectOutlined />
  //       ),
  //       getItem(<Link to="country">Country</Link>, "12", <FlagOutlined />),
  //       getItem(<div>Banks</div>, "13", <BankOutlined />, [
  //         getItem(<Link to="bank">List</Link>, "14", <UnorderedListOutlined />),
  //         // getItem(
  //         //   <Link to="banks-by-country">Banks by Country</Link>,
  //         //   "12",
  //         //   <UnorderedListOutlined />
  //         // ),
  //         getItem(
  //           <Link to="bank-accounts">Banks Accounts</Link>,
  //           "15",
  //           <UnorderedListOutlined />
  //         ),
  //       ]),
  //       getItem(
  //         <Link to="exchange-rate">Exchange Rate</Link>,
  //         "16",
  //         <BsCurrencyExchange />
  //       ),
  //       getItem(
  //         <Link to="base-fixed-rate">Base Fixed Rate</Link>,
  //         "17",
  //         <BsCurrencyExchange />
  //       ),
  //       getItem(
  //         <Link to="dashboard-user">Dashboard Users</Link>,
  //         "18",
  //         <UserOutlined />
  //       ),
  //       getItem(
  //         <Link to="general-setting">General</Link>,
  //         "19",
  //         <SettingOutlined />
  //       ),
  //     ]),
  // ];

  const handleVisibleChange = (newVisible) => {
    setIsPopOverVisible(newVisible);
  };

  const markAllMessageAsRead = () => {
    try {
      dispatch(markAllAsRead()).unwrap();
      dispatch(resetNotificationPage());
      dispatch(resetNotification());
      dispatch(retrieveNotifications()).unwrap();
      dispatch(resetModalContent());
      dispatch(retrieveNotifications()).unwrap();
    } catch (error) {
      message.error(error.toString());
    }
  };

  const markAsRead = (item) => {
    try {
      dispatch(markMessageAsRead({ id: item.id })).unwrap();
      dispatch(resetNotificationPage());
      dispatch(resetNotification());
      dispatch(retrieveNotifications()).unwrap();
      setIsPopOverVisible(false);

      setTimeout(() => {
        dispatch(toggleNotificationModal(true));
      }, 100);

      dispatch(
        setModalContent({
          title: item.title,
          content: item.content
        })
      );

      dispatch(retrieveNotifications()).unwrap();
    } catch (error) {
      message.error(error.toString());
    }
  };

  const notificationHeader = (
    <Row justify="space-between" align="middle" gutter={50}>
      <Col>
        <div className={styles.notification_title}>Notification</div>
      </Col>
      <Col>
        <a onClick={() => markAllMessageAsRead()}>
          <div className={styles.underline}>Mark all as read</div>
        </a>
      </Col>
    </Row>
  );

  const notificationContent = () => (
    <div
      onScroll={onScroll}
      ref={notificationList}
      className={styles.notification_content}
    >
      <List
        itemLayout="horizontal"
        dataSource={notifications.message}
        renderItem={(item, index) => {
          return (
            <a key={index} onClick={() => markAsRead(item)}>
              <List.Item className={styles.list_item}>
                <List.Item.Meta
                  title={item.title}
                  description={
                    <>
                      <div>{item.content}</div>
                      <div style={{ fontSize: 12, marginTop: 10 }}>
                        {moment(item.created_at).fromNow()}
                      </div>
                    </>
                  }
                />
                <Badge
                  dot={item.is_read ? false : true}
                  style={{ backgroundColor: "#EE8915" }}
                />
              </List.Item>
            </a>
          );
        }}
      />
    </div>
  );

  const actions = [
    {
      label: "Log out",
      key: "logout"
    }
  ];

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/signin");
  //   }
  // }, [isLoggedIn]);

  const userMenuOnClick = async () => {
    try {
      await dispatch(logout()).unwrap();

      dispatch(setIsLoggedIn(false));
      dispatch(resetApp());
      dispatch(resetBank());
      dispatch(resetCountry());
      dispatch(resetCurrency());
      dispatch(resetExchange());
      dispatch(resetCountry());
      dispatch(resetNotification());
      dispatch(resetReload());
      dispatch(resetTransfer());
      dispatch(resetTransaction());
      dispatch(resetUi());
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <FullPageSpinner />
      <Layout className={styles.main_layout}>
        <Header>
          <Row justify="space-between" align="center">
            <Col span={8}>
              <Row align="middle" justifyContent="center">
                <Image
                  width={200}
                  src={require("../../assets/images/TopKash_Logo_0622.png")}
                  preview={false}
                />
              </Row>

              {/* <a onClick={toggleCollapsed}>
                  {collapsed ? (
                    <MenuUnfoldOutlined className={styles.toggle_icon_style} />
                  ) : (
                    <MenuFoldOutlined className={styles.toggle_icon_style} />
                  )}
                </a>
                <Breadcrumb className={styles.breadcrumb_margin_left}>
                  {breadcrumbs}
                </Breadcrumb> */}

              {/* <Row>
                <Breadcrumb className={styles.breadcrumb_margin_left}>
                  {map(breadcrumbs, (breadcrumb, index) => (
                    <Breadcrumb.Item key={index}></Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </Row> */}
            </Col>

            <Col>
              <Row align="left">
                {/* <Dropdown
                  overlay={language}
                  arrow={true}
                  destroyPopupOnHide={true}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>{languageFlag}</Space>
                  </a>
                </Dropdown> */}

                <Space>
                  {/* <Popover
                    visible={isPopOverVisible}
                    onVisibleChange={async (newVisible) => {
                      dispatch(resetNotificationPage());

                      dispatch(resetNotification());

                      await dispatch(retrieveNotifications()).unwrap();

                      handleVisibleChange(newVisible);
                    }}
                    placement="bottomRight"
                    title={notificationHeader}
                    content={notificationContent()}
                    trigger="click"
                    overlayStyle={{
                      width: contentWidth * 0.3,
                      height: contentHeight * 0.8,
                    }}
                  >
                    <Badge
                      size="small"
                      count={notifications.totalUnread}
                      offset={[-45, 5]}
                      style={{ backgroundColor: "#EE8915" }}
                    >
                      <Button style={{ background: "#EDEFF1" }} type="text">
                        <BellOutlined />
                      </Button>
                    </Badge>
                  </Popover> */}

                  <Popover
                    content={
                      <>
                        <Link
                          to="new-profile"
                          style={{ paddingBottom: 8, display: "block" }}
                        >
                          My Profile
                        </Link>
                        <Link to="change-password">Change Password</Link>
                        <Divider className={styles.popover_divider} />
                        <a onClick={(event) => userMenuOnClick(event)}>
                          Log Out
                        </a>
                      </>
                    }
                    items={actions}
                    trigger="hover"
                  >
                    <Button style={{ background: "#EDEFF1" }} type="text">
                      <UserOutlined />
                      {user.fullname}
                    </Button>
                  </Popover>
                </Space>
              </Row>
            </Col>
          </Row>
        </Header>
        {/* <Header className={styles.header}>
          <Row justify="space-between" align="middle">
            <Col>
              <Row align="middle">
                <a onClick={toggleCollapsed}>
                  {collapsed ? (
                    <MenuUnfoldOutlined className={styles.toggle_icon_style} />
                  ) : (
                    <MenuFoldOutlined className={styles.toggle_icon_style} />
                  )}
                </a>
                <Row>
                  <Breadcrumb className={styles.breadcrumb_margin_left}>
                    {map(breadcrumbs, (breadcrumb, index) => (
                      <Breadcrumb.Item key={index}>
                        {breadcrumb}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </Row>
              </Row>
            </Col>

            <Col>
              <Row align="middle">
                <Dropdown overlay={menu} arrow={true} destroyPopupOnHide={true}>
                  <a onClick={(e) => e.preventDefault()}>
                    <div className={styles.menu_container}>
                      <Row align="middle">
                        <div className={styles.menu_text}>Settings</div>
                        <DownOutlined className={styles.menu_icon_style} />
                      </Row>
                    </div>
                  </a>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Header> */}

        <div className={styles.content_container}>
          <Layout className="layout_content_container">
            <Sider
              collapsible={true}
              collapsed={collapsed}
              collapsedWidth={0}
              reverseArrow={true}
              width={270}
              className={styles.sider}
            >
              <div className="sider_menu">
                <Menu
                  className={[
                    styles.sider_menu,
                    styles.sider_menu_selected_item
                  ]}
                  // expandIcon={<></>}
                  defaultSelectedKeys={["pending-request"]}
                  // defaultOpenKeys={["1"]}
                  selectedKeys={siderMenuSelectedKey}
                  onClick={({ key }) =>
                    dispatch(
                      setSiderMenuSelectedKey({ siderMenuSelectedKey: key })
                    )
                  }
                  mode="inline"
                  // items={items}
                  items={
                    filteredAbility &&
                    filteredAbility.length > 0 &&
                    filteredAbility
                  }
                />
              </div>
            </Sider>
            <Layout>
              <Content ref={contentRef} className={styles.layout_content}>
                {contentHeight > 0 && children}
              </Content>
              <Footer className={styles.footer}>
                {" "}
                Copyright © 2023 Topkash, All Rights Reserved
              </Footer>
            </Layout>
          </Layout>
        </div>
      </Layout>

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center"
        }}
        visible={notifications.isNotificationModalVisible}
        closable={false}
        onOk={() => {
          dispatch(resetModalContent());
          dispatch(toggleNotificationModal(false));
        }}
        okText="Close"
        cancelButtonProps={{
          style: { display: "none" }
        }}
        okButtonProps={{
          style: { display: "inline-block" }
        }}
      >
        <Row align="center">{notifications.title}</Row>
        <Row align="center">{notifications.content}</Row>
      </Modal>
    </>
  );
};

CustomLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default CustomLayout;
