import React, { useEffect, useState, useCallback } from "react";
import { useAbility } from "@casl/react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
  Image,
  Modal,
  Carousel
} from "antd";
import { SearchOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findAllStatuses, assignCaseToUser } from "@store/app";
import { findAllBanners } from "@store/app";
import TableHeader from "@components/table-header";
import { AbilityContext, Can } from "@configs/can";
import { map, startCase } from "lodash";

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const [filter, setFilter] = useState({});
  const [tableData, setTableData] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  // const [statuses, setStatuses] = useState([]);
  const ability = useAbility(AbilityContext);

  const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (filter != null) {
        filter["localTimeOffset"] = localTimeOffset;
      }
      const { data } = await dispatch(findAllBanners({ filter })).unwrap();
      const { data: bannerData, total } = data;
      setTableData(bannerData);
      setTableTotalData(total);

      let bannerDataCopy = JSON.parse(JSON.stringify(bannerData));
      bannerDataCopy.sort((x, y) => x.sequence - y.sequence);
      setSortedTableData(bannerDataCopy);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ability.cannot("manage", "Setting")) {
      navigate("/error");
    }

    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const actionBtnOnClick = async (bannerId) => {
    navigate(`/settings/banner/${bannerId}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 80,
      render: (_, record) => {
        const { id } = record;
        return <div style={{ textAlign: "left" }}>{id}</div>;
      }
    },
    {
      title: "Banner Name",
      dataIndex: "name",
      align: "left",
      width: 180,
      render: (_, record) => {
        const { name } = record;
        return <div style={{ textAlign: "left" }}>{name}</div>;
      }
    },
    {
      title: "Media ID",
      dataIndex: "media_id",
      align: "left",
      width: 100,
      render: (_, record) => {
        const { media_id } = record;
        return <div style={{ textAlign: "left" }}>{media_id}</div>;
      }
    },
    {
      title: "Thumbnail",
      dataIndex: "file_url",
      align: "left",
      width: 200,
      render: (_, record) => {
        return (
          <Image
            style={{
              height: "100%",
              width: "100%",
              border: "1px solid #dcdcdc",
              borderRadius: "5px"
            }}
            src={record.file_url}
          />
        );
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "left",
      width: 80,
      render: (_, record) => {
        const { type } = record;
        return <div style={{ textAlign: "left" }}>{startCase(type)}</div>;
      }
    },
    {
      title: "Navigation Stack",
      dataIndex: "navigation_stack",
      align: "left",
      width: 140,
      render: (_, record) => {
        const { navigation_stack } = record;
        return (
          <div style={{ textAlign: "left" }}>{navigation_stack ?? "-"}</div>
        );
      }
    },
    {
      title: "Navigation Screen",
      dataIndex: "navigation_screen",
      align: "left",
      width: 140,
      render: (_, record) => {
        const { navigation_screen } = record;
        return (
          <div style={{ textAlign: "left" }}>{navigation_screen ?? "-"}</div>
        );
      }
    },
    {
      title: "Website",
      dataIndex: "website",
      align: "left",
      width: 200,
      render: (_, record) => {
        const { website } = record;
        return <div style={{ textAlign: "left" }}>{website ?? "-"}</div>;
      }
    },
    {
      title: "Sequence",
      dataIndex: "sequence",
      align: "left",
      width: 90,
      render: (_, record) => {
        const { sequence } = record;
        return <div style={{ textAlign: "left" }}>{sequence}</div>;
      }
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "left",
      width: 190,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment(created_at)
              .add(-localTimeOffset, "minutes")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        ); // 16:23
        // return <div>{moment(created_at).format("DD-MM-YYYY, hh:mm:ss A")}</div>; // 12:23
      }
    },
    {
      title: "Status",
      dataIndex: "is_enabled",
      align: "left",
      fixed: "right",
      width: 80,
      render: (_, record) => {
        let is_enabled;
        let color;

        if (record.is_enabled === 0) {
          is_enabled = "Disabled";
          color = "#FF0000";
        } else if (record.is_enabled === 1) {
          is_enabled = "Enabled";
          color = "#95de64";
        }

        return <div style={{ color }}>{is_enabled}</div>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        const { id } = record;
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => actionBtnOnClick(id)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  const previewButtonOnClick = () => {
    setShowPreview(true);
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          fontSize: "15px"
        }}
        onClick={onClick}
      >
        <RightOutlined style={{ color: "black" }} />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          fontSize: "15px"
        }}
        onClick={onClick}
      >
        <LeftOutlined style={{ color: "black" }} />
      </div>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Banners"
        showCreateButton={true}
        createButtonText={"Create New Banner"}
        showResetButton={true}
        showFilterButton={true}
        // showCustomButton={true}
        // customButtonText={"Banner Full Preview"}
        // customOnClick={previewButtonOnClick}
        // customButtonDisable={tableData && tableData.length > 0 ? false : true}
        customButtons={[
          {
            show: true,
            text: 'Banner Full Preview',
            onClick: previewButtonOnClick,
            disabled: tableData && tableData.length > 0 ? false : true
          }
        ]}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        createOnClick={() => navigate("/settings/banner/add")}
        resetOnClick={() => {
          resetButtonOnClick()
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>

          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
      <Modal
        className={styles.modal_container}
        centered={true}
        visible={showPreview}
        onCancel={() => {
          setShowPreview(false);
        }}
        footer={null}
      >
        <Carousel dots={true} arrows {...settings}>
          {map(sortedTableData, (item) => {
            if (item.is_enabled) {
              return (
                <div>
                  <div style={{ textAlign: "center", fontSize: "18px" }}>
                    {item.name}
                  </div>
                  <div>
                    <Image
                      style={{
                        height: "100%",
                        width: "100%",
                        padding: "5px"
                      }}
                      src={item.file_url}
                    />
                  </div>
                </div>
              );
            }
          })}
        </Carousel>
      </Modal>
    </div>
  );
};

export default Banner;
