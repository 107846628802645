import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Form,
  Input,
  Row,
  Select,
  Button,
  message,
  Space,
  Divider,
  Table,
} from "antd";

import styles from "./styles.module.less";
import {
  getAllRoles,
  findUserById,
  updateDashboardUser,
  findActivityLogsByRefIdAndRefTable,
} from "@store/app";
import { getAllCountries } from "@store/country";
import HistoryTable from "../../../components/history-table";

const CustomerServiceEdit = () => {
  const { params } = useMatch("customer-service-user/:id");
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    name: "",
  });
  const [countries, setCountries] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [historyData, setHistoryData] = useState([]);

  const updateProfile = async (values) => {
    try {
      const { data } = await dispatch(
        updateDashboardUser({
          fullname: values.fullname,
          email: values.email,
          phone_no: values.phone_no,
          // nationality: values.nationality,
          role_id: selectedRole.id,
          dashboard_user_id: params.id,
        })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(getAllRoles());
    const fetchData = async () => {
      // Retrieve countries
      try {
        const countriesPayload = await dispatch(getAllCountries()).unwrap();
        const { data: countryData } = countriesPayload;
        setCountries(countryData);

        // Retrieve dashbord user

        const userPayload = await dispatch(
          findUserById({
            id: params.id,
          })
        ).unwrap();

        const { data: userData } = userPayload;

        if (userData) {
          formRef.current.setFieldsValue({
            role: userData.role,
            role_id: userData.role_id,
            username: userData.username,
            fullname: userData.fullname,
            email: userData.email,
            phone_no: userData.phone_no,
            status: userData.status,
            created_at: moment(userData.created_at).format(
              "YYYY-MM-DD, hh:mm:ss A"
            ),
          });

          setUserDetail(userData);
        }

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "users",
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  console.log(userDetail);

  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">Edit Customer Service User</div>
        </Row>
        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          // style={{
          //   maxWidth: 1000,
          // }}
          initialValues={{
            remember: true,
          }}
          onFinish={(values) => {
            updateProfile(values);
          }}
          className="form-details"
        >
          <Form.Item name="status" label="Status" className="label-details">
            <div>{userDetail.status === 1 ? "Active" : "Blocked"}</div>
          </Form.Item>

          <Form.Item name="id" label="User ID" className="label-details">
            <div>{userDetail.id}</div>
          </Form.Item>

          <Form.Item name="role" label="User Type" className="label-details">
            <div>{userDetail.role?.name}</div>
          </Form.Item>

          <Divider className="divider" />

          <Form.Item name="username" label="Username" className="input">
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="fullname"
            label="Full Name"
            className="input"
            rules={[
              {
                required: true,
                message: "Fullname cannot be empty",
              },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            className="input"
            rules={[
              {
                required: true,
                message: "Email cannot be blank",
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="phone_no"
            label="Mobile"
            className="input"
            rules={[
              {
                required: true,
                message: "Mobile number cannot be empty",
              },
            ]}
          >
            <Input placeholder="Mobile" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Space>
                  <Button onClick={goBack}>Cancel</Button>
                  <Button
                    type="default"
                    htmlType="submit"
                    className="ant-btn-green"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Row>
          </Form.Item>
        </Form>

        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default CustomerServiceEdit;
