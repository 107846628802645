import { has, isNil, isArray, chain, size } from "lodash";
import QueryString from "qs";

const queryBuilder = (path, pagination, filter) => {
  const { current, pageSize, search, sort, from_date, to_date } = pagination;

  let url = `${path}?page=${current}&page_size=${pageSize}`;

  if (search) {
    url = `${url}&search=${search}`;
  }

  if (sort) {
    url = `${url}&sort=${sort}`;
  }

  if (from_date) {
    url = `${url}&from_date=${from_date}`;
  }

  if (to_date) {
    url = `${url}&to_date=${to_date}`;
  }

  let finalFilterObject = chain(filter)
    .omitBy((item) => {
      return isNil(item) || item == "";
    })
    .omitBy((item) => {
      if (isArray(item)) {
        return size(item) === 0;
      }
    })
    .value();

  if (size(finalFilterObject)) {
    /**
     * To parse moment instance to readable date format
     */
    if (has(finalFilterObject, "created_at")) {
      const { created_at } = finalFilterObject;

      finalFilterObject = {
        ...finalFilterObject,
        created_at: [
          created_at[0].format("YYYY-MM-DD"),
          created_at[1].format("YYYY-MM-DD"),
        ],
      };
    }

    if (has(finalFilterObject, "processed_at")) {
      const { processed_at } = finalFilterObject;

      finalFilterObject = {
        ...finalFilterObject,
        processed_at: [
          processed_at[0].format("YYYY-MM-DD"),
          processed_at[1].format("YYYY-MM-DD"),
        ],
      };
    }

    if (has(finalFilterObject, "selected_date")) {
      const { selected_date } = finalFilterObject;

      finalFilterObject = {
        ...finalFilterObject,
        selected_date: selected_date.format("YYYY-MM-DD"),
      };
    }

    const filterString = QueryString.stringify(finalFilterObject);
    url = `${url}&${filterString}`;
  }

  return url;
};

export default queryBuilder;
