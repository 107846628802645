import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { Card, Form, Row, Select, Button, message, Divider, Space } from "antd";
import { map, filter } from "lodash";
// ============
import styles from "./styles.module.less";
import { findTransferById, updateTransferById } from "@store/transfer";
import moment from "moment-timezone";
import HistoryTable from "../../../../components/history-table";

const SendTopkashMemberEdit = () => {
  const { params } = useMatch("send/member/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [searchParams] = useSearchParams();

  const userId = useSelector((state) => state.app.user.id);

  const [transferInfo, setTransferInfo] = useState({});
  const [allowEditing, setAllowEditing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(
          findTransferById({
            id: params.id
          })
        ).unwrap();

        const { data: transferData } = res;

        if (transferData) {
          // if (transferData.status == 1 || transferData.status == 2) {
          setTransferInfo(transferData);

          if (transferData.assigned_user_id) {
            let assignedUserId = transferData.assigned_user_id;

            if (assignedUserId == userId) {
              setAllowEditing(true);
            }
          }
          // } else {
          //   message.error("This transfer record is not available for editing");
          //   navigate("/transfer");
          // }
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const renderStatusColor = () => {
    return { color: "#339933" };
  };

  const renderStatus = () => {
    return "Successful";
  };

  const renderSendType = (type) => {
    if (type === "TOPKASH") {
      return "Topkash Member";
    } else {
      return "Bank";
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">View Send</div>
        </Row>

        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
        >
          <Form.Item name="status" label="Status" className="label-details">
            <div style={renderStatusColor(transferInfo.status)}>
              {renderStatus(transferInfo.status)}
            </div>
          </Form.Item>

          <Form.Item name="doc_id" label="ID" className="label-details">
            <div>{transferInfo.doc_id}</div>
          </Form.Item>

          <Form.Item
            name="send_type"
            label="Send Type"
            className="label-details"
          >
            <div>{renderSendType(transferInfo.user_bank_account_type)}</div>
          </Form.Item>

          <Form.Item
            name="submission_date"
            label="Submission Date"
            className="label-details"
          >
            <div>
              {moment
                .utc(transferInfo.created_at)
                .tz("Asia/Phnom_Penh")
                .format("DD-MM-YYYY, hh:mm:ss A")}
            </div>
          </Form.Item>

          <Divider className="divider" />

          <Form.Item
            name="topkash_id"
            label="Topkash ID"
            className="label-details"
          >
            <div>{transferInfo.user_topkash_id}</div>
          </Form.Item>

          <Form.Item name="username" label="Username" className="label-details">
            <div>{transferInfo.user_username}</div>
          </Form.Item>

          <Form.Item
            name="fullname"
            label="Full Name"
            className="label-details"
          >
            <div>
              {transferInfo.user_fullname ? transferInfo.user_fullname : "-"}
            </div>
          </Form.Item>

          <Form.Item name="mobile" label="Mobile" className="label-details">
            <div>{transferInfo.user_phone_no}</div>
          </Form.Item>

          <Form.Item name="email" label="Email" className="label-details">
            <div>{transferInfo.user_email}</div>
          </Form.Item>

          <Divider className="divider" />

          <div className="section_subtitle">Conversion</div>
          {/* <Form.Item label="Conversion" className={styles.section_subtitle} /> */}

          <Form.Item
            name="from_currency"
            label="From Currency"
            className="label-details"
          >
            <div>
              {transferInfo.from_currency_code} -{" "}
              {transferInfo.from_currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="from_amount"
            label="From Amount"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(transferInfo.amount)).format("0,0.00")}
            </div>
          </Form.Item>

          <Form.Item name="fee" label="Fee" className="label-details">
            <div>
              {numeral(parseFloat(transferInfo.processing_fee)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="amount_to_converted"
            label="Amount To Converted"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(transferInfo.to_convert_amount)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="exchange_rate"
            label="Exchange Rate"
            className="label-details"
          >
            <div>
              {transferInfo.from_currency_code == transferInfo.to_currency_code
                ? "1.000000"
                : numeral(parseFloat(transferInfo.exchange_rate)).format(
                    "0,0.000000"
                  )}
            </div>
          </Form.Item>

          <Form.Item
            name="to_currency"
            label="To Currency"
            className="label-details"
          >
            <div>
              {transferInfo.to_currency_code} - {transferInfo.to_currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="to_amount"
            label="To Amount"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(transferInfo.converted_amount)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Divider className="divider" />

          <div className="section_subtitle">Transfer To Topkash Member</div>
          {/* <Form.Item
            label="Transfer To Topkash Member"
            className={styles.section_subtitle}
          /> */}

          <Form.Item
            name="to_user_topkash_id"
            label="Topkash ID"
            className="label-details"
          >
            <div>{transferInfo.to_user_topkash_id}</div>
          </Form.Item>

          <Form.Item
            name="to_user_username"
            label="Username"
            className="label-details"
          >
            <div>{transferInfo.to_user_username}</div>
          </Form.Item>

          <Form.Item
            name="to_user_fullname"
            label="Full Name"
            className="label-details"
          >
            <div>
              {transferInfo.to_user_fullname
                ? transferInfo.to_user_fullname
                : "-"}
            </div>
          </Form.Item>

          <Form.Item
            name="to_user_phone_no"
            label="Mobile"
            className="label-details"
          >
            <div>{transferInfo.to_user_phone_no}</div>
          </Form.Item>

          <Form.Item
            name="to_user_email"
            label="Email"
            className="label-details"
          >
            <div>{transferInfo.to_user_email}</div>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default SendTopkashMemberEdit;
