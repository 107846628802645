import React, { useState, useEffect } from "react";
import { Row, Col, Card, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AuthCode from "react-auth-code-input";
//====
import styles from "./styles.module.less";
import { setIsLoggedIn, tfaVerification } from "../../store/app";

const TwoFactorAuthentication = () => {
  const dispatch = useDispatch();

  const [verifyCode, setVerifyCode] = useState(false);

  const status = useSelector((state) => state.app.tfaVerificationStatus);

  const handleOnChange = (res) => {
    try {
      if (res.length === 6) {
        setVerifyCode(res);

        dispatch(tfaVerification({ code: res }));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (verifyCode && status == 1) {
      dispatch(setIsLoggedIn(true));
    }
  }, [status]);

  return (
    <>
      <Row className={styles.tfa_container}>
        <Card className={styles.tfa_card}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className={styles.tfa_title}>Two Factor Authentication</div>
            </Col>
            <Col span={24}>
              <div className={styles.tfa_desc}>
                Please enter your 6-digits verification code
              </div>
            </Col>
            <AuthCode
              allowedCharacters="numeric"
              onChange={handleOnChange}
              inputClassName={styles.authCodeStyle}
              containerClassName={styles.authCodeContainerStyle}
            />
          </Row>
          <Row align="middle" justify="center">
            {verifyCode && status != 1 ? (
              <Row justify="center" align="center">
                <div className={styles.unmatch_code_error_text}>
                  Invalid code
                </div>
              </Row>
            ) : null}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default TwoFactorAuthentication;
