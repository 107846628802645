import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  DatePicker,
  Button,
  Space,
  message,
  Modal,
  Form,
  Select,
  Col
} from "antd";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { map, chain, isEmpty } from "lodash";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableFilter,
  resetTablePagination,
  resetTableSort,
  resetTableSearch,
  resetTableFilter
} from "@store/ui";
import { retrieveExchangeRatesList } from "@store/exchange";
import TableHeader from "@components/table-header";

const ExchangeRate = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { contentHeight, table } = useSelector((state) => state.ui);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [daysUnupdated, setDaysUnupdated] = useState(0);
  const [loading, setLoading] = useState(false);
  const [optionFromCurrency, setOptionFromCurrency] = useState(0);
  const [optionToCurrency, setOptionToCurrency] = useState(0);
  const [fromCurrency, setFromCurrency] = useState([]);
  const [toCurrency, setToCurrency] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { RangePicker } = DatePicker;

  const currencies = useSelector((state) => state.currency?.currencies);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());

    // dispatch(setTableFilter({ from_date: moment().format("YYYY-MM-DD") }));
    let exchangeRateData = fetchData();

    if (table.current === 1) {
      const endDate = moment(exchangeRateData[0]?.effective_date, "YYYY-MM-DD");
      setDaysUnupdated(moment().diff(endDate, "days"));
    }
  }, []);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        retrieveExchangeRatesList({
          filter
        })
      ).unwrap();
      const { data: exchangeRateData, total } = data;
      setTableData(exchangeRateData);
      setTableTotalData(total);

      return exchangeRateData;
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (range) => {
    setDateRange(range);
  };

  const resetButtonOnClick = () => {
    // dispatch(resetTablePagination());
    // dispatch(resetTableFilter());
    setFilter({});
    // fetchData();

    let option = map(currencies, (item) => {
      return {
        label: item.iso_code,
        value: item.iso_code,
        disabled: false
      };
    });

    setOptionFromCurrency(option);
    setOptionToCurrency(option);
  };

  useEffect(() => {
    currencyList();
  }, [currencies]);

  const currencyList = () => {
    let option = map(currencies, (item) => {
      return {
        label: item.iso_code,
        value: item.iso_code
      };
    });

    setOptionFromCurrency(option);

    let optionTo = remappedCurrencyList(fromCurrency);
    setOptionToCurrency(optionTo);
  };
  const remappedCurrencyList = (values) => {
    let option = map(currencies, (item) => {
      if (values.includes(item.iso_code)) {
        return {
          label: item.iso_code,
          value: item.iso_code,
          disabled: true
        };
      } else {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      }
    });

    return option;
  };

  const handleToCurrency = (value) => {
    setToCurrency(value);
    let option = remappedCurrencyList(value);
    setOptionFromCurrency(option);
  };

  const handleFromCurrency = (values) => {
    setFromCurrency(values);

    let option = remappedCurrencyList(values);

    setOptionToCurrency(option);
  };

  const columns = [
    {
      title: "Effective Date",
      dataIndex: "effective_date",
      key: "created_at",
      align: "left",
      render: (_, record) => {
        return (
          <div>
            {moment
              .utc(record.created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      }
    },

    {
      title: "From Currency",
      dataIndex: "from_currency",
      key: "from_currency",
      align: "left",
      width: "25%",
      render: (_, record) => {
        return (
          <div>
            {record.from_currency_iso_code} - {record.from_currency_name}
          </div>
        );
      }
    },

    {
      title: "To Currency",
      dataIndex: "to_currency",
      key: "to_currency",
      align: "left",
      width: "25%",
      render: (_, record) => {
        return (
          <div>
            {record.to_currency_iso_code} - {record.to_currency_name}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Rate</div>
          </span>
        );
      },
      dataIndex: "rate",
      key: "rate",
      render: (_, record) => {
        if (record.rate) {
          return (
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(record.rate)).format("0,0.000000")}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let field = sorter.field;

      dispatch(
        setTableSort({
          sort: `${field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Cost Exchange Rates"
        createButtonText="Create New Cost Exchange Rate"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        createOnClick={() => navigate("/settings/cost-exchange-rate/add")}
        resetOnClick={resetButtonOnClick}
      >
        <>
          <Col>
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"from_currency"} initialValue={[]}>
              <Select
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="From Currency"
                options={optionFromCurrency}
                onChange={(values) => handleFromCurrency(values)}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"to_currency"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="To Currency"
                options={optionToCurrency}
                onChange={(values) => handleToCurrency(values)}
                showArrow
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>

      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default ExchangeRate;
