import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";

const initialState = {
  isLoading: false,
  transfer: [],
};

export const findTransferCaseById = createAsyncThunk(
  "transfer/findTransferCaseById",
  async ({ id, mode }) => {
    try {
      const { data } = await axios.get(`cases/transfer/${id}?mode=${mode}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findTransferById = createAsyncThunk(
  "transfer/findTransferById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`transfers/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateTransferById = createAsyncThunk(
  "transfer/updateTransferById",
  async (values) => {
    try {
      let dataTemp = JSON.stringify({
        status: values.status,
        reject_reason: values.reject_reason
          ? values.reject_reason.trim()
          : null,
        tk_bank_account_id: values.tk_bank_account_id,
        tk_reference_no: values.tk_reference_no,
      });

      const { data } = await axios.put(
        `cases/transfer/${values.id}`,
        dataTemp,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllConverts = createAsyncThunk(
  "transfer/findAllConverts",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "transfers/all/convert",
        getState().ui.table,
        filter
      );
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllTransfersByType = createAsyncThunk(
  "transfer/findAllTransfersByType",
  async ({ type, filter }, { getState }) => {
    try {
      const url = queryBuilder(
        `transfers/all/${type}`,
        getState().ui.table,
        filter
      );
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllTransfers = createAsyncThunk(
  "transfer/findAllTransfers",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "cases/transfer/all",
        getState().ui.table,
        filter
      );
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllPendingTransfers = createAsyncThunk(
  "transfer/findAllPendingTransfers",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "cases/transfer/pending",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

/**
 * To be removed
 */
export const getTransferCases = createAsyncThunk(
  "transfer/getTransferCases",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("cases/transfer", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    resetTransfer: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    const matchBuilder = (action, status) =>
      action.type.startsWith("transfer/updateTransferById") &&
      action.type.endsWith(status);

    builder.addCase(getTransferCases.fulfilled, (state, { payload }) => {
      // state.isLoading = false;
      state.transfer = payload.data.data;
    });

    builder.addCase(updateTransferById.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addMatcher(
      (action) => matchBuilder(action, "/pending"),
      (state) => ({
        ...state,
        isLoading: true,
      })
    );

    builder.addMatcher(
      (action) => matchBuilder(action, "/Unsuccessful"),
      (state) => ({
        ...state,
        isLoading: false,
      })
    );
  },
});

export const { resetTransfer } = transferSlice.actions;

export default transferSlice.reducer;
