import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  DatePicker,
  Button,
  Space,
  message,
  Modal,
  Form,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableFilter,
  resetTablePagination,
  resetTableSort,
  resetTableSearch,
  resetTableFilter,
} from "@store/ui";
import { retrieveAllCurrencies } from "@store/currency";
import { retrieveBaseFixedRates } from "@store/base-fixed-rates";
import { map, chain, filter, sortBy } from "lodash";

const BaseFixedRate = () => {
  const dispatch = useDispatch();

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [daysUnupdated, setDaysUnupdated] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());

    dispatch(setTableFilter({ from_date: moment().format("YYYY-MM-DD") }));
    fetchData();
    fetchcurrencies();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await dispatch(retrieveBaseFixedRates()).unwrap();
      const { current_page, data: baseFixedRateData, total } = data;

      if (current_page === 1) {
        const endDate = moment(baseFixedRateData[0].date, "YYYY-MM-DD");
        setDaysUnupdated(moment().diff(endDate, "days"));
      }

      setTableData(baseFixedRateData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchcurrencies = async () => {
    try {
      const { data } = await dispatch(retrieveAllCurrencies()).unwrap();
      let tempData = [];
      let parentData = [];
      let childData = [];

      chain(data)
        // .filter(data => data.id != 1)

        .map((parent, index) => {
          parentData.push({
            id: parent.id,
            currency_name: parent.currency_name,
            currency_iso: parent.currency_iso,
          });
          data.map((child, index1) => {
            if (parent.id != child.id) {
              childData.push({
                from_currency_id: parent.id,
                from_currency_iso: parent.currency_iso,
                to_currency_id: child.id,
                to_currency_iso: child.currency_iso,
              });
            }
          });

          // const childTempData = childData.map((item, index) => {
          //   if(item.currency_from == parent.id){
          //     return {
          //       currency_from: item.currency_from,
          //       currency_to: item.currency_to,
          //     };
          //   }
          // });
          const result = childData.filter(
            (item) => item.from_currency_id == parent.id
          );
          tempData.push({
            parent: parentData[index],
            child: result,
          });
        })
        .value();
    } catch (error) {
      message.error(error.message);
    }
  };

  const disabledDate = (current) => {
    // Can only select days within the last one month including today
    return (
      current &&
      !(
        current >= moment().subtract(1, "month").startOf("day") &&
        current <= moment().endOf("day")
      )
    );
  };

  // const onFinish = async (values) => {
  //   const { selected_date } = values;

  //   try {
  //     setLoading(true);
  //     const { data } = await dispatch(
  //       updateExchangeRatesByAPI(moment(selected_date).format("YYYY-MM-DD"))
  //     ).unwrap();
  //     message.success(data.message);
  //     setLoading(false);
  //     fetchData();
  //     setVisible(false);
  //   } catch (error) {
  //     message.error(error.message);
  //     setLoading(false);
  //   }
  // };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
    },
    {
      title: "Total Currency",
      dataIndex: "total_curr",
      key: "total_curr",
      align: "left",
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Currencies",
        dataIndex: "from_curr",
        key: "from_curr",
        align: "left",
      },
    ];

    const columnsInner = [
      {
        title: "From",
        dataIndex: "from_currency",
        key: "from_currency",
        align: "center",
        width: "25%",
      },
      {
        title: "To",
        dataIndex: "to_currency",
        key: "to_currency",
        align: "center",
        width: "25%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "left",
      },
    ];

    const expandedRowRenderInner = (record) => {
      return (
        <Table columns={columnsInner} dataSource={record} pagination={false} />
      );
    };

    return (
      <Table
        columns={columns}
        dataSource={record.currencies}
        expandable={{
          expandedRowRender: (subRecord) =>
            expandedRowRenderInner(subRecord.to_curruncies),
        }}
        pagination={false}
        showHeader={false}
      />
    );
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let field = sorter.field;

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row align="middle" justify="space-between">
          <div>Last Update was {daysUnupdated} day(s) ago</div>
          <Row>
            <Space>
              <div>
                <Button
                  onClick={() => setVisible(true)}
                  className="secondary_btn"
                  icon={<PlusOutlined />}
                >
                  Update
                </Button>
              </div>
            </Space>
          </Row>
        </Row>
      </div>

      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        expandable={{ expandedRowRender }}
        onChange={onChange}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
        }}
      />
      <Modal
        className={styles.modal_container}
        visible={visible}
        okText="Update"
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ height: "100%", overflow: "auto", position: "relative" }}>
          <Form
            form={form}
            layout="vertical"
            id="dynamic_form_nest_item"
            // onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item
              name="selected_date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please select a date",
                },
              ]}
            >
              <DatePicker
                className={styles.date_picker}
                disabledDate={disabledDate}
                style={{ width: "100%", padding: 10 }}
              />
            </Form.Item>

            <Button
              type="default"
              loading={loading}
              htmlType="submit"
              className="secondary_btn"
            >
              Update
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default BaseFixedRate;
