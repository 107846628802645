import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Row,
  Button,
  message,
  Select,
  InputNumber,
  Col,
  Table,
  Space,
  Tooltip,
} from "antd";
import { map } from "lodash";
import { paramCase, capitalCase } from "change-case";
// ============
import styles from "./styles.module.less";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { findFaqLayerById, updateFaqLayerById } from "../../../store/faq";
import { EditOutlined } from "@ant-design/icons";

const { Option } = Select;

const FaqLayerEdit = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusOption = [
    { value: 0, label: "Disabled" },
    { value: 1, label: "Enabled" },
  ];
  const [value, setValue] = useState("");
  const [foreignId, setForeignId] = useState(null);
  const [layerTwos, setLayerTwos] = useState([]);

  const { type, id } = useParams();

  const columns = [
    {
      title: <div className={styles.label}>ID</div>,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "left",
      width: 80,
    },
    {
      title: <div className={styles.label}>Title</div>,
      dataIndex: "title",
      key: "title",
      align: "left",
    },
    {
      title: <div className={styles.label}>Slug</div>,
      dataIndex: "slug",
      key: "slug",
      align: "left",
    },
    {
      title: <div className={styles.label}>Code</div>,
      dataIndex: "code",
      key: "code",
      align: "left",
      render: (_, record) => {
        return <div>{record.code ? record.code : "-"}</div>;
      },
    },
    {
      title: <div className={styles.label}>Sequence</div>,
      dataIndex: "sequence",
      key: "sequence",
      align: "left",
    },

    {
      title: <div className={styles.label}>Mail Support</div>,
      dataIndex: "is_enable_mail_support",
      key: "is_enable_mail_support",
      align: "left",
      render: (_, record) => {
        let statusText;
        let color;

        if (record.is_enable_mail_support) {
          statusText = "Enabled";
          color = "#95de64";
        } else {
          statusText = "Disabled";
          color = "#FF0000";
        }

        return <div style={{ color }}>{statusText}</div>;
      },
    },

    {
      title: <div className={styles.label}>Call Us</div>,
      dataIndex: "is_enable_call_us",
      key: "is_enable_call_us",
      align: "left",
      render: (_, record) => {
        let statusText;
        let color;

        if (record.is_enable_call_us) {
          statusText = "Enabled";
          color = "#95de64";
        } else {
          statusText = "Disabled";
          color = "#FF0000";
        }

        return <div style={{ color }}>{statusText}</div>;
      },
    },

    {
      title: <div className={styles.label}>Action</div>,
      key: "action",
      align: "center",
      render: (_, record) => {
        const { id } = record;
        return (
          <>
            <div>
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => navigate(`/settings/faq-layer/two/${id}`)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Space>
            </div>
          </>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const { content } = record;

    return (
      <div>
        <div
          style={{
            backgroundColor: "#fafafa",
            paddingBottom: 10,
          }}
        >
          Content
        </div>

        {content ? (
          <MDEditor.Markdown
            source={content}
            style={{
              borderRadius: 10,
              border: "1px solid #f0f0f0",
              padding: 25,
            }}
            wrapperElement={{
              "data-color-mode": "light",
            }}
          />
        ) : (
          <Row align="middle" justify="center">
            --- No content available ----
          </Row>
        )}
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, [id, type]);

  const fetchData = async () => {
    try {
      const { data: initializeData } = await dispatch(
        findFaqLayerById({
          id,
          type,
        })
      ).unwrap();

      if (initializeData) {
        const faqLayerTwos = map(initializeData.children, (item) => {
          return {
            ...item,
            key: item.id,
          };
        });

        setLayerTwos(faqLayerTwos);

        setForeignId(
          type === "one"
            ? initializeData.faq_category_id
            : initializeData.faq_layer_one_id
        );

        formRef.current.setFieldsValue({
          title: initializeData.title,
          slug: initializeData.slug,
          content: initializeData.content,
          code: initializeData.code,
          sequence: initializeData.sequence,
          is_enable_mail_support: initializeData.is_enable_mail_support,
          is_enable_call_us: initializeData.is_enable_call_us,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleSubmit = async (values) => {
    if (values) {
      try {
        let payload = {
          id,
          type,
          faq_category_id: foreignId,
          ...values,
        };

        if (type === "two") {
          console.log("inside type = two here");
          payload = {
            id,
            type,
            faq_layer_one_id: foreignId,
            ...values,
          };
        }

        const { data } = await dispatch(updateFaqLayerById(payload)).unwrap();

        message.success(data.message);
        navigate(-1);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>
            Edit FAQ Layer {capitalCase(type)}
          </div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="title"
            className={styles.input}
            label="Title"
            rules={[
              {
                required: true,
                message: "Please enter your title",
              },
              {
                required: true,
                validator: async (_, value) => {
                  const regex = /^\s+|\s+$/g;

                  if (value) {
                    if (regex.test(value)) {
                      throw new Error(
                        "Title should not contain space before and after it"
                      );
                    }
                  }
                },
              },
            ]}
          >
            <Input
              placeholder="Title"
              onChange={(e) => {
                formRef.current.setFieldValue(
                  "slug",
                  paramCase(e.target.value)
                );
              }}
            />
          </Form.Item>

          <Form.Item name="slug" label="Slug" className="input">
            <Input disabled placeholder="Slug" />
          </Form.Item>

          <Form.Item name="content" label="Content" className="md_editor_style">
            <MDEditor
              value={value}
              onChange={setValue}
              commands={[
                commands.bold,
                commands.strikethrough,
                commands.hr,
                commands.title,
                commands.link,
                commands.quote,
                commands.code,
                commands.codeBlock,
                commands.comment,
                commands.image,
                commands.table,
                commands.checkedListCommand,
                commands.unorderedListCommand,
                commands.codePreview,
                commands.divider,
                commands.fullscreen,
                commands.codeEdit,
              ]}
              height={500}
              data-color-mode="light"
            />
          </Form.Item>

          <Form.Item
            name="code"
            className={styles.input}
            label="Code"
            rules={[
              {
                required: false,
                validator: async (_, value) => {
                  const regex = /\s/g;

                  if (value && regex.test(value)) {
                    return Promise.reject(
                      new Error("Code should not contain any space")
                    );
                  }
                },
              },
            ]}
          >
            <Input placeholder="Code" />
          </Form.Item>

          <Form.Item
            name="sequence"
            label="Sequence"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the sequence",
              },
            ]}
          >
            <InputNumber
              className="input"
              placeholder="1"
              style={{ height: "inherit", padding: 0 }}
            />
          </Form.Item>

          <Form.Item
            name="is_enable_mail_support"
            label="Enable mail support"
            rules={[
              {
                required: true,
                message: "Please enable or disable mail support",
              },
            ]}
          >
            <Select
              name="is_enable_mail_support"
              initialValues={statusOption[0]}
            >
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="is_enable_call_us"
            label="Enable call us"
            rules={[
              {
                required: true,
                message: "Please enable or disable call us",
              },
            ]}
          >
            <Select
              name="is_enable_mail_support"
              initialValues={statusOption[0]}
            >
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {type === "one" && (
            <Form.Item name="layer_two" label="Layer Two(s)" className="input">
              <Row justify="start" align="top">
                <Col span="18">
                  <Table
                    columns={columns}
                    dataSource={layerTwos}
                    style={{ width: "100%" }}
                    pagination={false}
                    expandable={{
                      expandedRowRender,
                    }}
                  />
                </Col>
                <Col span="6" style={{ paddingTop: 0, paddingLeft: 15 }}>
                  <div>
                    <Button
                      onClick={() =>
                        navigate(`/settings/faq-layer/add/two/${id}`)
                      }
                      type="secondary"
                    >
                      Add Layer Two
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          )}

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
          <br />
        </Form>
      </Card>
    </div>
  );
};

export default FaqLayerEdit;
