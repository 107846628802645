import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Image,
} from "antd";
// ============
import styles from "./styles.module.less";
import { findAllStatuses } from "../../store/app";
import { findReloadById, updateReloadById } from "../../store/reload";
import { find, map, filter } from "lodash";

const { Option } = Select;

const ReloadEdit = () => {
  const { params } = useMatch("reload/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [reloadInfo, setReloadInfo] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [allowEditing, setAllowEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Processing");
  const [filerUrl, setFilerUrl] = useState(null);
  const userId = useSelector((state) => state.app.user.id);
  const isLoading = useSelector((state) => state.reload.isLoading);

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve statuses
      try {
        const statusPayload = await dispatch(findAllStatuses()).unwrap();
        const { data: statusData } = statusPayload;
        const filteredStatusData = filter(statusData, (item) =>
          ["Processing", "Successful", "Unsuccessful"].includes(item.name)
        );
        setStatuses(filteredStatusData);

        const reloadPayload = await dispatch(
          findReloadById({
            id: params.id,
          })
        ).unwrap();

        const { data: reloadData } = reloadPayload;

        if (reloadData) {
          if (reloadData.status == 1 || reloadData.status == 2) {
            setReloadInfo(reloadData);

            if (reloadData.assigned_user_id) {
              let assignedUserId = reloadData.assigned_user_id;

              if (assignedUserId == userId) {
                setAllowEditing(true);
              }
            }

            formRef.current.setFieldsValue({
              from: reloadData.user_username, //without KYC
              requested_currency: reloadData.reload_currency_iso_code,
              source_bank: reloadData.bank_branch_name,
              source_account: reloadData.bank_account_name,
              amount: numeral(parseFloat(reloadData.amount)).format("0.00"),
              reference_no: reloadData.reference_no,
              status: reloadData.status,
              reject_reason: reloadData.reject_reason_text,
            });

            setFilerUrl(reloadData.file_url);
          } else {
            message.error("This reload record is not available for editing");
            navigate("/reload");
          }
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        await dispatch(
          updateReloadById({
            id: params.id,
            // bank_acc_id: values.source_account,
            // amount: values.amount,
            status: values.status,
            reject_reason: values.reject_reason ? values.reject_reason : null,
          })
        ).unwrap();

        message.success("You have successfully edited the reload info");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>RELOAD INFORMATION</div>
          {reloadInfo.assigned_user_fullname && (
            <Tag color="purple">
              Case is assigned to: {reloadInfo.assigned_user_fullname}
            </Tag>
          )}
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Row>
            <Col span={14}>
              <div className={styles.input}>
                <Form.Item
                  name="from"
                  label={<div className={styles.label}>From</div>}
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className={styles.input}>
                <Form.Item
                  name="requested_currency"
                  label={<div className={styles.label}>Requested Currency</div>}
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className={styles.input}>
                <Form.Item
                  name="source_bank"
                  label={<div className={styles.label}>Source Bank</div>}
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className={styles.input}>
                <Form.Item
                  name="source_account"
                  label={<div className={styles.label}>Source Account</div>}
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className={styles.input_number}>
                <Form.Item
                  name="amount"
                  label={<div className={styles.label}>Amount</div>}
                >
                  <InputNumber
                    style={{
                      width: "80%",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingRight: 10,
                      borderRadius: 6,
                      border: "1px solid #bfc9d4",
                      fontSize: 15,
                    }}
                    min="0"
                    step="0.01"
                    stringMode
                    disabled={true}
                  />
                </Form.Item>
              </div>
              <div className={styles.input}>
                <Form.Item
                  name="reference_no"
                  label={<div className={styles.label}>Receipt Reference</div>}
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className={styles.select_container}>
                <Form.Item
                  name="status"
                  label={<div className={styles.label}>Status</div>}
                >
                  <Select
                    style={{
                      width: "80%",
                      minWidth: 100,
                    }}
                    disabled={!allowEditing || isLoading}
                    onSelect={(_, option) => {
                      if (option) {
                        setSelectedStatus(option.children);
                      }
                    }}
                  >
                    {map(statuses, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              {selectedStatus == "Unsuccessful" && (
                <div className={styles.input}>
                  <Form.Item
                    name="reject_reason"
                    label={<div className={styles.label}>Reject Reason</div>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your reject reason",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please enter your reject reason"
                      disabled={!allowEditing || isLoading}
                    />
                  </Form.Item>
                </div>
              )}
            </Col>
            <Col span={10}>
              <Image
                style={{
                  height: 600,
                  border: "1px solid #dcdcdc",
                  borderRadius: "5px",
                }}
                src={filerUrl}
              />
            </Col>
          </Row>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                disabled={
                  !allowEditing || isLoading || selectedStatus === "Processing"
                }
                className={
                  allowEditing || !isLoading
                    ? "secondary_btn"
                    : "secondary_btn_disabled"
                }
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ReloadEdit;
