import { useState, useEffect, createContext } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { message } from "antd";

export const WebSocketContext = createContext(null);

export const useWebSocket = () => {
  const [socket, setSocket] = useState(null);

  const { socketToken, user } = useSelector((state) => state.app);

  useEffect(() => {
    const connectSocket = () => {
      const webSocket = io(process.env.REACT_APP_SOCKET_URL, {
        extraHeaders: {
          Authorization: "Bearer " + socketToken,
        },
        auth: {
          user: user.id,
        },
      });

      webSocket.on("connect", () => {
        setSocket(webSocket);
        message.info("Socket Connected");
      });

      webSocket.on("connect_error", (err) => {
        // message.error("Socket Error: " + err.message);
      });
    };
    if (!socket) {
      if (socketToken) {
        connectSocket();
      }
    }

    if (!socketToken) {
      if (socket) {
        socket.disconnect();
        setSocket(() => {
          message.info("Socket Ended");
          return null;
        });
      }
    }

    return () => {
      //   console.log("socket", socket);
      //   console.log("socketToken", socketToken);
      //   if (socket) {
      //     socket.disconnect();
      //     console.log("end");
      //     message.info("Socket Ended 2");
      //   }
    };
  }, [socketToken]);

  return socket;
};
