import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, Form, Input, Row, Select, Button, message } from "antd";
import { find, map } from "lodash";

// ============
import styles from "./styles.module.less";
import {
  getAllRoles,
  findUserById,
  updateDashboardUser,
} from "../../store/app";
import { getAllCountries } from "../../store/country";

const { Option } = Select;

const DashboardUserEdit = () => {
  const { params } = useMatch("dashboard-user/:id");
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();

  const roles = useSelector((state) => state.app.roles);

  const [selectedCountry, setSelectedCountry] = useState({
    id: null,
    name: "",
  });

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    name: "",
  });
  const [countries, setCountries] = useState([]);

  const updateProfile = async (values) => {
    try {
      const { data } = await dispatch(
        updateDashboardUser({
          fullname: values.fullname,
          email: values.email,
          phone_no: values.phone_no,
          nationality: values.nationality,
          role_id: selectedRole.id,
          dashboard_user_id: params.id,
        })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(getAllRoles());
    const fetchData = async () => {
      // Retrieve countries
      try {
        const countriesPayload = await dispatch(getAllCountries()).unwrap();
        const { data: countryData } = countriesPayload;
        setCountries(countryData);

        // Retrieve dashbord user

        const userPayload = await dispatch(
          findUserById({
            id: params.id,
          })
        ).unwrap();

        const { data: userData } = userPayload;

        // setSelectedCountry({
        //   id: userData.country.id,
        //   name: userData.country.name,
        // });
        // setSelectedRole({ id: userData.role_id, name: userData.role });

        if (userData) {
          formRef.current.setFieldsValue({
            role: userData.role,
            role_id: userData.role_id,
            username: userData.username,
            fullname: userData.fullname,
            email: userData.email,
            phone_no: userData.phone_no,
            // country: userData.country.name,
            nationality: userData.nationality_id,
            created_at: moment(userData.created_at).format(
              "YYYY-MM-DD, hh:mm:ss A"
            ),
          });
        }
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  const countriesOnChange = (value) => {
    const selectCountry = find(countries, (item) => item.id === value);

    if (selectCountry) {
      setSelectedCountry({ id: selectCountry.id, name: selectCountry.name });
    }
  };

  const userRoleOnChange = (value) => {
    const selectRole = find(roles, (item) => item.id === value);

    if (selectRole) {
      setSelectedRole({ id: selectRole.id, name: selectRole.name });
    }
  };

  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>DASHBOARD USER INFORMATION</div>
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          requiredMark={false}
          onFinish={(values) => {
            updateProfile(values);
          }}
        >
          <div className={styles.select_container}>
            <Form.Item
              name="role_id"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Role cannot be empty",
                },
              ]}
            >
              <Select
                name="role_id"
                // value={selectedRole.id}
                onChange={userRoleOnChange}
              >
                {map(roles, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item name="username" label="Username">
              <Input disabled />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="fullname"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Fullname cannot be empty",
                },
              ]}
            >
              <Input placeholder="Fullname" />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email cannot be blank",
                },
                {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </div>

          <div className={styles.input}>
            <Form.Item
              name="phone_no"
              label="Phone No"
              rules={[
                {
                  required: true,
                  message: "Phone number cannot be empty",
                },
              ]}
            >
              <Input placeholder="Phone No" />
            </Form.Item>
          </div>
          <div className={styles.select_container}>
            <Form.Item
              name="nationality"
              label="Nationality"
              rules={[
                {
                  required: true,
                  message: "Nationality cannot be empty",
                },
              ]}
            >
              <Select
                name="country"
                // value={selectedCountry.id}
                onChange={countriesOnChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(countries, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item name="created_at" label="Created At">
              <Input disabled />
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={goBack} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default DashboardUserEdit;
