import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { useAbility } from "@casl/react";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Select,
  InputNumber,
  Upload,
  Modal,
  Image
} from "antd";
// ============
import styles from "./styles.module.less";
import {
  createBanner,
  removeMediaById,
  createBannerMedia,
  findBannerById,
  updateBanner
} from "@store/app";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { find, map, isEmpty, first, filter } from "lodash";
import { AbilityContext, Can } from "@configs/can";
import HistoryTable from "../../../components/history-table";
const { Option } = Select;
const { Dragger } = Upload;

const BannerEdit = () => {
  const { params } = useMatch("settings/banner/:id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const [typeSelected, setTypeSelected] = useState(null);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [mediaId, setMediaId] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const statusOption = [
    { value: 0, label: "Disabled" },
    { value: 1, label: "Enabled" }
  ];
  const bannerTypeOption = [
    { index: 1, value: "navigate", label: "Navigate" },
    { index: 2, value: "website", label: "Website" },
    { index: 3, value: "static", label: "Static" }
  ];

  // const minDimensions = {width: 600, height: 240};
  // const maxDimensions = {width: 1000, height: 400};

  const dimensions = { min: 240, max: 1000 };

  const formRef = useRef();

  useEffect(() => {
    if (ability.cannot("manage", "Setting")) {
      navigate("/error");
    }

    const fetchData = async () => {
      try {
        // Retrieve banner
        const bannerPayload = await dispatch(
          findBannerById({
            id: params.id
          })
        ).unwrap();

        const { data: bannerData } = bannerPayload;

        if (bannerData) {
          formRef.current.setFieldsValue({
            name: bannerData.name,
            type: bannerData.type,
            navigation_stack: bannerData.navigation_stack,
            navigation_screen: bannerData.navigation_screen,
            website: bannerData.website,
            sequence: bannerData.sequence,
            is_enabled: bannerData.is_enabled
          });

          setTypeSelected(bannerData.type);
          setMediaId(bannerData.media_id);

          let newFileList = [
            {
              uid: bannerData.id,
              name: bannerData.filename,
              status: "done",
              url: bannerData.file_url
            }
          ];

          setFileList(newFileList);
          formRef.current.setFieldValue("banner", newFileList);
        }

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "banner"
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  const customRequest = async ({ file, onSuccess }) => {
    try {
      let { data } = await dispatch(
        createBannerMedia({
          file
        })
      ).unwrap();

      setMediaId(data[0]);

      onSuccess("Image successfully uploaded");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const bannerOnPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const bannerOnChange = ({ fileList: newFileList }) => {
    const media = first(newFileList);

    setFileList(newFileList);
    formRef.current.setFieldValue("banner", newFileList);
    formRef.current.validateFields();

    if (media) {
      const { response, status } = media;
      if (status === "done") {
        message.success(response);
      }
    }
  };

  const bannerOnRemove = async (file) => {
    await dispatch(
      removeMediaById({
        id: mediaId
      })
    ).unwrap();
    formRef.current.setFieldValue("banner", undefined);

    message.success("Successfully deleted image");

    setMediaId(null);
  };

  const bannerBeforeUpload = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = () => {
          if (
            img.naturalWidth <= dimensions.max &&
            img.naturalHeight >= dimensions.min &&
            img.naturalWidth / img.naturalHeight === 2.5
          ) {
            const canvas = document.createElement("canvas");
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((result) => resolve(result));
          } else {
            message.error("Banner dimensions invalid");
          }
        };
      };
    });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (values) => {
    if (values) {
      if (mediaId == null) {
        message.error("Banner has not been set");
      } else {
        try {
          await dispatch(
            updateBanner({
              values,
              mediaId,
              bannerId: params.id
            })
          ).unwrap();

          message.success("You have successfully updated banner");
          navigate(-1);
        } catch (error) {
          message.error(error.message);
        }
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Edit Banner</div>
        </Row>
        <Form
          colon={false}
          ref={formRef}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
          labelWrap
        >
          <Form.Item
            name="name"
            className="input"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter the banner name"
              }
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: "Please enter the banner type"
              }
            ]}
          >
            <Select
              name="type"
              placeholder="Type"
              onSelect={(value) => {
                setTypeSelected(value);
              }}
            >
              {map(bannerTypeOption, (item) => {
                return (
                  <Option key={item.index} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {typeSelected === "navigate" && (
            <Form.Item
              name="navigation_stack"
              className="input"
              label="Navigation Stack"
              rules={[
                {
                  required: true,
                  message: "Please enter the navigation stack"
                }
              ]}
            >
              <Input placeholder="Navigation Stack" />
            </Form.Item>
          )}
          {typeSelected === "navigate" && (
            <Form.Item
              name="navigation_screen"
              className="input"
              label="Navigation Screen"
              rules={[
                {
                  required: true,
                  message: "Please enter the navigation screen"
                }
              ]}
            >
              <Input placeholder="Navigation Screen" />
            </Form.Item>
          )}

          {typeSelected === "website" && (
            <Form.Item
              name="website"
              className="input"
              label="Website URL"
              rules={[
                {
                  required: true,
                  message: "Please enter the website URL"
                }
              ]}
            >
              <Input placeholder="Website URL" />
            </Form.Item>
          )}

          <Form.Item
            name="sequence"
            label="Sequence"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the sequence"
              }
            ]}
          >
            <InputNumber
              className="input"
              placeholder="1"
              style={{ height: "inherit", padding: 0 }}
            />
          </Form.Item>

          <Form.Item name="is_enabled" label="Status">
            <Select name="is_enabled" initialValues={statusOption[0]}>
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="banner"
            label={
              <div style={{ marginRight: 20 }}>
                <Col>
                  <Row justify="end">Banner</Row>
                  <Row justify="end">(Aspect Ratio : 5:2)</Row>
                </Col>
              </div>
            }
            className="two-rows-banner-label"
            rules={[
              {
                required: true,
                message: "Please upload the banner"
              }
            ]}
          >
            <Dragger
              customRequest={customRequest}
              fileList={fileList}
              onPreview={bannerOnPreview}
              onChange={bannerOnChange}
              onRemove={bannerOnRemove}
              beforeUpload={bannerBeforeUpload}
              listType="picture"
              multiple={false}
              maxCount={1}
              withCredentials
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined className={styles.inbox_icon} />
              </p>
              <p className={`ant-upload-text ${styles.inbox_description}`}>
                Click or drag file to this area to upload
              </p>
            </Dragger>
            <Modal
              visible={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%"
                }}
                src={previewImage}
              />
            </Modal>
            <div className={styles.dimension_text}>
              (Min size: 600 x 240px, Max size: 1000 x 400px)
            </div>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default BannerEdit;
