import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Row, Select, Button, message } from "antd";
import { map } from "lodash";
import { useMatch, useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { updateCountryById } from "../../store/country";
import { retrieveAllCurrencies } from "../../store/currency";

const CountryEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params } = useMatch("country/:id");
  const { Option } = Select;
  const selectedCountry = useSelector((state) => state.country.selectedCountry);
  const currencies = useSelector((state) => state.currency.currencies);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(retrieveAllCurrencies()).unwrap();
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, [params]);

  const updateSelectedCountry = async (values) => {
    try {
      await dispatch(
        updateCountryById({
          id: params.id,
          currency_ids: values.currencies,
        })
      ).unwrap();
      message.success("You have update the country information");
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>COUNTRY INFORMATION</div>
        </Row>
        <br />
        <Form
          layout="vertical"
          onFinish={updateSelectedCountry}
          initialValues={{
            currencies: map(selectedCountry.currency, (item) => item.id),
          }}
          requiredMark={false}
        >
          <Row justify="space-between" align="middle">
            <div>
              <div className={styles.label}>Country: </div>
              {selectedCountry.name} ({selectedCountry.iso_code_2})
            </div>
          </Row>
          <br />
          <div className={styles.select_container}>
            <Form.Item
              name="currencies"
              label={<div className={styles.label}>Currencies</div>}
              rules={[
                {
                  required: true,
                  message: "Please select the currencies",
                },
              ]}
            >
              <Select
                mode="multiple"
                showArrow
                placeholder="Please select the available currencies"
              >
                {map(currencies, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.currency_iso}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CountryEdit;
