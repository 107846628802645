import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Divider,
  Modal,
  message,
  Input,
  InputNumber,
  Space,
  Form,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
// ============
import styles from "./styles.module.less";
import {
  enableForceLogin,
  retrieveSettings,
  updateSettingByKey,
} from "@store/app";

const GeneralSetting = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const setting = useSelector((state) => {
    if (!isEmpty(state.app.settings)) {
      return state.app.settings;
    } else {
      return {};
    }
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [modalWithInputVisible, setModalWithInputVisible] = useState(false);
  const [selectedSettingItem, setSelectedSettingItem] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await dispatch(retrieveSettings()).unwrap();
    } catch (e) {
      if (e.hasOwnProperty("message")) {
        message.error(e.message.toString());
      } else {
        message.error(e.toString());
      }
    }
  };

  const onEnableForceLogin = async (event) => {
    if (event) {
      try {
        const { data } = await dispatch(enableForceLogin()).unwrap();
        message.success(data.message);
        setModalVisible(false);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const onSubmitForm = async (value) => {
    if (value) {
      try {
        let key = selectedSettingItem.key;

        const { data } = await dispatch(
          updateSettingByKey({ key, value: value[key] })
        ).unwrap();
        message.success(data.message);
      } catch (e) {
        if (e.hasOwnProperty("message")) {
          message.error(e.message.toString());
        } else {
          message.error(e.toString());
        }
      }

      setModalWithInputVisible(false);
      fetchData();
    }
  };

  const onClickCancel = (event, type) => {
    if (event) {
      if (type == "force-login-modal") {
        setModalVisible(false);
      } else if (type == "input-modal") {
        setModalWithInputVisible(false);
      }
    }
  };

  const onClickSettingItem = (item) => {
    let formInitialValue = {};

    formInitialValue[item.key] = item.value;

    setSelectedSettingItem(item);
    form.setFieldValue(formInitialValue);
    setModalWithInputVisible(true);
  };

  return (
    <>
      <div className={styles.card_container}>
        <Row
          align="middle"
          justify="space-between"
          className={styles.item_container}
        >
          <Col>Enable force login for all users</Col>
          <Col>
            <Button
              className="secondary_btn"
              onClick={() => setModalVisible(true)}
            >
              Enable Force Login
            </Button>
          </Col>
        </Row>
        <Divider />
        <div className={styles.section_title}>CURRENCY</div>
        <Row
          align="middle"
          justify="space-between"
          className={styles.item_container}
        >
          <Col>Currency Processing Fee (Percentage %)</Col>
          <Col>
            <Space>
              <div className={styles.input}>
                <Input value={setting?.processing_fee?.value} disabled />
              </div>

              <Button
                className="secondary_btn"
                onClick={() =>
                  onClickSettingItem({
                    ...setting?.processing_fee,
                    label: "Processing Fee",
                    placeholder: "Enter new processing fee (%)",
                  })
                }
              >
                Change
              </Button>
            </Space>
          </Col>
        </Row>
      </div>

      {/* Handle dynamic form item input based on type */}
      <Modal
        className={styles.modal_container}
        title="Edit"
        bodyStyle={{
          height: 120,
          alignItems: "center",
        }}
        width={320}
        visible={modalWithInputVisible}
        closable={false}
        okText="Submit"
        onOk={form.submit}
        onCancel={(event) => onClickCancel(event, "input-modal")}
        cancelButtonProps={{
          style: { display: "inline-block", width: "30%" },
          className: "default_btn",
        }}
        okButtonProps={{
          style: { display: "inline-block", width: "30%" },
          className: "secondary_btn",
        }}
      >
        <Form layout="vertical" form={form} onFinish={onSubmitForm}>
          {selectedSettingItem && selectedSettingItem.type == "number" && (
            <Form.Item
              label={selectedSettingItem.label}
              name={selectedSettingItem.key}
              rules={[
                {
                  required: true,
                  message: "This field cannot be blank",
                },
                {
                  pattern: /\d+/g,
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <div className={styles.input}>
                <InputNumber
                  placeholder={selectedSettingItem.placeholder}
                  min={1}
                  max={100}
                  defaultValue={selectedSettingItem.value}
                />
              </div>
            </Form.Item>
          )}
        </Form>
      </Modal>

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
        }}
        visible={modalVisible}
        closable={false}
        onOk={onEnableForceLogin}
        onCancel={(event) => onClickCancel(event, "force-login-modal")}
        cancelButtonProps={{
          style: { display: "inline-block", width: "30%" },
          className: "default_btn",
        }}
        okButtonProps={{
          style: { display: "inline-block", width: "30%" },
          className: "secondary_btn",
        }}
      >
        <strong>
          Are you sure you want to enable force login for all dashboard users?
        </strong>
      </Modal>
    </>
  );
};

export default GeneralSetting;
