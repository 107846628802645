import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findAllStatuses, assignCaseToUser } from "@store/app";
import {
  findAllDeleteMemberRequests,
  updateDeleteMemberRequest
} from "@store/delete-member-request";
import TableHeader from "@components/table-header";
import ConfirmationPopUp from "@components/confirmation-popup";
import { map, values } from "lodash";

const DeleteMemberRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const formRef = useRef();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const [filter, setFilter] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        findAllDeleteMemberRequests({ filter })
      ).unwrap();
      const { data: userData, total } = data;
      setTableData(userData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    initialize();
    fetchData();
  }, []);

  const initialize = async () => {
    try {
      const statusPayload = await dispatch(findAllStatuses()).unwrap();
      const { data: statusData } = statusPayload;

      const filteredStatusData = statusData.filter((item) =>
        ["Unsuccessful", "Resolved"].includes(item.name)
      );

      setStatuses(filteredStatusData);
    } catch (error) {
      message.error(error.message);
    }
  };

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const editButtonOnClick = (data) => {
    console.log("data-----------");
    console.log(data);

    setSelectedRequest(data);
    setShowEditStatusModal(true);
  };

  const onCancelModal = () => {
    form.resetFields();
    setShowEditStatusModal(false);
  };

  // const updateStatus = async ({ status, notes }) => {
  //   // try {
  //   //   await dispatch(
  //   //     updateDeleteUserRequest({
  //   //       // id: params.id,
  //   //       status,
  //   //       notes: notes ?? null,
  //   //     })
  //   //   ).unwrap();
  //   //   return true;
  //   // } catch (error) {
  //   //   message.error(error.message);
  //   // }
  // };

  const onSaveSelectedStatus = async (values) => {
    try {
      const data = await dispatch(
        updateDeleteMemberRequest({
          id: selectedRequest.id,
          status: values.status,
          notes: values.notes
        })
      ).unwrap();

      if (data) {
        setShowEditStatusModal(false);
        message.success("You have successfully updated");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 110,
      render: (_, record) => {
        const { id } = record;
        return <div style={{ textAlign: "left" }}>{id}</div>;
      }
    },
    {
      title: "Validity",
      dataIndex: "is_valid",
      align: "left",
      width: 110,

      // render: (_, record) => {
      //   const { is_valid } = record;
      //   return <div style={{ textAlign: "left" }}>{is_valid ? "true" : "false"}</div>;
      // },
      render: (_, record) => {
        let is_valid;
        let color;

        if (record.is_valid === 0) {
          is_valid = "Invalid";
          color = "#FF0000";
        } else if (record.is_valid === 1) {
          is_valid = "Valid";
          color = "#95de64";
        }

        return <div style={{ color }}>{is_valid}</div>;
      }
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      width: 110,
      render: (_, record) => {
        const { username } = record;
        return <div style={{ textAlign: "left" }}>{username}</div>;
      }
    },
    {
      title: "Topkash ID",
      dataIndex: "topkash_id",
      align: "left",
      width: 110,
      render: (_, record) => {
        const { topkash_id } = record;
        return <div style={{ textAlign: "left" }}>{topkash_id}</div>;
      }
    },
    {
      title: "Phone No",
      dataIndex: "phone_no",
      align: "left",
      width: 110,
      render: (_, record) => {
        const { phone_no } = record;
        return <div style={{ textAlign: "left" }}>{phone_no}</div>;
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      width: 110,
      render: (_, record) => {
        const { email } = record;
        return <div style={{ textAlign: "left" }}>{email}</div>;
      }
    },
    {
      title: "Reason to Delete",
      dataIndex: "reason",
      align: "left",
      width: 220,
      render: (_, record) => {
        const { reason } = record;
        return <div style={{ textAlign: "left" }}>{reason}</div>;
      }
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_user_username",
      align: "left",
      width: 110,
      render: (_, record) => {
        const { assigned_user_username } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {assigned_user_username ? assigned_user_username : "-"}
          </div>
        );
      }
    },
    {
      title: "Notes",
      dataIndex: "notes",
      align: "left",
      width: 220,
      render: (_, record) => {
        const { notes } = record;
        return <div style={{ textAlign: "left" }}>{notes ? notes : "-"}</div>;
      }
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "left",
      // fixed: "right",
      width: 200,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment
              .utc(created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        ); // 16:23
        // return <div>{moment(created_at).format("DD-MM-YYYY, hh:mm:ss A")}</div>; // 12:23
      }
    },
    {
      title: "Status",
      align: "center",
      width: 110,
      fixed: "right",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status;
        let color;

        if (record.status === 7) {
          status = "Resolved";
          color = "#95de64";
        } else if (record.status === 5) {
          status = "Unsuccessful";
          color = "#FF0000";
        } else {
          status = "Pending";
          color = "#ff8000";
        }

        return <div style={{ color }}>{status}</div>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 110,
      fixed: "right",
      render: (_, record) => {
        const { status } = record;

        let ctaText;
        let isDisabled;

        if (status === 7 || status === 5) {
          isDisabled = true;
          ctaText = "Edit";
        } else {
          isDisabled = false;
          ctaText = "Edit";
        }

        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editButtonOnClick(record)}
                disabled={isDisabled}
              >
                {ctaText}
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Delete Member Requests"
        showCreateButton={false}
        // createButtonText={"Create New Request"}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        // createOnClick={() => navigate("/settings/delete-member-request/add")}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
          // dispatch(resetTablePagination());
          // dispatch(resetTableFilter());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>

          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />

      {/* Status Changing Modal */}

      <ConfirmationPopUp
        visible={showEditStatusModal}
        title="Delete Member Request Status"
        handleOnOk={() => formRef.current.submit()}
        handleOnCancel={onCancelModal}
        modalFormOnFinish={(values) => {
          console.log(values);
        }}
      >
        <Form
          ref={formRef}
          form={form}
          style={{ width: "100%" }}
          onFinish={onSaveSelectedStatus}
          labelCol={{
            span: 9
          }}
          wrapperCol={{
            span: 15
          }}
          className="modal-form"
          labelAlign="left"
        >
          <Form.Item
            name="status"
            label="Status"
            style={{ textAlign: "left" }}
            className="label-details"
            rules={[
              {
                required: true,
                message: "Please update the status"
              }
            ]}
          >
            <Select
              placeholder="Status"
              onSelect={(value) => {
                setSelectedStatus(value);
              }}
            >
              {map(statuses, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {selectedStatus === 5 ? (
            <Form.Item
              name="notes"
              label="Note"
              style={{ textAlign: "left" }}
              rules={[
                {
                  required: true,
                  message: "Please fill the reason of rejection"
                }
              ]}
            >
              <TextArea
                // rows={5}
                placeholder="Reason"
                autoSize={{
                  minRows: 3,
                  maxRows: 5
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item name="notes" label="Note" style={{ textAlign: "left" }}>
              <TextArea
                rows={5}
                placeholder="Reason"
                autoSize={{
                  minRows: 3,
                  maxRows: 5
                }}
              />
            </Form.Item>
          )}
        </Form>
      </ConfirmationPopUp>
    </div>
  );
};

export default DeleteMemberRequest;
