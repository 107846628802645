import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";
import { isEmpty } from "lodash";

const initialState = {};

export const retrieveExchangeRates = createAsyncThunk(
  "exchange/retrieveExchangeRates",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("exchange-rates", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveExchangeRatesList = createAsyncThunk(
  "exchange/retrieveExchangeRates",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder(
        "exchange-rates/list",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveExchangeRateById = createAsyncThunk(
  "exchange/retrieveExchangeRateById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`exchange-rates/${id}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createExchangeRate = createAsyncThunk(
  "exchange/createExchangeRate",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("from_currency_id", values.from_currency);
      formData.append("to_currency_id", values.to_currency);
      formData.append("rate", values.rate);
      formData.append("duration", values.duration);

      const { data } = await axios.post("exchange-rates/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const addExchangeRateInBulk = createAsyncThunk(
  "exchange/addExchangeRateInBulk",
  async (values) => {
    try {
      let payload = {
        exchange_rates: values
      };
      const { data } = await axios.post("exchange-rates/add/bulk", payload, {
        headers: {
          Accept: "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const removeExchangeRateById = createAsyncThunk(
  "exchange/removeExchangeRateById",
  async ({ id }) => {
    try {
      const { data } = await axios.delete(`exchange-rates/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateExchangeRateById = createAsyncThunk(
  "exchange/updateExchangeRateById",
  async ({ id, values }) => {
    try {
      let payload = {
        from_currency_id: values.from_currency,
        to_currency_id: values.to_currency,
        rate: values.rate,
        date: values.date
      };

      const { data } = await axios.put(`exchange-rates/${id}`, payload, {
        headers: {
          Accept: "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateExchangeRatesByAPI = createAsyncThunk(
  "exchange/updateExchangeRatesByAPI",
  async (values) => {
    try {
      let payload = {
        date: values
      };

      const { data } = await axios.patch("exchange-rates/api", payload, {
        headers: {
          Accept: "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getExchangeCases = createAsyncThunk(
  "exchange/getExchangeCases",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("cases/exchange", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllExchangeMarginRate = createAsyncThunk(
  "exchange/findAllExchangeMarginRate",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "exchange-rates/margin",
        getState().ui.table,
        filter
      );
      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createExchangeMarginRate = createAsyncThunk(
  "exchange/createExchangeMarginRate",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("rate", parseFloat(values.margin));
      formData.append("type", values.type);

      if (values.from_currency_id || values.to_currency_id) {
        formData.append("from_currency_id", values.from_currency_id);
        formData.append("to_currency_id", values.to_currency_id);
      }

      console.log(values);
      const { data } = await axios.post("exchange-rates/margin/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllExchangeRateSettings = createAsyncThunk(
  "exchange/findAllExchangeRateSettings",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "exchange-rates/setting",
        getState().ui.table,
        filter
      );
      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findExchangeRateSettingById = createAsyncThunk(
  "exchange/findExchangeRateSettingById",
  async ({ id }) => {
    try {

      const { data } = await axios.get(`exchange-rates/setting/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateExchangeRateSettingById = createAsyncThunk(
  "exchange/updateExchangeRateSettingById",
  async ({ id, status, duration }) => {
    try {

      let payload = {
        status,
        duration
      }

      const { data } = await axios.patch(`exchange-rates/setting/${id}`, payload);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    resetExchange: () => {
      return {
        ...initialState
      };
    }
  }
});

export const { resetExchange } = exchangeSlice.actions;

export default exchangeSlice.reducer;
