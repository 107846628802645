import { initializeApp } from "firebase/app";
// import { getMessaging } from "firebase/messaging/sw";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { map } from "lodash";
import { authRoutes, routes } from "./configs/routes";
import Favicon from "react-favicon";
// ============
import "./App.less";
import CustomLayout from "./components/layout";
import audio from "./assets/audios/notification.mp3";
import {
  loginStatus,
  saveFcmDetails,
  setIsLoggedIn,
  setIsNewUser,
  setIsSessionExpiredModalVisible
} from "@store/app";

import {
  markMessageAsRead,
  setModalContent,
  resetModalContent,
  retrieveNotifications
} from "@store/notification";
import { notification, Modal, Row, message } from "antd";
import { AbilityContext } from "@configs/can";
import { setAbility } from "@configs/ability";
import { useWebSocket, WebSocketContext } from "@configs";
import { retrievePendingTransactions } from "@store/transaction";
import { resetNotificationPage } from "./store/ui";
import { resetNotification } from "./store/notification";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useWebSocket();

  const audioRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [counter, setCounter] = useState(5);
  const notifications = useSelector((state) => state.notification.notification);
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn);
  const isNewUser = useSelector((state) => state.app.isNewUser);
  const { permissions } = useSelector((state) => state.app.user);
  const location = useLocation();

  const isSessionExpiredModalVisible = useSelector(
    (state) => state.app.isSessionExpiredModalVisible
  );

  const totalCount = useSelector((state) => {
    if (
      state.transaction.pendingCount?.reloadBankCount +
        state.transaction.pendingCount?.transferCount >
      99
    ) {
      return "99+";
    }

    return (
      state.transaction.pendingCount?.reloadBankCount +
      state.transaction.pendingCount?.transferCount
    );
  });

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        await dispatch(loginStatus()).unwrap();
      } catch (error) {
        // message.error(error.message);
        console.log(error.message);
      }
    };

    checkLoginStatus();
  }, []);

  // Hide session expired modal for current stage
  // useEffect(() => {
  //   counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  // }, [counter]);

  useEffect(() => {
    let checkIsResetPassword = false;
    let checkIsFirstLogin = false;

    if (window.location.href.match(/\/reset-password/gi)) {
      checkIsResetPassword = true;
    }

    if (window.location.href.match(/\/first-login/gi)) {
      checkIsFirstLogin = true;
    }

    if (!isLoggedIn && !checkIsResetPassword && !checkIsFirstLogin) {
      navigate("/signin");
    }
  }, [isLoggedIn]);

  const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    const getRegistrationToken = async () => {
      try {
        const firebaseConfig = {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
          appId: process.env.REACT_APP_FIREBASE_APP_ID
        };
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);

        // If registration for service worker success,
        // get web push notification permission from user
        // if (registration) {
        if (!location.pathname.startsWith("/signin")) {
          const permission = await Notification.requestPermission();
          // If permission granted, get token

          if (permission === "granted") {
            const token = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
            });

            await dispatch(
              saveFcmDetails({
                fcm_token: token
              })
            );
          }

          onMessage(messaging, async function (payload) {
            console.log(
              "[firebase-messaging.js] Received foreground message ",
              payload
            );

            await dispatch(retrievePendingTransactions()).unwrap();

            dispatch(resetNotificationPage());
            dispatch(resetNotification());

            await dispatch(retrieveNotifications()).unwrap();

            notification.info({
              message: payload.notification.title,
              description: payload.notification.body,
              onClick: async () => {
                dispatch(
                  setModalContent({
                    title: payload.notification.title,
                    content: payload.notification.body
                  })
                );

                setIsModalVisible(true);
                dispatch(markMessageAsRead({ id: payload.data.id }));
                notification.destroy();
              }
            });
            audioRef.current.play();
          });
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    if (isLoggedIn) {
      getRegistrationToken();
    }
  }, [isLoggedIn, location]);

  const redirectNow = () => {
    dispatch(setIsSessionExpiredModalVisible(false));
    dispatch(setIsLoggedIn(false));
    window.location("/signin");
  };
  return (
    <WebSocketContext.Provider value={socket}>
      <div>
        <Favicon
          alertCount={totalCount}
          alertFillColor="#ff8000"
          url={require("@assets/images/topkash-favicon.ico")}
        />
        {isLoggedIn ? (
          <>
            <AbilityContext.Provider value={setAbility(permissions)}>
              <CustomLayout>
                <Routes>
                  {map(routes, (item, index) => (
                    <Route
                      key={index}
                      path={item.path}
                      element={item.element}
                    />
                  ))}
                </Routes>
              </CustomLayout>
            </AbilityContext.Provider>
            <audio ref={audioRef} src={audio} />
          </>
        ) : (
          <Routes>
            {map(authRoutes, (item, index) => (
              <Route key={index} path={item.path} element={item.element} />
            ))}
          </Routes>
        )}
        <Modal
          className="modal_container"
          bodyStyle={{
            paddingTop: 24,
            paddingBottom: 12,
            textAlign: "center"
          }}
          visible={isModalVisible}
          closable={false}
          onOk={() => {
            dispatch(resetModalContent());
            setIsModalVisible(false);
          }}
          okText="Close"
          cancelButtonProps={{
            style: { display: "none" }
          }}
          okButtonProps={{
            style: { display: "inline-block" }
          }}
        >
          <Row align="center">{notifications.title}</Row>
          <Row align="center">{notifications.content}</Row>
        </Modal>

        {/* Hide session expired modal for current stage */}
        {/* <Modal
          className="modal_container"
          bodyStyle={{
            paddingTop: 24,
            paddingBottom: 12,
            textAlign: "center",
          }}
          visible={isSessionExpiredModalVisible && isLoggedIn}
          closable={false}
          okText="Redirect Now"
          onOk={redirectNow}
          cancelButtonProps={{
            style: { display: "none" },
          }}
          okButtonProps={{
            style: { display: "inline-block" },
          }}
        >
          <Row
            style={{ fontWeight: 700, fontSize: 16, marginBottom: 18 }}
            align="center"
          >
            Session Expired, please sign in again.
          </Row>
          <Row align="center">
            You will be redirected to login page within {counter} seconds
          </Row>
          <Row align="center"></Row>
        </Modal> */}
      </div>
    </WebSocketContext.Provider>
  );
}

export default App;
