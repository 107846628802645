import axios from "axios";
import { setIsLoggedIn, setIsSessionExpiredModalVisible } from "../store/app";
import { store } from "@store";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/`;
// const apiUrl = `http://54.169.231.89/api/`;

const instance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    let headers = { ...config.headers };

    // headers["Authorization"] =
    //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdCIsInN1YiI6NH0.2ZftlV0kkyUIr4FXbRzJteoS4O3dMpe8l22QII1OO7Y";

    // Do something before request is sent
    return {
      ...config,
      headers,
    };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      store.dispatch(setIsSessionExpiredModalVisible(true));

      setTimeout(() => {
        store.dispatch(setIsSessionExpiredModalVisible(false));
        store.dispatch(setIsLoggedIn(false));
        window.location("/signin");
      }, 1);
    }

    return Promise.reject(error);
  }
);

export default instance;
