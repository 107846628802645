import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";

const initialState = {
  currencies: []
};

export const retrieveCurrencies = createAsyncThunk(
  "currency/retrieveCurrencies",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("currencies", getState().ui.table);

      const { data } = await axios.get(url);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveAllCurrencies = createAsyncThunk(
  "currency/retrieveAllCurrencies",
  async (_) => {
    try {
      const { data } = await axios.get("currencies?type=all");
      // const { data } = await axios.get("currencies");

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllCurrencies = createAsyncThunk(
  "currency/findAllCurrencies",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("currencies/all", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const addCurrency = createAsyncThunk(
  "currency/addCurrency",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("name", values.currency_name.trim());
      formData.append("code", values.iso_code);
      formData.append("minimum_amount", values.minimum_amount);
      formData.append(
        "unverified_maximum_amount",
        values.unverified_maximum_amount
      );
      formData.append("type", values.type);
      formData.append("flag", values.flag);

      // formData.append("locale", values.locale);

      const { data } = await axios.post("currencies/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateCurrency = createAsyncThunk(
  "currency/updateCurrency",
  async (values) => {
    try {
      let payload = {
        name: values.currency_name.trim(),
        type: values.type,
        // code: values.iso_code,
        minimum_amount: values.minimum_amount,
        unverified_maximum_amount: values.unverified_maximum_amount
      };

      const { data } = await axios.put(`currencies/${values.id}`, payload, {
        headers: {
          Accept: "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const deleteCurrency = createAsyncThunk(
  "currency/deleteCurrency",
  async ({ id }) => {
    try {
      const { data } = await axios.delete(`currencies/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCurrencyByCountryId = createAsyncThunk(
  "currency/findCurrencyByCountryId",
  async ({ countries_id }) => {
    try {
      const { data } = await axios.get(
        `currencies/currencies-country?country_id=${countries_id}`
      );

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getCurrencyById = createAsyncThunk(
  "currency/getCurrencyById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`currencies/${id}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findCurrencyFilterOptionsByType = createAsyncThunk(
  "currency/findCurrencyFilterOptionsByType",
  async ({ type }) => {
    try {
      const { data } = await axios.get(`currencies/filter/${type}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const currencySlice = createSlice({
  name: "currrency",
  initialState,
  reducers: {
    resetCurrency: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveAllCurrencies.fulfilled, (state, { payload }) => {
      state.currencies = payload.data;
    });
  }
});
export const { resetCurrency } = currencySlice.actions;

export default currencySlice.reducer;
