import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { paramCase } from "change-case";
import {
  Card,
  Form,
  Input,
  Row,
  Button,
  message,
  InputNumber,
  Tooltip,
  Space,
  Table,
  Col,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { map } from "lodash";
// ============
import styles from "./styles.module.less";
import MDEditor from "@uiw/react-md-editor";
import { findFaqCategoryById, updateFaqCategoryById } from "../../../store/faq";

const FaqCategoryEdit = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [faqLayers, setFaqLayers] = useState([]);

  const { params } = useMatch("settings/faq-category/:id");

  const columns = [
    {
      title: <div className={styles.label}>ID</div>,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "left",
      width: 80,
    },
    {
      title: <div className={styles.label}>Title</div>,
      dataIndex: "title",
      key: "title",
      align: "left",
    },
    {
      title: <div className={styles.label}>Slug</div>,
      dataIndex: "slug",
      key: "slug",
      align: "left",
    },
    {
      title: <div className={styles.label}>Code</div>,
      dataIndex: "code",
      key: "code",
      align: "left",
      render: (_, record) => {
        return <div>{record.code ? record.code : "-"}</div>;
      },
    },
    {
      title: <div className={styles.label}>Sequence</div>,
      dataIndex: "sequence",
      key: "sequence",
      align: "left",
    },

    {
      title: <div className={styles.label}>Mail Support</div>,
      dataIndex: "is_enable_mail_support",
      key: "is_enable_mail_support",
      align: "left",
      render: (_, record) => {
        let statusText;
        let color;

        if (record.is_enable_mail_support) {
          statusText = "Enabled";
          color = "#95de64";
        } else {
          statusText = "Disabled";
          color = "#FF0000";
        }

        return <div style={{ color }}>{statusText}</div>;
      },
    },

    {
      title: <div className={styles.label}>Call Us</div>,
      dataIndex: "is_enable_call_us",
      key: "is_enable_call_us",
      align: "left",
      render: (_, record) => {
        let statusText;
        let color;

        if (record.is_enable_call_us) {
          statusText = "Enabled";
          color = "#95de64";
        } else {
          statusText = "Disabled";
          color = "#FF0000";
        }

        return <div style={{ color }}>{statusText}</div>;
      },
    },

    {
      title: <div className={styles.label}>Action</div>,
      key: "action",
      align: "center",
      render: (_, record) => {
        const { id } = record;
        return (
          <>
            <div>
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => navigate(`/settings/faq-layer/one/${id}`)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Space>
            </div>
          </>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const { content } = record;

    return (
      <div>
        <div
          style={{
            backgroundColor: "#fafafa",
            paddingBottom: 10,
          }}
        >
          Content
        </div>

        {content ? (
          <MDEditor.Markdown
            source={content}
            style={{
              borderRadius: 10,
              border: "1px solid #f0f0f0",
              padding: 25,
            }}
            wrapperElement={{
              "data-color-mode": "light",
            }}
          />
        ) : (
          <Row
            align="middle"
            justify="center"
            style={{
              borderRadius: 10,
              border: "1px solid #f0f0f0",
              padding: 25,
              backgroundColor: "#FFFFFF",
            }}
          >
            --- No content available ----
          </Row>
        )}
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data: initializeData } = await dispatch(
        findFaqCategoryById({ id: params.id })
      ).unwrap();

      if (initializeData) {
        const faqLayers = map(initializeData.children, (item) => {
          return {
            ...item,
            key: item.id,
          };
        });

        setFaqLayers(faqLayers);
        formRef.current.setFieldsValue({
          title: initializeData.title,
          slug: initializeData.slug,
          sequence: initializeData.sequence,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = async (values) => {
    if (values) {
      try {
        const { data } = await dispatch(
          updateFaqCategoryById({
            ...values,
            id: params.id,
          })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Edit FAQ Category</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="title"
            label="Title"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter your FAQ title",
              },
              {
                required: true,
                validator: async (_, value) => {
                  const regex = /^\s+|\s+$/g;

                  if (value) {
                    if (regex.test(value)) {
                      throw new Error(
                        "Title should not contain space before and after it"
                      );
                    }
                  }
                },
              },
            ]}
          >
            <Input
              placeholder="FAQ title"
              onChange={(e) => {
                formRef.current.setFieldValue(
                  "slug",
                  paramCase(e.target.value)
                );
              }}
            />
          </Form.Item>
          <Form.Item name="slug" label="Slug" className="input">
            <Input disabled placeholder="Slug" />
          </Form.Item>
          <Form.Item
            name="sequence"
            label="Sequence"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the sequence",
              },
            ]}
          >
            <InputNumber
              className="input"
              placeholder="1"
              style={{ height: "inherit", padding: 0 }}
            />
          </Form.Item>

          <Form.Item name="layer_one" label="Layer One(s)" className="input">
            <Row justify="start" align="top">
              <Col span="18">
                <Table
                  columns={columns}
                  dataSource={faqLayers}
                  style={{ width: "100%" }}
                  pagination={false}
                  expandable={{
                    expandedRowRender,
                  }}
                />
              </Col>
              <Col span="6" style={{ paddingTop: 0, paddingLeft: 15 }}>
                <div>
                  <Button
                    onClick={() =>
                      navigate(`/settings/faq-layer/add/one/${params.id}`)
                    }
                    type="secondary"
                  >
                    Add Layer One
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Item>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default FaqCategoryEdit;
