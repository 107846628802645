import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";
import { isEmpty } from "lodash";
const initialState = {
  isLoading: false,
  transaction: [],
  pendingCount: {
    reloadCryptoCount: null,
    reloadBankCount: null,
    transferCount: null,
  },
};

export const findAllTransactions = createAsyncThunk(
  "transaction/findAllTransactions",
  async (
    { currencies, status, type, referenceTable, filter },
    { getState }
  ) => {
    try {
      let url = queryBuilder(
        "transactions/old-all",
        getState().ui.table,
        filter
      );

      // if (!isEmpty(currencies)) {
      //   url = `${url}&currencies=${currencies}`;
      // }
      // if (!isEmpty(status)) {
      //   url = `${url}&status=${status}`;
      // }

      if (!isEmpty(type)) {
        url = `${url}&transaction_type=${type}`;
      }

      // if (!isEmpty(referenceTable)) {
      //   url = `${url}&reference_table=${referenceTable}`;
      // }

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllTransactionRecords = createAsyncThunk(
  "transaction/findAllTransactionRecords",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder("transactions/all", getState().ui.table, filter);
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllRedeemTransactions = createAsyncThunk(
  "transaction/findAllRedeemTransactions",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder(
        "transactions/redeem/all",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrievePendingTransactions = createAsyncThunk(
  "transaction/retrievePendingTransactions",
  async (_) => {
    try {
      const { data } = await axios.get(`cases/pending`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findUserTransactionsByReferenceId = createAsyncThunk(
  "reload/findUserTransactionsByReferenceId",
  async ({ id, type }) => {
    try {
      const { data } = await axios.get(`transactions/${id}?type=${type}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findRedeemById = createAsyncThunk(
  "transfer/findRedeemById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`transactions/redeem/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findLastestUserTransactionsByUserId = createAsyncThunk(
  "reload/findLastestUserTransactionsByUserId",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`transactions/latest/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllCommissions = createAsyncThunk(
  "commission/findAllCommissions",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "transactions/commission/all",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllCommissionLogs = createAsyncThunk(
  "commission/findAllCommissionLogs",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "transactions/commission-log/all",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const approveCommissionLogs = createAsyncThunk(
  "commission/approveCommissionLogs",
  async ({ selectedDate }) => {
    try {
      const url = `transactions/commission-log/approve?selected_date=${selectedDate.format(
        "YYYY-MM-DD"
      )}`;

      const { data } = await axios.patch(url);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const calculateAllTransactionCommission = createAsyncThunk(
  "commission/calculateAllTransactionCommission",
  async ({ selectedDate }) => {
    try {
      const url = `transactions/commission-log/calculate?selected_date=${selectedDate.format(
        "YYYY-MM-DD"
      )}`;

      const { data } = await axios.put(url);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const findAllAdjustment = createAsyncThunk(
  "adjustement/findAllAdjustment",
  async ({ filter }, { getState }) => {
    console.log(filter);
    try {
      let url = queryBuilder("adjustment/all", getState().ui.table, filter);
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createNewAdjustment = createAsyncThunk(
  "adjustement/createNewAdjustment",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("username", values?.username);
      formData.append("reason", values?.reason);
      formData.append("amount", values.amount);
      formData.append("currency_id", values?.currency_id);
      formData.append("type", values.type);

      const { data } = await axios.post("adjustment/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAdjustmemtById = createAsyncThunk(
  "adjustement/findAdjustmemtById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`adjustment/${id}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    resetTransaction: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      retrievePendingTransactions.fulfilled,
      (state, { payload }) => {
        const { reload_bank_count, reload_crypto_count, transfer_count } =
          payload.data;

        state.pendingCount = {
          reloadBankCount: reload_bank_count,
          reloadCryptoCount: reload_crypto_count,
          transferCount: transfer_count,
        };
      }
    );
  },
});

export const { resetTransaction } = transactionSlice.actions;

export default transactionSlice.reducer;
