import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler } from "../configs";

export const metricsInitialise = createAsyncThunk(
  "app/metricsInitialise",
  async (_) => {
    try {
      const { data } = await axios.get("metrics/initialize");

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const metricsReload = createAsyncThunk(
  "app/metricsReload",
  async ({ date_type }) => {
    try {
      let url = `metrics/reload?date_type=${date_type}`;
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const metricsTransfer = createAsyncThunk(
  "app/metricsTransfer",
  async ({ date_type }) => {
    try {
      let url = `metrics/transfer?date_type=${date_type}`;
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
