import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { useAbility } from "@casl/react";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Image,
  Space,
  Divider
} from "antd";
// ============
import styles from "./styles.module.less";
import { findAllStatuses } from "@store/app";
import { findReloadById, updateReloadById } from "@store/reload";
import { find, map, filter, includes, reject } from "lodash";
import moment from "moment-timezone";
import HistoryTable from "../../../../components/history-table";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";

import { AbilityContext, Can } from "@configs/can";

const { Option } = Select;

const ReloadEdit = () => {
  const { params } = useMatch("top-up/bank/:id");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [reloadInfo, setReloadInfo] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [allowEditing, setAllowEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Processing");
  const [fileUrl, setFileUrl] = useState(null);
  const userId = useSelector((state) => state.app.user.id);
  const isLoading = useSelector((state) => state.reload.isLoading);
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    setMode(searchParams.get("mode"));
  }, [searchParams]);

  useEffect(() => {
    let currentMode = searchParams.get("mode");

    // If mode not equal edit or view should not allow user to view detail page
    if (!includes(["edit", "view"], currentMode)) {
      navigate("/error");
    }

    setMode(currentMode);

    if (ability.cannot("update", "Reload") && currentMode === "edit") {
      const { pathname, search } = location;
      navigate(`${pathname}${search.replace(/edit/gi, "view")}`, {
        replace: true
      });
    }

    const fetchData = async () => {
      // Retrieve statuses
      try {
        const statusPayload = await dispatch(findAllStatuses()).unwrap();
        const { data: statusData } = statusPayload;
        const filteredStatusData = filter(statusData, (item) =>
          ["Pending", "Successful", "Unsuccessful"].includes(item.name)
        );
        setStatuses(filteredStatusData);

        const reloadPayload = await dispatch(
          findReloadById({
            id: params.id,
            mode: currentMode
          })
        ).unwrap();

        const { data: reloadData } = reloadPayload;

        if (reloadData) {
          // if (reloadData.status == 1 || reloadData.status == 2) {

          setReloadInfo(reloadData);
          console.log(reloadData);

          if (reloadData.assigned_user_id) {
            let assignedUserId = reloadData.assigned_user_id;

            if (assignedUserId == userId) {
              setAllowEditing(true);
            }
          }

          setFileUrl(reloadData.file_url);
          // } else {
          //   message.error("This reload record is not available for editing");
          //   navigate("/reload");
          // }
        }

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "reloads"
          })
        ).unwrap();

        const filteredHistoryData = filter(
          historyData,
          (item) => item.action_type !== "View"
        );

        setHistoryData(filteredHistoryData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const isButtonDisabled = (status) => {
    return (
      (status === 2 && !allowEditing) ||
      status === 3 ||
      status === 5 ||
      mode === "view"
    );
  };

  const renderStatus = (status) => {
    if (status === 1) {
      return "Pending";
    } else if (status === 2) {
      return "Processing";
    } else if (status === 3) {
      return "Successful";
    } else if (status === 5) {
      return "Unsuccessful";
    } else {
      return "N/A";
    }
  };

  const renderStatusColor = (status) => {
    if (status === 3) {
      return { color: "#339933" };
    } else if (status === 5) {
      return { color: "#ff0000" };
    } else {
      return { color: "#4f8bc2" };
    }
  };

  const statusOnClick = async (status) => {
    try {
      if (ability.can("update", "Reload")) {
        await dispatch(
          updateReloadById({
            id: params.id,
            status,
            reject_reason: null
          })
        ).unwrap();

        message.success("You have successfully edited topup info");
        navigate(-1);
      } else {
        throw { message: "Invalid Permission" };
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">
            {mode === "edit" ? "Edit Top-Up (Bank)" : "View Top-Up (Bank)"}
          </div>
        </Row>

        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 5
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item name="status" label="Status" className="label-details">
                <div style={renderStatusColor(reloadInfo.status)}>
                  {renderStatus(reloadInfo.status)}
                </div>
              </Form.Item>
              <Form.Item name="change_status" label="Click to Change Status">
                <Space>
                  <Button
                    type="default"
                    className="red_btn outline"
                    // style={{ color: "#001529", bord }}
                    onClick={() => statusOnClick(5)}
                    disabled={isButtonDisabled(reloadInfo.status)}
                  >
                    Reject
                  </Button>

                  <Button
                    type="default"
                    className="green_btn outline"
                    onClick={() => statusOnClick(3)}
                    disabled={isButtonDisabled(reloadInfo.status)}
                  >
                    Approve
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item name="doc_id" label="ID" className="label-details">
                <div>{reloadInfo.doc_id}</div>
              </Form.Item>
              <Form.Item
                name="type"
                label="Top-Up Type"
                className="label-details"
              >
                <div>Bank</div>
              </Form.Item>
              <Form.Item
                name="submission_date"
                label="Submission Date"
                className="label-details"
              >
                <div>
                  {reloadInfo.created_at
                    ? moment
                        .utc(reloadInfo.created_at)
                        .tz("Asia/Phnom_Penh")
                        .format("DD-MM-YYYY, hh:mm:ss A")
                    : "-"}
                </div>
              </Form.Item>
              <Form.Item
                name="processed_date"
                label="Processed Date"
                className="label-details"
              >
                <div>
                  {reloadInfo.processed_at
                    ? moment
                        .utc(reloadInfo.processed_at)
                        .tz("Asia/Phnom_Penh")
                        .format("DD-MM-YYYY, hh:mm:ss A")
                    : "-"}
                </div>
              </Form.Item>
              <Divider className="divider" />
              <Form.Item
                name="topkash_id"
                label="Topkash ID"
                className="label-details"
              >
                <div>{reloadInfo.user_topkash_id}</div>
              </Form.Item>
              <Form.Item
                name="username"
                label="Username"
                className="label-details"
              >
                <div>{reloadInfo.user_username}</div>
              </Form.Item>
              <Form.Item
                name="fullname"
                label="Full Name"
                className="label-details"
              >
                <div>
                  {reloadInfo.user_fullname ? reloadInfo.user_fullname : "-"}
                </div>
              </Form.Item>
              <Form.Item name="mobile" label="Mobile" className="label-details">
                <div>{reloadInfo.user_phone_no}</div>
              </Form.Item>
              <Form.Item name="email" label="Email" className="label-details">
                <div>{reloadInfo.user_email}</div>
              </Form.Item>
              <Divider className="divider" />
              <Form.Item
                name="currency"
                label="Currency"
                className="label-details"
              >
                <div>
                  {reloadInfo.reload_currency_iso_code} -{" "}
                  {reloadInfo.reload_currency_name}
                </div>
              </Form.Item>
              <Form.Item name="amount" label="Amount" className="label-details">
                <div style={{ color: "#027e54" }}>
                  {numeral(parseFloat(reloadInfo.amount)).format("0,0.00")}
                </div>
              </Form.Item>
              <Divider className="divider" />
              <div className="section_subtitle">Recipient Bank</div>
              {/* <Form.Item
                label="Recipient Bank"
                className={styles.section_subtitle}
              /> */}
              <Form.Item
                name="country"
                label="Country"
                className="label-details"
              >
                <div>{reloadInfo.country_name}</div>
              </Form.Item>
              <Form.Item name="bank" label="Bank" className="label-details">
                <div>{reloadInfo.bank_name}</div>
              </Form.Item>
              <Form.Item
                name="bank_account_no"
                label="Acc No"
                className="label-details"
              >
                <div>{reloadInfo.bank_account_no}</div>
              </Form.Item>
              <Form.Item
                name="bank_account_name"
                label="Acc Name"
                className="label-details"
              >
                <div>{reloadInfo.bank_account_name}</div>
              </Form.Item>

              <Form.Item
                name="reference_no"
                label="Top-Up Reference Code"
                className="label-details"
              >
                <div>{reloadInfo.reference_no}</div>
              </Form.Item>

              <Divider className="divider" />
              <Form.Item
                name="assigned_to"
                label="Assigned To"
                className="label-details"
              >
                <div>
                  {reloadInfo.assigned_user_fullname} (
                  {reloadInfo.assigned_user_username})
                </div>
              </Form.Item>
            </Col>

            <Col span={6}>
              {fileUrl ? (
                <Image
                  style={{
                    height: 600,
                    width: "100%",
                    border: "1px solid #dcdcdc",
                    borderRadius: "5px"
                  }}
                  src={fileUrl}
                />
              ) : (
                <div>No image available</div>
              )}
            </Col>
          </Row>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            </div>
          </Row>
        </Form>

        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default ReloadEdit;
