import React, { useRef, useState } from "react";
import { Button, Row, Input, Form, message, Select, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, find, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { createUser } from "@store/user";

const CustomerServiceAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const onFinishAddUser = async (values) => {
    try {
      const { data } = await dispatch(createUser(values)).unwrap();
      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">New Customer Service User</div>
        </Row>

        <Form
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishAddUser}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            className="input"
            name="new_username"
            label="Username"
            extra={
              <div className="rules">
                Maximum 10 characters, lowercase, alphanumeric and underscore
                only.
              </div>
            }
            rules={[
              {
                required: true,
                message: "Username cannot be blank",
              },
              {
                pattern: /^[a-z0-9]{1,10}$/,
                message:
                  "Username must be small letters, no caps and no symbols. The length should not exceeding 10 characters",
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            label="Full Name"
            name="new_fullname"
            className="input"
            rules={[
              {
                required: true,
                message: "Full name cannot be blank",
              },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="new_email"
            className="input"
            rules={[
              {
                required: true,
                message: "Email cannot be blank",
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Mobile"
            name="new_phone_no"
            className="input"
            rules={[
              {
                required: true,
                message: "Mobile number cannot be empty",
              },
              {
                validator: async (_, value) => {
                  const regex = /^[0-9]*$/;
                  if (!regex.test(value)) {
                    throw new Error(
                      "Please enter a valid Mobile without symbols and alphabets"
                    );
                  }
                },
              },
            ]}
          >
            <Input placeholder="Mobile" />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save & Email Login Details To User
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CustomerServiceAdd;
