import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Button,
  Input,
  Form,
  message,
  Select,
  Card,
  Row,
  Col,
  Divider,
  Table
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, find } from "lodash";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { retrieveAllCurrencies } from "@store/currency";
import { findAllCustomers } from "@store/app";
import { findCustomerById, findCustomerWalletsById } from "@store/user";
import { createNewAdjustment } from "@store/transaction";
import numeral from "numeral";
import { unformat, formatNumber } from "accounting-js";
import TextArea from "antd/lib/input/TextArea";

const AdjustmentsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Option } = Select;
  const formRef = useRef();
  const [selectedMember, setSelectedMember] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [members, setMembers] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [tableData, setTableData] = useState([]);
  const [addedAmount, setAddedAmount] = useState([]);
  const [deductedAmount, setDeductedAmount] = useState([]);

  const { contentHeight } = useSelector((state) => state.ui.contentHeight);

  const handleSubmit = async (values) => {
    if (values) {

      const amount = values.amount_added ? values.amount_added : values.amount_deducted
      const unformatAmount = unformat(amount.replace(/(\.|\,)/g, "")) / 100;

      try {
        const { data } = await dispatch(
          createNewAdjustment({
            username: userDetail.username,
            currency_id: values.currency,
            reason: values.reason,
            amount: unformatAmount,
            type: values.amount_added ? "add" : "deduct"
          })
        ).unwrap();

        message.success(data.message);
        navigate("/adjustments");
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Initialize data

    const { data: currencyData } = await dispatch(
      retrieveAllCurrencies()
    ).unwrap();
    const { data: customerData } = await dispatch(
      findAllCustomers({ paginate: false })
    ).unwrap();

    setCurrencies(currencyData);
    setMembers(customerData);
  };

  const columns = [
    {
      title: "Currency Code",
      dataIndex: "iso_code",
      align: "left",
      fixed: "left",
      width: 80
    },
    {
      title: "Currency Name",
      dataIndex: "currency_name",
      align: "left",
      width: 140
    },
    {
      title: "Credit Balance",
      dataIndex: "amount",
      align: "right",
      width: 100,
      render: (_, record) => {
        return (
          <div style={{ textAlign: "right" }}>
            {numeral(parseFloat(record.amount)).format("0,0.00")}
          </div>
        );
      }
    }
  ];

  const addedAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const amount_added = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(amount_added, { precision: 2 });

    formRef.current.setFieldsValue({
      amount_added: formattedAmount,
      amount_deducted: null
    });

    setAddedAmount(formattedAmount);
    setDeductedAmount(null);

  });

  const deductedAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const amount_deducted = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(amount_deducted, { precision: 2 });

    formRef.current.setFieldsValue({
      amount_deducted: formattedAmount,
      amount_added: null
    });

    setDeductedAmount(formattedAmount);
    setAddedAmount(null);
  });

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Adjustment</div>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form
              ref={formRef}
              colon={false}
              name="basic"
              labelCol={{
                span: 4
              }}
              wrapperCol={{
                span: 20
              }}
              onFinish={handleSubmit}
              autoComplete="off"
              className="form-details"
            >
              <Form.Item name="member" label="Member" initialValue={[]}>
                <Select
                  allowClear
                  onClear={() => {
                    setSelectedMember(null);
                  }}
                  placeholder="Member"
                  showSearch
                  optionFilterProp="children"
                  showArrow
                  onSelect={async (value) => {
                    setSelectedMember(value);

                    //Retrieve user details
                    const userPayload = await dispatch(
                      findCustomerById({
                        id: value
                      })
                    ).unwrap();

                    const { data: userData } = userPayload;

                    if (userData) {
                      formRef?.current?.setFieldsValue({
                        username: userData.username,
                        fullname: userData.fullname,
                        email: userData.email,
                        phone_no: userData.phone_no
                      });

                      setUserDetail(userData);
                    }

                    //Retrieve user wallet details

                    const walletPayload = await dispatch(
                      findCustomerWalletsById({
                        id: value
                      })
                    ).unwrap();
                    const { data: walletData } = walletPayload;

                    setTableData(walletData.data);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {map(members, (item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.username}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {selectedMember && (
                <Form.Item
                  name="topkash_id"
                  label="Topkash ID"
                  className="label-details"
                >
                  <div>{userDetail.topkash_id}</div>
                </Form.Item>
              )}
              {selectedMember && (
                <Form.Item
                  name="fullname"
                  label="Full Name"
                  className="label-details"
                >
                  <div>{userDetail.fullname ?? "-"}</div>
                </Form.Item>
              )}

              {selectedMember && (
                <Form.Item name="email" label="Email" className="label-details">
                  <div>{userDetail.email}</div>
                </Form.Item>
              )}

              {selectedMember && (
                <Form.Item
                  name="phone_no"
                  label="Mobile"
                  className="label-details"
                >
                  <div>{userDetail.phone_no}</div>
                </Form.Item>
              )}

              {selectedMember && <Divider className="divider" />}

              {selectedMember && (
                <Form.Item
                  name="phone_no"
                  label="Wallet Info"
                  className="label-details"
                >
                  <Table
                    className={styles.pagination_item}
                    columns={columns}
                    dataSource={tableData}
                    size="small"
                    scroll={{
                      y: contentHeight - 90 - 40 - 56 - 70
                    }}
                    pagination={false}
                    style={{ width: 500 }}
                  />
                </Form.Item>
              )}

              {selectedMember && <Divider className="divider" />}

              {selectedMember && (
                <Form.Item
                  name="currency"
                  label="Currency"
                  rules={[
                    {
                      required: true,
                      message: "Please select a currency"
                    }
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Currency"
                    showSearch
                    optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase())
                    // }
                    // filterSort={(optionA, optionB) =>
                    //   optionA.children
                    //     .toLowerCase()
                    //     .localeCompare(optionB.children.toLowerCase())
                    // }
                    onSelect={async (value) => {
                      const selectCurrency = find(
                        currencies,
                        (item) => item.id === value
                      );
                      if (selectCurrency) {
                        setSelectedCurrency(selectCurrency);
                      }
                    }}
                  >
                    {map(currencies, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.iso_code} - {item.currency_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              {selectedMember && (
                <Form.Item
                  name="reason"
                  label="Reason"
                  rules={[
                    {
                      required: true,
                      message: "Please enter reason for adjustment"
                    }
                  ]}
                >
                  <TextArea
                    placeholder="Reason"
                    style={{
                      height: 80
                      // paddingBottom: 45
                    }}
                  />
                </Form.Item>
              )}

              {selectedMember && (
                <Form.Item label="Amount" style={{ alignItems: "flex-end" }}>
                  <Form.Item
                    validateStatus="success"
                    name="amount_added"
                    style={{
                      display: "inline-block"
                    }}
                  // rules={[
                  //   {
                  //     validator: async (_, value) => {
                  //       let amountDeducted =
                  //         formRef.current.getFieldValue("amount_deducted");
                  //       amountDeducted = unformat(
                  //         amountDeducted?.replace(/(\,)/g, "")
                  //       );

                  //       // value = value
                  //       //   ? unformat(value.replace(/(\,)/g, ""))
                  //       //   : null;

                  //       if (amountDeducted > 0) {
                  //         formRef.current.resetField("amount_deducted");
                  //       }
                  //     }
                  //   }
                  // ]}
                  >
                    <div
                      style={{
                        color: "#029b67",
                        textAlign: "right"
                      }}
                    >
                      Add {selectedCurrency.iso_code} To {userDetail.username}
                    </div>
                    {/* )} */}

                    <Input
                      className="input"
                      placeholder="0.00"
                      style={{
                        width: 235,
                        borderColor: "#029b67",
                        textAlign: "right"
                      }}
                      onChange={(e) => {
                        addedAmountOnChange(e.target.value);
                      }}
                      value={formRef.current.getFieldValue("amount_added")}
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "inline-block",
                      marginTop: 25,
                      margin: 8
                    }}
                  >
                    or
                  </div>
                  <Form.Item
                    name="amount_deducted"
                    style={{
                      display: "inline-block"
                    }}
                  >
                    <div
                      style={{
                        color: "#cf1322",
                        textAlign: "right"
                      }}
                    >
                      Deduct {selectedCurrency.iso_code} From{" "}
                      {userDetail.username}
                    </div>
                    <Input
                      placeholder="0.00"
                      style={{
                        width: 235,
                        borderColor: "#cf1322",
                        textAlign: "right"
                      }}
                      onChange={(e) => {
                        deductedAmountOnChange(e.target.value);
                      }}
                      value={formRef.current.getFieldValue("amount_deducted")}
                    />
                  </Form.Item>
                </Form.Item>
              )}

              {selectedMember && (
                <Row justify="end">
                  <div className={styles.btn_container}>
                    <Button onClick={() => navigate(-1)}>Cancel</Button>
                  </div>
                  <div className={styles.btn_container}>
                    <Button
                      type="default"
                      htmlType="submit"
                      className="ant-btn-green"
                    >
                      Save
                    </Button>
                  </div>
                </Row>
              )}
            </Form>
          </Col>
          {/* <Col span={12}>
            {selectedMember  && (
              <div
                className={styles.sub_section_title}
                style={{ marginBottom: 16 }}
              >
                {"Member Wallet Info"}
              </div>
            )}
            {selectedMember  && (
              <Table
                className={styles.pagination_item}
                columns={columns}
                dataSource={tableData}
                // onChange={onChange}
                size="small"
                scroll={{
                  y: contentHeight - 90 - 40 - 56 - 70
                }}
                pagination={false}
                // style={{ marginRight: }}
              />
            )}
          </Col> */}
        </Row>
      </Card>
    </div>
  );
};

export default AdjustmentsAdd;
