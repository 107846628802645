import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { find, map, isEmpty, filter } from "lodash";
// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../../store/ui";
import TableHeader from "../../../components/table-header";
import { retrieveCryptoProviders } from "../../../store/crypto";
import {
  retrieveAllCurrencies,
} from "../../../store/currency";

const CryptoProvider = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);

  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState([]);


  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const editOnClick = (item) => {
    
    navigate(`/settings/crypto-provider/${item.id}`);

  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(retrieveCryptoProviders()).unwrap();

      const { data: providerData, total } = data;
  

      // // Retrieve currencies
      // const currency = await dispatch(retrieveAllCurrencies()).unwrap();
      // const { data: currencyData } = currency;
   
      // setCurrency(currencyData);

      setTableData(data);

      setTableTotalData(total);

      dispatch(resetTableSearch());
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());

    fetchData();
  }, []);

  const columns = [
    {
      title: "Crypto Provider",
      dataIndex: "name",
      align: "left",
      fixed: "left",
      width: 120,
      sorter: true,
    },

    // {
    //   title: "Currencies",
    //   dataIndex: "currency_id",
    //   align: "left",
    //   width: 100,
     
    // },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 80,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment
              .utc(created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Crypto Providers"
        showCreateButton={true}
        createButtonText={"Create New Crypto Providers"}
        createOnClick={() => navigate("/settings/crypto-provider/add")}
      ></TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default CryptoProvider;
