import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Row,
  Input,
  Table,
  Select,
  Modal,
  message,
  Space,
  Tooltip,
  Col,
  Form,
  DatePicker,
  Button
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { debounce, reject, find, findIndex } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useAbility } from "@casl/react";
import moment from "moment-timezone";
import "moment-timezone";
import numeral from "numeral";
// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { assignCaseToUser, findAllDashboardUsers } from "@store/app";
import {
  retrieveCryptoAccounts,
  retrieveCryptoNetworks,
  retrieveCryptoProviders
} from "../../../../store/crypto";

import { findAllPendingReloads } from "@store/reload";
import { findAllPendingCryptoReloads } from "../../../../store/crypto";
import AssignUserPopUp from "@components/assign-user-popup";
import { AbilityContext } from "@configs/can";
import { WebSocketContext } from "@configs";
import TableHeader from "@components/table-header";
import { map } from "lodash";
import { findCountryFilterOptionsByType } from "@store/country";
import { findCurrencyFilterOptionsByType } from "@store/currency";
import { findAllCaseAssignee } from "@store/user";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Reload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provider, setProvider] = useState([]);
  const [network, setNetwork] = useState([]);

  // const localTimeOffset = useSelector((state) => state.app.localTimeOffset);
  const webSocket = useContext(WebSocketContext);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [dashboardUsers, setDashboardUsers] = useState([]);

  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const ability = useAbility(AbilityContext);

  const location = useLocation();

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
    fetchDashboardUsers();
  }, []);

  const fetchDashboardUsers = async () => {
    try {
      const { data } = await dispatch(
        findAllCaseAssignee({ paginate: false })
      ).unwrap();

      const assignToList = map(data, (item) => {
        return {
          label: item.username,
          value: item.username
        };
      });
      setDashboardUsers(assignToList);
    } catch (error) {
      message.error(error.message);
    }
  };

  const editOnClick = async (item) => {
    if (ability.can("update", "Reload")) {
      if (item.status === 1) {
        navigate(`/top-up/crypto/${item.ref_id}?mode=edit`);

        try {
          await dispatch(
            assignCaseToUser({
              ref_id: item.ref_id,
              type: "reloads",
              action: "assign"
            })
          ).unwrap();
        } catch (error) {
          message.error(error.message);
        }
      } else {
        navigate(`/top-up/crypto/${item.ref_id}?mode=edit`);
      }
    } else {
      navigate(`/top-up/crypto/${item.ref_id}?mode=view`);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // let reload = await dispatch(findAllPendingReloads({ filter })).unwrap();
      let reload = await dispatch(
        findAllPendingCryptoReloads({ filter })
      ).unwrap();
      let { data, total } = reload.data;

      let { data: countryFilterOptions } = await dispatch(
        findCountryFilterOptionsByType({ type: "reloads" })
      ).unwrap();

      countryFilterOptions = map(countryFilterOptions, (item) => {
        return {
          label: item.name,
          value: item.iso_code
        };
      });

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "reloads" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      setCountries(countryFilterOptions);
      setCurrencies(currencyFilterOptions);

      // Retrieve providers
      const provider = await dispatch(retrieveCryptoProviders()).unwrap();
      const { data: providersData } = provider;
      setProvider(providersData);

      // Retrieve networks
      const network = await dispatch(retrieveCryptoNetworks()).unwrap();
      const { data: networksData } = network;
      setNetwork(networksData);

      setTableData(data);
      console.log(data);
      setTableTotalData(total);
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 110,
      render: (_, record) => {
        return <div>{record.doc_id}</div>;
      }
    },
    {
      title: (
        <>
          <div>Submission</div>
          <div>Date (GMT +7)</div>
        </>
      ),
      dataIndex: "created_at",
      align: "left",
      width: 200,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;

        console.log(created_at);

        return (
          <div style={{ textAlign: "left" }}>
            {created_at != null
              ? moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : "-"}
          </div>
        );
      }
    },

    {
      title: (
        <>
          <div>Sender</div>
          <div>Topkash ID</div>
        </>
      ),
      dataIndex: "user_topkash_id",
      align: "left",
      width: 110,
      render: (_, record) => {
        if (record.user_topkash_id) {
          return <div>{record.user_topkash_id}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Sender</div>
          <div>Username</div>
        </>
      ),
      dataIndex: "user_username",
      align: "left",
      width: 110,
      render: (_, record) => {
        if (record.user_username) {
          return <div>{record.user_username}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Currency",
      dataIndex: "reload_currency_iso_code",
      align: "right",
      width: 80
    },

    {
      title: <div style={{ textAlign: "right" }}>Amount</div>,
      dataIndex: "amount",
      align: "right",
      width: 160,
      render: (_, record) => {
        if (record.amount) {
          return (
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(record.amount)).format("0,0.00")}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Crypto</div>
          <div>Provider</div>
        </>
      ),
      dataIndex: "crypto_provider",
      align: "left",
      width: 160,
      render: (_, record) => {
        if (record.crypto_account_provider) {
          return <div>{record.crypto_account_provider}</div>;
        } else if (record.crypto_account_provider) {
          return <div>{record.crypto_account_provider}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Crypto</div>
          <div>Network</div>
        </>
      ),
      dataIndex: "crypto_network",
      align: "left",
      width: 160,
      render: (_, record) => {
        if (record.crypto_account_network) {
          return <div>{record.crypto_account_network}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Address",
      dataIndex: "crypto_address",
      align: "left",
      width: 160,
      render: (_, record) => {
        if (record.crypto_account_address) {
          return <div>{record.crypto_account_address}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Assigned To",
      dataIndex: "assigned_user_username",
      align: "left",
      width: 100,
      render: (_, record) => {
        if (record.assigned_user_username) {
          return <div>{record.assigned_user_username}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        let fontWeight;

        if (record.status === 3) {
          status = "Successful";
          color = "#339933";
        } else if (record.status === 5) {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.status === 2) {
          status = "Processing";
          color = "#4f8bc2";
        } else {
          status = "Pending";
          color = "#ff8000";
          fontWeight = 700;
        }

        return <div style={{ color, fontWeight }}>{status}</div>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      width: 90,
      render: (_, record) => {
        if (ability.can("update", "Reload")) {
          return (
            <div>
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => editOnClick(record)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Space>
            </div>
          );
        } else {
          return (
            <div>
              <Space size="middle">
                <Tooltip title="View">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => editOnClick(record)}
                  >
                    View
                  </Button>
                </Tooltip>
              </Space>
            </div>
          );
        }
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Pending Top-Ups (Crypto)"
        showAdditionalNote={true}
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter(null);
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>

          <Col span="18" align="right">
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"user_topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Sender Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"username"} initialValue={null}>
                  <Input
                    placeholder="Sender Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"crypto_provider"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Provider"
                    showArrow
                    // filterOption={(input, option) => {
                    //   return option.label
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase());
                    // }}
                    // filterSort={(optionA, optionB) =>
                    //   optionA.label
                    //     .toLowerCase()
                    //     .localeCompare(optionB.label.toLowerCase())
                    // }
                  >
                    {map(provider, (item) => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"crypto_network"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Network"
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {map(network, (item) => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"crypto_address"} initialValue={null}>
                  <Input
                    placeholder="Address"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8" offset={8}>
                <Form.Item
                  name={"assigned_to"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Assigned To"
                    options={
                      dashboardUsers && dashboardUsers.length > 0
                        ? dashboardUsers
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      </TableHeader>
      <div className="pending_topup">
        <Table
          className={styles.pagination_item}
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
          loading={isLoading}
          rowKey={(row) => {
            return row.id;
          }}
          size="small"
          scroll={{
            y: contentHeight - 90 - 40 - 56 - 70
          }}
          pagination={{
            total: tableTotalData,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            current: currentPage
          }}
        />
      </div>
    </div>
  );
};

export default Reload;
