import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation,
  useParams,
  useMatch,
  useSearchParams
} from "react-router-dom";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  // message,
  Select,
  Input,
  Divider,
  Table,
  TimePicker,
  Switch,
  Upload,
  Modal,
  Spin,
  Space
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import message from "@configs/message";
import {
  find,
  map,
  isEmpty,
  filter,
  assign,
  chain,
  split,
  trim,
  size,
  debounce
} from "lodash";
import moment from "moment";

import { unformat, formatNumber } from "accounting-js";
import numeral from "numeral";
// ============
import styles from "./styles.module.less";
import merchant, {
  sendOnboardingEmail,
  createMerchant,
  findMerchantById,
  updateMerchantById,
  retrieveAllBusinessType,
  generateMerchantSticker
} from "../../../store/merchant.js";
import { retrieveAllCurrencies } from "../../../store/currency";
import {
  findActivityLogsByRefIdAndRefTable,
  findCountryStatesMaritalStatus,
  findAllCustomersEnabled,
  removeMediaById,
  createLogoMedia,
  createImageGalleryMedia,
  removeGalleryMediaById
} from "@store/app";
import { toNumber, first } from "lodash";
import HistoryTable from "../../../components/history-table";
import MDEditor, { commands, image } from "@uiw/react-md-editor";
import { downloadFromUrl } from "../../../configs/utilities";

const { Option } = Select;
const { Dragger } = Upload;

const EditMerchant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { params } = useMatch("settings/merchant/:id");

  // const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [countries, setCountries] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState("**Hello world!!!**");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [galleryFileList, setGalleryFileList] = useState([]);
  const [mediaId, setMediaId] = useState(null);
  const [mediaIds, setMediaIds] = useState([]);
  const [addedImage, setAddedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dimensions = { min: 100, max: 600 };
  const galleryDimensions = { min: 500, max: 1000 };

  const [states, setStates] = useState([]);
  const [merchantData, setMerchantData] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [isCloseArr, setIsCloseArr] = useState([]);
  const [operationList, setOperationList] = useState([
    {
      key: "1",
      days: "Sunday",
      is_closed: false,
      hours: null
    },
    {
      key: "2",
      days: "Monday",
      is_closed: false,
      hours: null
    },
    {
      key: "3",
      days: "Tuesday",
      is_closed: false,
      hours: null
    },
    {
      key: "4",
      days: "Wednesday",
      is_closed: false,
      hours: null
    },
    {
      key: "5",
      days: "Thursday",
      is_closed: false,
      hours: null
    },
    {
      key: "6",
      days: "Friday",
      is_closed: false,
      hours: null
    },
    {
      key: "7",
      days: "Saturday",
      is_closed: false,
      hours: null
    }
  ]);

  // const country = useSelector((state) =>
  //   filter(state.country.allCountries, (item) => item.is_enabled == 1)
  // );

  const formRef = useRef();

  const columns = [
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      render: (_, record, index) => {
        const { key } = record;
        return (
          // <div>
          <>
            {record.is_closed ? (
              <div>Closed</div>
            ) : (
              <Form.Item
                name={["operations", index, "hours"]}
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select a time"
                  }
                ]}
              >
                <TimePicker.RangePicker
                  className="time-picker"
                  format="HH:mm"
                  onChange={(values) => {
                    onChangeTime(values, key);
                  }}
                />
              </Form.Item>
            )}
          </>

          // </div>
        );
      }
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const { key, is_closed } = record;
        return (
          <>
            <Switch
              checkedChildren="Open"
              unCheckedChildren="Close"
              checked={!is_closed}
              onChange={(checked) => {
                onChangeSwitch(checked, key);
              }}
            />
          </>
        );
      }
    }
  ];

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" }
  ];

  useEffect(() => {
    // set Status initial value
    formRef.current.setFieldsValue({
      is_active: statusOption[0].value
    });

    // console.log(operationList);
    formRef.current.setFieldValue("operations", operationList);
    // setOperationList(data);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: initializeData } = await dispatch(
          findCountryStatesMaritalStatus()
        ).unwrap();

        setCountries(initializeData.country);

        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;
        setCurrency(currencyData);

        //Retrieve business type
        const businessType = await dispatch(retrieveAllBusinessType()).unwrap();
        const { data: businessTypeData } = businessType;
        setBusinessType(businessTypeData);

        const { data: memberData } = await dispatch(
          findAllCustomersEnabled({
            id: params.id
          })
        ).unwrap();

        // filter memberdata to only get users with no merchant and themselves(initial data)
        // memberData = memberData.filter((x) => {return (x.merchant_id == null || x.merchant_id == params.id)});

        setUsers(memberData);
        if (params.id !== "add") {
          const { data: merchant } = await dispatch(
            findMerchantById({
              id: params.id
            })
          ).unwrap();
          console.log("merchant");
          console.log(merchant);

          setMerchantData(merchant);

          if (merchant) {
            const currencyListed = [];
            const currencyArr = merchant.currency_available.split(",");
            for (var i = 0; i < currencyArr.length; i++) {
              currencyListed.push(toNumber(currencyArr[i]));
            }

            const businessTypeNames = merchant.business_type.map(
              (businessType) => businessType.id
            );

            let userArr = [];
            if (merchant.users.length) {
              for (var i = 0; i < merchant.users.length; i++) {
                userArr.push(merchant.users[i].id);
              }
            }

            formRef.current.setFieldsValue({
              name: merchant.name,
              pic: merchant.pic,
              phone: merchant.phone,
              content: merchant.content,
              // email: merchant.email,
              currency_available: currencyListed,
              business_type: businessTypeNames,
              // operation_days: merchant.operation_days,
              // operation_hours: merchant.operation_hours,
              assign_users: userArr ? userArr[0] : null,
              // emergency_phone: merchant.emergency_phone,
              emergency_pic: merchant.emergency_pic,
              is_active: merchant.is_active,
              address_1: merchant.address?.address_1,
              address_2: merchant.address?.address_2,
              country: merchant.country_id,
              state: merchant.address?.state_id,
              city: merchant.address?.city,
              postcode: merchant.address?.postcode,
              longitude: merchant?.longitude,
              latitude: merchant?.latitude,
              google_map: merchant?.google_map,
              waze_map: merchant?.waze_map
            });

            if (merchant.operation.length) {
              let operationValues = map(merchant.operation, (item) => {
                const { hours, is_closed } = item;
                let remapArrayHours = null;
                if (hours) {
                  let arrayHours = hours.split(" to ");
                  remapArrayHours = map(arrayHours, (hourItem) => {
                    return moment(hourItem, "HH:mm");
                  });
                }

                return { ...item, hours: remapArrayHours, key: item.id };
              });
              setOperationList(operationValues);
              formRef.current.setFieldValue("operations", operationValues);
            }

            if (merchant.address?.country_id) {
              formRef.current.setFieldsValue({
                state: null
              });

              let selectedCountry = find(initializeData.country, {
                id: merchant.address?.country_id
              });
              if (selectedCountry) {
                setStates(selectedCountry.state);
              }

              formRef.current.setFieldsValue({
                state: merchant.address?.state_id
              });
            }

            if (merchant.media) {
              setMediaId(merchant.media_id);

              let newFileList = [
                {
                  uid: merchant.media.id,
                  name: merchant.media.filename,
                  status: "done",
                  url: merchant.media.file_url
                }
              ];

              setFileList(newFileList);
              formRef.current.setFieldValue("logo", newFileList);
            }

            setMediaIds(merchant.gallery_media);

            let newGalleryFileList = merchant.gallery_media.map((item) => ({
              uid: item.id,
              name: item.filename,
              status: "done",
              url: item.file_url,
              path: item.path
            }));

            setGalleryFileList(newGalleryFileList);

            formRef.current.setFieldValue("gallery", newGalleryFileList);
          }

          const { data: merchantHistoryData } = await dispatch(
            findActivityLogsByRefIdAndRefTable({
              reference_id: params.id,
              reference_table: "merchants"
            })
          ).unwrap();

          const { data: addressHistoryData } = await dispatch(
            findActivityLogsByRefIdAndRefTable({
              reference_id: merchant.address_id,
              reference_table: "addresses"
            })
          ).unwrap();

          // sort by id desc (get latest first)
          let historyData = merchantHistoryData
            .concat(addressHistoryData)
            .sort((x, y) => {
              return y.id - x.id;
            });

          setHistoryData(historyData);
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const sendOnboardingEmailOnClick = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        sendOnboardingEmail({
          id: params.id
        })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadMerchantSticker = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        generateMerchantSticker({
          id: params.id
        })
      ).unwrap();
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const customRequest = async ({ file, onSuccess }) => {
    try {
      let { data } = await dispatch(
        createLogoMedia({
          file
        })
      ).unwrap();

      setMediaId(data[0]);

      onSuccess("Image successfully uploaded");
    } catch (error) {
      message.error(error.message);
    }
  };

  const customRequestGallery = async ({ file, onSuccess }) => {
    try {
      let { data } = await dispatch(
        createImageGalleryMedia({
          file,
          referenceId: 0,
          referenceTable: "merchants"
        })
      ).unwrap();

      let stringArray = mediaIds;

      // Pushing a new string into the array
      const newString = data?.id.toString();

      stringArray.push(newString);

      setMediaIds(stringArray);

      let galleryArray = galleryFileList;

      const newImage = {
        uid: data.id,
        name: data?.filename,
        status: "done",
        url: data?.file_url,
        path: data?.path
      };

      galleryArray.push(newImage);

      setGalleryFileList(galleryArray);

      setAddedImage(newImage);

      onSuccess("Image successfully uploaded");
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const logoOnPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const galleryOnPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const logoOnChange = ({ fileList: newFileList }) => {
    const media = first(newFileList);

    setFileList(newFileList);
    formRef.current.setFieldValue("logo", newFileList);
    formRef.current.validateFields();

    if (media) {
      const { response, status } = media;
      if (status === "done") {
        message.success(response);
      }
    }
  };

  const galleryOnChange = ({ fileList: newGalleryFileList }) => {
    const media = first(newGalleryFileList);

    formRef.current.setFieldValue("gallery", galleryFileList);

    formRef.current.validateFields();

    if (media) {
      if (params.id === "add") {
        message.success("Image successfully uploaded");
      } else {
        message.success("Successfully updated gallery");
      }
    }
  };

  const galleryOnChangeDebounce = debounce(galleryOnChange, 500, {
    leading: true,
    trailing: false
  });

  const logoOnRemove = async (file) => {
    await dispatch(
      removeMediaById({
        id: mediaId
      })
    ).unwrap();
    formRef.current.setFieldValue("logo", undefined);

    message.success("Successfully deleted image");

    setMediaId(null);
  };

  const galleryOnRemove = async (file) => {
    const fileIndex = galleryFileList.findIndex(
      (item) => item.uid === file.uid
    );

    if (fileIndex !== -1) {
      const removedMediaId = galleryFileList[fileIndex].uid;

      await dispatch(
        removeGalleryMediaById({
          id: removedMediaId
        })
      ).unwrap();

      const newGalleryFileList = [...galleryFileList];
      newGalleryFileList.splice(fileIndex, 1);

      setGalleryFileList(newGalleryFileList);
      formRef.current.setFieldValue("gallery", newGalleryFileList);

      message.success("Successfully deleted gallery image");

      setMediaIds((prevMediaIds) => {
        const newMediaIds = [...prevMediaIds];
        newMediaIds.splice(fileIndex, 1);
        return newMediaIds;
      });
    }
  };

  const logoBeforeUpload = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = () => {
          if (
            img.naturalWidth <= dimensions.max &&
            img.naturalWidth >= dimensions.min &&
            img.naturalWidth == img.naturalHeight
          ) {
            const canvas = document.createElement("canvas");
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((result) => resolve(result));
          } else {
            message.error("Logo dimensions invalid");
          }
        };
      };
    });
  };

  const galleryBeforeUpload = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = () => {
          if (
            img.naturalWidth <= galleryDimensions.max &&
            img.naturalWidth >= galleryDimensions.min &&
            img.naturalWidth == img.naturalHeight
          ) {
            const canvas = document.createElement("canvas");
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((result) => resolve(result));
          } else {
            message.error("Image dimensions invalid");
          }
        };
      };
    });
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangeTime = (value, index) => {
    // let { operations } = formRef.current.getFieldsValue();
    // console.log(operations);
    let list = [];

    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === index) {
          item.hours = value;
        }

        return item;
      })
      .value();

    formRef.current.setFieldValue("operations", newOperationList);
    setOperationList(newOperationList);
  };

  const onChangeCloseArr = (value) => {
    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === value) {
          item.is_closed = true;
        }

        return item;
      })
      .value();
    formRef.current.setFieldValue("operations", newOperationList);

    setOperationList(newOperationList);
  };

  const onChangeOpenArr = (value) => {
    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === value) {
          item.hours = null;

          item.is_closed = false;
        }

        return item;
      })
      .value();
    formRef.current.setFieldValue("operations", newOperationList);

    setOperationList(newOperationList);
  };
  const onChangeSwitch = (checked, value) => {
    let newOperationList = chain(operationList)
      .map((item) => {
        if (item.key === value) {
          if (!checked) {
            item.hours = null;
          }
          item.is_closed = !checked;
        }

        return item;
      })
      .value();

    formRef.current.setFieldValue("operations", newOperationList);
    setOperationList(newOperationList);
  };
  const onChangeCountries = (value) => {
    formRef.current.setFieldsValue({
      state: null
    });

    let selectedCountry = find(countries, { id: value });
    if (selectedCountry) {
      setStates(selectedCountry.state);
    }
  };
  const phoneNumberOnChange = async ({ target }) => {
    const { value } = target;
    let text = value;
    let id = target.id;
    let newId = id.replace("basic_", "");
    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/gi, "");
    formRef.current.setFieldValue(newId, formattedPhoneNumber);
  };

  const handleEdit = async (values) => {
    const { operations } = values;
    let operationValues = map(operations, (item) => {
      const { hours, is_closed } = item;
      let text = null;
      if (hours && !is_closed) {
        text = `${moment(hours[0], "HH:mm").format("HH:mm")} to ${moment(
          hours[1],
          "HH:mm"
        ).format("HH:mm")}`;
      } else if (hours && is_closed) {
        text = null;
      }

      return { ...item, hours: text };
    });
    try {
      if (values) {
        let numbersToString = values.currency_available.toString();

        let businessType = values.business_type;

        let addressReferenceId = null;
        if (merchantData.address && values.address_1) {
          addressReferenceId = merchantData.id;
        }
        for (var key in values) {
          var value = values[key];
          if (value === "") {
            values[key] = null;
          }
        }
        values = {
          ...values,
          media_id: mediaId,
          images: mediaIds,
          operations: operationValues,
          currency_available: numbersToString,
          business_type: businessType,
          reference_table: "merchants",
          reference_id: addressReferenceId
        };
        const dataRes = await dispatch(
          updateMerchantById({
            id: params.id,
            values
          })
        ).unwrap();
        message.success(dataRes.data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleSubmit = async (values) => {
    const { operations } = values;
    let operationValues = map(operations, (item) => {
      const { hours, is_closed } = item;
      let text = null;
      if (hours && !is_closed) {
        text = `${moment(hours[0], "HH:mm").format("HH:mm")} to ${moment(
          hours[1],
          "HH:mm"
        ).format("HH:mm")}`;
      } else if (hours && is_closed) {
        text = null;
      }

      return { ...item, hours: text };
    });
    try {
      if (values) {
        await dispatch(
          createMerchant({
            address_1: values.address_1 ? values.address_1 : "",
            address_2: values.address_2 ? values.address_2 : "",
            city: values.city ?? "",
            country: values.country ?? "",
            currency_available: values.currency_available,
            // email: values.email,
            // emergency_phone: values.emergency_phone,
            // emergency_pic: values.emergency_pic,
            name: values.name,
            operations: operationValues,
            longitude: values?.longitude ?? null,
            latitude: values?.latitude ?? null,
            google_map: values?.google_map ?? null,
            waze_map: values?.waze_map ?? null,
            phone: values.phone,
            pic: values.pic,
            content: values.content ?? "",
            postcode: values.postcode ?? "",
            state: values.state ?? "",
            is_active: values.is_active,
            assign_users: values.assign_users ?? "",
            media_id: mediaId,
            images: mediaIds,
            business_type: values.business_type
          })
        ).unwrap();

        message.success("You have successfully added new merchant");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <Card className={styles.card_container}>
          <Row>
            <div className={styles.section_title}>
              {params?.id === "add" ? "New" : "Edit"} Merchant
            </div>
          </Row>

          <Form
            ref={formRef}
            colon={false}
            name="basic"
            labelCol={{
              span: 4
            }}
            wrapperCol={{
              span: 20
            }}
            initialValues={{
              remember: true
            }}
            onFinish={params.id === "add" ? handleSubmit : handleEdit}
            autoComplete="off"
            className="form-details"
          >
            <Form.Item name="is_active" label="Status">
              <Select name="is_active">
                {map(statusOption, (item) => {
                  return (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Business Name"
              name="name"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the business name"
                }
              ]}
            >
              <Input placeholder="Business Name" />
            </Form.Item>

            <Form.Item
              name="business_type"
              label="Business Type"
              rules={[
                {
                  required: true,
                  message: "Please select business type"
                }
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Business Type"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(businessType, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Person In Charge"
              name="pic"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the person in charge"
                }
              ]}
            >
              <Input placeholder="Person In Charge" />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Mobile"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the mobile number"
                }
              ]}
            >
              <Input placeholder="Mobile" onChange={phoneNumberOnChange} />
            </Form.Item>
            {/* <Form.Item
            name="email"
            label="Email"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the email"
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Please enter a valid email"
              }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item> */}

            <Form.Item
              name="logo"
              label="Logo"
              rules={[
                {
                  required: true,
                  message: "Please upload the logo"
                }
              ]}
            >
              <Dragger
                customRequest={customRequest}
                fileList={fileList}
                onPreview={logoOnPreview}
                onChange={logoOnChange}
                onRemove={logoOnRemove}
                beforeUpload={logoBeforeUpload}
                listType="picture"
                multiple={false}
                maxCount={1}
                withCredentials
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined className={styles.inbox_icon} />
                </p>
                <p className={`ant-upload-text ${styles.inbox_description}`}>
                  Click or drag file to this area to upload
                </p>
              </Dragger>
              <Modal
                visible={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%"
                  }}
                  src={previewImage}
                />
              </Modal>
              <div className={styles.dimension_text}>
                (Aspect Ratio: 1:1, Min size: 100 x 100px, Max size: 600 x
                600px)
              </div>
            </Form.Item>

            <Form.Item
              name="currency_available"
              // value={selectedCurrency}

              label="Currency"
              rules={[
                {
                  required: true,
                  message: "Please enter the currency"
                }
              ]}
            >
              <Select
                mode="multiple"
                // value={selectedCurrency}
                placeholder="Select Currency"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(currency, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.iso_code}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="operations" label="Operation" className="input">
              <Table
                columns={columns}
                dataSource={operationList}
                style={{ width: "66%" }}
                pagination={false}
                rowClassName={styles.rowClass}
              />
            </Form.Item>

            {/* <Form.Item
            name="operation_days"
            label="Operation Days"
            className="input"
          >
            <Input placeholder="Operation Days" />
          </Form.Item>

          <Form.Item
            name="operation_hours"
            label="Operation Hours"
            className="input"
          >
            <Input placeholder="Operation Hours" />
          </Form.Item> */}

            {/* <Form.Item
            label={
              // <div>
              //   <div>Emergency Person</div>
              //   <div>In Charge</div>
              // </div>
              <div style={{ marginRight: 20 }}>
                <Col>
                  <Row justify="end">Emergency</Row>
                  <Row justify="end">Person In Charge</Row>
                </Col>
              </div>
            }
            name="emergency_pic"
            className="two-rows-emergency-label"
            // className="input"
            rules={[
              {
                required: true,
                message: "Please enter the emergency person in charge",
              },
            ]}
          >
            <Input placeholder="Emergency Person In Charge" />
          </Form.Item>

          <Form.Item
            name="emergency_phone"
            label="Emergency Contact"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the emergency contact number",
              },
            ]}
          >
            <Input
              placeholder="Emergency Contact"
              onChange={phoneNumberOnChange}
            />
          </Form.Item> */}
            <Divider className="divider" />

            <Form.Item
              name="content"
              className={styles.input}
              label="Description"
            >
              <MDEditor
                value={value}
                onChange={setValue}
                commands={[
                  commands.bold,
                  commands.strikethrough,
                  commands.hr,
                  commands.title,
                  commands.link,
                  commands.quote,
                  commands.code,
                  commands.codeBlock,
                  commands.comment,
                  commands.image,
                  commands.table,
                  commands.checkedListCommand,
                  commands.unorderedListCommand,
                  commands.codePreview,
                  commands.divider,
                  commands.fullscreen,
                  commands.codeEdit
                ]}
                height={500}
                data-color-mode="light"
              />
            </Form.Item>

            <Divider className="divider" />

            <Form.Item name="gallery" label="Gallery">
              <Dragger
                customRequest={customRequestGallery}
                fileList={galleryFileList}
                onPreview={galleryOnPreview}
                onChange={galleryOnChangeDebounce}
                onRemove={galleryOnRemove}
                beforeUpload={galleryBeforeUpload}
                listType="picture"
                multiple={true}
                withCredentials
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined className={styles.inbox_icon} />
                </p>
                <p className={`ant-upload-text ${styles.inbox_description}`}>
                  Click or drag images to this area to upload
                </p>
              </Dragger>
              <Modal
                visible={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%"
                  }}
                  src={previewImage}
                />
              </Modal>
              <div className={styles.dimension_text}>
                (Aspect Ratio: 1:1, Min size: 500 x 500px, Max size: 1000 x
                1000px)
              </div>
            </Form.Item>

            <Divider className="divider" />

            <Form.Item
              name="address_1"
              label="Address Line 1"
              className="input"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    /**
                     * If one of the address related field being filled in, then another 4 fields become required
                     */
                    if (
                      getFieldValue("address_2") ||
                      // getFieldValue("country") ||
                      getFieldValue("city") ||
                      getFieldValue("state") ||
                      getFieldValue("postcode")
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please enter Address Line 1")
                        );
                      }
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Address Line 1" allowClear />
            </Form.Item>

            <Form.Item
              name="address_2"
              label="Address Line 2"
              className={styles.input}
            >
              <Input placeholder="Address Line 2" allowClear />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              // rules={[
              //   ({ getFieldValue }) => ({
              //     validator(_, value) {
              //       /**
              //        * If one of the address related field being filled in, then another 4 fields become required
              //        */
              //       if (
              //         getFieldValue("address_1") ||
              //         getFieldValue("address_2") ||
              //         getFieldValue("city") ||
              //         getFieldValue("state") ||
              //         getFieldValue("postcode")
              //       ) {
              //         if (!value) {
              //           return Promise.reject(
              //             new Error("Please select a country")
              //           );
              //         }
              //       }

              //       return Promise.resolve();
              //     },
              //   }),
              // ]}
              rules={[
                {
                  required: true,
                  message: "Please select a country"
                }
              ]}
            >
              <Select
                // name="country"
                placeholder="Select country"
                //   value={selectedCountry}
                onChange={onChangeCountries}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                allowClear
              >
                {map(countries, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    /**
                     * If one of the address related field being filled in, then another 4 fields become required
                     */
                    if (
                      getFieldValue("address_1") ||
                      getFieldValue("address_2") ||
                      getFieldValue("city") ||
                      // getFieldValue("country") ||
                      getFieldValue("postcode")
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please select a state")
                        );
                      }
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Select name="state" placeholder="State" allowClear>
                {map(states, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="city"
              label="City"
              className="input"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    /**
                     * If one of the address related field being filled in, then another 4 fields become required
                     */
                    if (
                      getFieldValue("address_1") ||
                      getFieldValue("address_2") ||
                      getFieldValue("state") ||
                      // getFieldValue("country") ||
                      getFieldValue("postcode")
                    ) {
                      if (!value) {
                        return Promise.reject(new Error("Please enter City"));
                      }
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="City" allowClear />
            </Form.Item>

            <Form.Item
              name="postcode"
              label="Postcode"
              className="input"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    /**
                     * If one of the address related field being filled in, then another 4 fields become required
                     */
                    if (
                      getFieldValue("address_1") ||
                      getFieldValue("address_2") ||
                      getFieldValue("state") ||
                      // getFieldValue("country") ||
                      getFieldValue("city")
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please enter Postcode")
                        );
                      }
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Postcode" allowClear />
            </Form.Item>
            <Divider className="divider" />

            <Form.Item
              label="Google Map Link"
              name="google_map"
              className="input"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    /**
                     * If one of the address related field being filled in, then another 4 fields become required
                     */
                    if (
                      getFieldValue("latitude") ||
                      getFieldValue("longitude")
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please enter Google Map Link")
                        );
                      }
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Google Map Link" allowClear />
            </Form.Item>
            <Form.Item label="Waze Map Link" name="waze_map" className="input">
              <Input placeholder="Waze Map Link" allowClear />
            </Form.Item>

            <Form.Item
              label="Latitude"
              name="latitude"
              className="input"
              rules={[
                {
                  pattern: "^([-]?[0-9]+(.[0-9]*)?)$",
                  message: "please enter valid latitude!"
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue("google_map") ||
                      getFieldValue("longitude")
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please enter Latitude")
                        );
                      }
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Latitude" allowClear />
            </Form.Item>

            <Form.Item
              label="Longitude"
              name="longitude"
              className="input"
              rules={[
                {
                  pattern: "^([-]?[0-9]+(.[0-9]*)?)$",
                  message: "please enter valid longitude"
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue("google_map") ||
                      getFieldValue("latitude")
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error("Please enter Longitude")
                        );
                      }
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Longitude" allowClear />
            </Form.Item>

            <Form.Item
              name="assign_users"
              // value={selectedCurrency}
              label="Assigned User"
              rules={[
                {
                  required: true,
                  message: "Please select a user"
                }
              ]}
            >
              <Select
                // mode="multiple"
                // value={selectedCurrency}
                placeholder="Select Assigned User"
                showSearch
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(users, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.username}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Row justify="end">
              <div className={styles.btn_container}>
                <Button onClick={() => navigate(-1)} className="default_btn">
                  Cancel
                </Button>
              </div>
              <div className={styles.btn_container}>
                <Button
                  type="default"
                  htmlType="submit"
                  className="ant-btn-green"
                >
                  Save
                </Button>
              </div>
              <div className={styles.btn_container}>
                {params?.id !== "add" && merchantData?.users?.length !== 0 && (
                  <Button
                    onClick={() => sendOnboardingEmailOnClick()}
                    className="ant-btn-green"
                  >
                    Send Onboarding Email
                  </Button>
                )}
              </div>

              <div className={styles.btn_container}>
                {params?.id !== "add" && merchantData?.users?.length !== 0 && (
                  <Button
                    onClick={() => downloadMerchantSticker()}
                    className="ant-btn-green"
                  >
                    Export Merchant Sticker
                  </Button>
                )}
              </div>
            </Row>
          </Form>

          {params?.id !== "add" ? <HistoryTable data={historyData} /> : ""}
        </Card>
      </Spin>
    </div>
  );
};

export default EditMerchant;
